import React, { useEffect, useState } from "react";
import {
  Col,
  Layout,
  Row,
  Form,
  Button,
  Tooltip,
  Select,
  Menu,
  Dropdown,
  Space,
  Radio,
  TreeSelect,
  Switch,
  Input,
  InputNumber
} from "antd";
import { appInsights } from "../../utilities/appInsights";
import { useHistory, withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import {
  SearchOutlined,
  MoreOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import CustomTable from "../../components/customTable";
import { useSelector, useDispatch } from "react-redux";

import {
  getComparisonTrendData,
  getDeepdiveTrendData,
  getAllFilters,
  getFilterData,
  getInitialFilters,
  setAppliedFilters,
  setFilters,
  updateRetailerDropdown,
  setSelectedRetailer,
  getScatterChartData,
  getSearchTermOverview,
  getAllSubFilters,
  getTableData,
  setStrategyData,
  setSearch,
  processFilters

} from "../../slicers/searchTerm.slicer";
import Loader from "../../components/loader";
import BubbleChart from "../../components/bubble-chart";
import Navigator from "../../components/bread-crumbs";
import {
  formatNumber,
  openNotificationWithIcon,
  setWindowHistory,
  handleTab,
  hasValue,
  getTitleWidth,
  initialUrlFilter,
  getLatestUpdatedFilter,
  replaceUrl,
} from "../../utilities/helper";
import mdiCheck from "../../assets/images/mdi_eye-check.svg";
import "../be-selected/be-selected.scss";
import {
  MultiLineChart,
  SelectColumnLineChart,
} from "../be-available/baUIHelper";
import shareColumns from '../be-visible/bvColumns.js'
import {
  viewBySearch,
  viewByStretagy,
  rowKey,
  barLegends,
} from "./stUIHelper";
import { isEqual, cloneDeep } from "lodash";
import columns from "./stColumns";
import "./search-term.scss";
import { setExportData } from "../../slicers/exportData.slicer";
import { exportEComTable, exportSTBubbleChart, exportSTExport, exportSTTrends } from "../../utilities/exports/excel";
import { getState } from "../../store";
import { pptExportST } from "../../utilities/exports/ecomppt";
import { ecomCommonFilters } from "../../slicers/diagnostics.slicer";
const { Content } = Layout;
const { Option } = Select;

/**
 * Search Insights
 * It enables the user to see all the search terms across brand with category and productive graphs like trend and bars.
 */
function SearchTerm(props) {
  const dispatch = useDispatch();

  const { defaultBrand } = useSelector((state) => state.profile);

  const {
    loading,
    dropdownLoading,
    keywordCategories,
    keywordTypes,
    feedingPhilosophies,
    textures,
    priceTiers,
    functions,
    lifeStages,
    breedSizes,
    packageTypes,
    proteins,

    retailers,
    retailerDropdown,
    timeAggregates,
    yearPeriods,
    benchmarkPeriods,
    brands,
    trendBrands,
    categories,
    filters,
    appliedFilters,
    searchTermOverview,
    comparisonTrendData,
    deepdiveMultiTrendData,
    tableData,
    // on mouse leave
    tableSearch,
    selectedRetailer,
    strategyData,
    strategyDataDuplicate,
  } = useSelector((state) => state.searchTerm);
  const exportData = useSelector((state) => state.exportData);
  const { searchTerm } = useSelector((state) => state.refreshDate);
  const [bubbleLegend, setBubbleLegend] = useState([
    { name: "Mars", color: "#0000A0", isVisible: false },
    { name: "Nestle", color: "#FF0000", isVisible: false },
    { name: "General Mills", color: "#CC5500", isVisible: false },
    { name: "Colgate Palmolive", color: "#006400", isVisible: false },
    { name: "Wellness Pet", color: "#800080", isVisible: false },
    { name: "Post Holdings", color: "#FFA500", isVisible: false },
    { name: "J.M. Smuckers", color: "#FFFF00", isVisible: false },
    { name: "Schell & Kampeter", color: "#E6E6FA", isVisible: false },
    { name: "Spectrum", color: "#90EE90", isVisible: false },
    { name: "Whitebridge Pet", color: "#FFC0CB", isVisible: false },
    { name: "Nexus CPTL MGMT", color: "#008080", isVisible: false },
    { name: "Agrolimen", color: "#964B00", isVisible: false },
    { name: "Other Brands", color: "#808080", isVisible: false },
  ])
  // On Mouse Leave events
  const [selectedTrendLimit, setSelectTrendLimit] = useState("10");
  const [selectedTrafficLimit, setSelectTrafficLimit] = useState(50);


  const [stRetailerColumns, setStRetailerColumns] = useState(
    columns.stRetailerCols
  );
  const [stRetailerColumnsPPTExport, setStRetailerColumnsPPTExport] = useState(
    columns.stRetailerCols
  );
  const [stRetailerColumnsExport, setStRetailerColumnsExport] = useState(
    columns.stRetailerColsExport
  );

  const [lastDefaultBrand, setLastDefaultBrand] = useState("");
  const [filterChanged, setFilterChanged] = useState(false);
  const [stored, setStored] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [selectedStrategyBrands, setSelectedStrategyBrands] = useState([])

  const menu = (exportData) => (
    <Menu>
      <Menu.Item key="0" onClick={exportData}>
        Export Data
      </Menu.Item>
    </Menu>
  );

  const handleSearch = (confirm) => {
    confirm();
  };

  // On Mouse Leave events
  const [selectedTrendBrands, setSelectedTrendBrands] = useState([]);
  const [selectedRetailerBrands, setSelectedRetailerBrands] = useState([]);
  const isReport = props.isReport;
  const history = useHistory();
  const { SHOW_PARENT } = TreeSelect;

  const bubblechartLabel = {
    label: "traffic_change",
    sizeField: "traffic",
    keywordColor: "keyword",
    size: true,
  };

  const getTableCallout = () =>
    "Below: keywords with traffic for period selected in top of page filters; Above: graphs for all keywords trended L52 weeks.";

  const getTableTitle = () =>
    `Search Terms - ${filters.retailer_view[0] == 0 ? "Retailer View" : "Retailer Deep Dive"
    }`;

  const getTableDescription = () =>
    "Search Term performance across " +
    (filters.retailer_view[0] == 0
      ? "multiple retailers"
      : "a selected retailer");

  const handleLabel = (checked) => {
    setShowLabel(checked);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const appendRetailers = (
    columnIndex,
    columns,
    record,
    kpi,
    renderFunction,
    sort
  ) => {
    if (record[kpi]) {
      const sorting = kpi === "traffic" && sort;
      columns[columnIndex].children = Object.keys(record[kpi]).map(
        (retailer) => ({
          title: retailer,
          dataIndex: [kpi, retailer],
          key: retailer,
          width: getTitleWidth(retailer, sorting),
          render: (val) => renderFunction(val),
          sorter:
            kpi === "traffic" && sort
              ? (a, b) =>
                a.traffic[retailer]?.abs < b.traffic[retailer]?.abs ? -1 : 1
              : false,
          defaultSortOrder: sort ? "descend" : "",
          className: "sub-td-border-left",
        })
      );

      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };

  const appendRetailersPPTExport = (
    columnIndex,
    columns,
    record,
    kpi,
    renderFunction,
  ) => {
    if (record[kpi]) {
      columns[columnIndex].children = Object.keys(record[kpi]).map(
        (retailer) => {
          return {
            title: retailer,
            dataIndex: kpi,
            key: kpi,
            renderPPT: (val) => renderFunction(val),
          }
        }
      );

      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };
  const appendRetailersExport = (
    columns,
    record,
    kpi,
    title,
    renderTextAbs,
    renderTextVariance
  ) => {
    if (record[kpi]) {
      Object.keys(record[kpi]).forEach((retailer) => {
        // abs
        columns.push({
          title: `${title} ${retailer}`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) => renderTextAbs(val && val[retailer]),
        });

        // variance
        columns.push({
          title: `${title} ${retailer} vs Benchmark`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) => renderTextVariance(val && val[retailer]),
        });
      });
    }
  };

  // Table dynamic column binding
  useEffect(() => {
    if (tableData && tableData.data && tableData.data.length) {
      const record = tableData.data[0];
      const stColumns = cloneDeep(columns.stRetailerCols);
      const stColumnsPPTExport = cloneDeep(columns.stRetailerCols);
      const stColumnsExport = cloneDeep(columns.stRetailerColsExport);

      appendRetailers(
        1,
        stColumns,
        record,
        "traffic",
        columns.renderNumberPercentFunction,
        true
      );
      appendRetailers(
        2,
        stColumns,
        record,
        "traffic_percentage",
        columns.renderPercentagePointsFunction
      );
      // PPT Export
      appendRetailersPPTExport(
        1,
        stColumnsPPTExport,
        record,
        "traffic",
        shareColumns.renderNumberPercentagePPT,
        true
      );
      appendRetailersPPTExport(
        2,
        stColumnsPPTExport,
        record,
        "traffic_percentage",
        shareColumns.renderPercentagePointsPPT
      );

      // Export
      appendRetailersExport(
        stColumnsExport,
        record,
        "traffic",
        stColumns[1].title,
        columns.renderTextAbsCommaFunction,
        columns.renderTextVariancePercentageFunction
      );

      // Export
      appendRetailersExport(
        stColumnsExport,
        record,
        "traffic_percentage",
        stColumns[2].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      setStRetailerColumns(stColumns);
      setStRetailerColumnsExport(stColumnsExport);
      setStRetailerColumnsPPTExport(stColumnsPPTExport)
      dispatch(
        setExportData({
          tableData: {
            callout: getTableCallout(),
            description: getTableDescription(),
            data: tableData.data,
            brands: brands
          },
        })
      );
    }
  }, [tableData]);

  // Update the applied filters section
  // if nothing is selecte set ALL otherwise display the actual items
  const setDeclareAppliedFilters = (finalFilter, filterName, filterData) => {
    if (
      (finalFilter[filterName].length === filterData.length ||
        finalFilter[filterName].length === 0) &&
      finalFilter[filterName].length !== 1
    )
      finalFilter[filterName] = ["ALL"];

    return finalFilter;
  }

  // Set the filters applied based on the filters selected on the UI while clicking on Apply button
  const declareAppliedFilters = (inputFilter) => {
    let finalFilter = cloneDeep(inputFilter);

    const filtersToApply = [
      { name: "product_feed_philosophy", data: feedingPhilosophies },
      { name: "product_texture", data: textures },
      { name: "product_price_tier", data: priceTiers },
      { name: "product_function", data: functions },
      { name: "product_life_stage", data: lifeStages },
      { name: "product_breed_size", data: breedSizes },
      { name: "product_package_type", data: packageTypes },
      { name: "product_protein", data: proteins },
    ];

    filtersToApply.forEach(filter => {
      finalFilter = setDeclareAppliedFilters(finalFilter, filter.name, filter.data);
    });

    dispatch(setAppliedFilters(finalFilter));
  };

  const getData = (filters, fetchFilterData = true, initialFilterData = true, clearHistory = true) => {
    declareAppliedFilters(filters);
    dispatch(setFilters(filters));

    // Fetch filter data if needed
    if (fetchFilterData) {
      if (initialFilterData) {
        dispatch(getAllFilters(filters));
      } else {
        dispatch(getFilterData({
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        }, true));
      }

    }

    // Dispatch other data fetching actions
    dispatch(getAllSubFilters(filters));
    dispatch(getSearchTermOverview(filters));
    dispatch(getComparisonTrendData(filters));
    dispatch(getTableData(filters));
    dispatch(getScatterChartData(filters));
  };

  const onTableChange = (pagination, data = filters, sorter, extra) => {
    if (extra.action === "paginate") {
      const newFilters = {
        ...filters,
        searchTermPagination: {
          ...filters.searchTermPagination,
          pageIndex: pagination.current !== filters.searchTermPagination.pageIndex ? pagination.current : 1,
          pageSize: pagination.pageSize,
          search: tableSearch[0] ? tableSearch[0] : ""
        }
      }
      dispatch(getTableData(newFilters));
      dispatch(setFilters(newFilters));
    }
    else if (extra.action === "filter") {
      const newFilters = {
        ...filters,
        searchTermPagination: {
          pageIndex: pagination.current !== filters.searchTermPagination.pageIndex ? pagination.current : 1,
          pageSize: pagination.pageSize,
          search: tableSearch[0] ? tableSearch[0] : ""
        }
      }
      dispatch(getTableData(newFilters));
      dispatch(setFilters(newFilters));
    }
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    const newFilters = {
      ...filters,
      searchTermPagination: {
        pageIndex: 1,
        pageSize: 100,
        search: ""
      }
    }
    dispatch(getTableData(newFilters));
    dispatch(setFilters(newFilters));
    dispatch(setSearch([]))
  };
  const applyFilters = () => {
    const newFilters = {
      ...filters,
      ...updateRetailerDropdown(filters, retailers, dispatch),
      // default_brand: [defaultBrand],
      deep_dive_list: [],
    };
    setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
    getData(newFilters, false, false);
  };

  const getRemainingFilters = (label) => {
    if (!filterChanged) return;

    setFilterChanged(false);

    if (label === "product_keyword_category") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_keyword_type") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_feed_philosophy") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_texture") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_price_tier") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_function") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,
          product_function: filters.product_function,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_life_stage") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,
          product_function: filters.product_function,
          product_life_stage: filters.product_life_stage,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_breed_size") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,
          product_function: filters.product_function,
          product_life_stage: filters.product_life_stage,
          product_breed_size: filters.product_breed_size,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_package_type") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,
          product_function: filters.product_function,
          product_life_stage: filters.product_life_stage,
          product_breed_size: filters.product_breed_size,
          product_package_type: filters.product_package_type,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_protein") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,
          product_function: filters.product_function,
          product_life_stage: filters.product_life_stage,
          product_breed_size: filters.product_breed_size,
          product_package_type: filters.product_package_type,
          product_protein: filters.product_protein,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "customer_retailer_details") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,
          product_function: filters.product_function,
          product_life_stage: filters.product_life_stage,
          product_breed_size: filters.product_breed_size,
          product_package_type: filters.product_package_type,
          product_protein: filters.product_protein,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "year_period") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,
          product_function: filters.product_function,
          product_life_stage: filters.product_life_stage,
          product_breed_size: filters.product_breed_size,
          product_package_type: filters.product_package_type,
          product_protein: filters.product_protein,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "time_aggregate") {
      dispatch(
        getFilterData({
          product_keyword_category: filters.product_keyword_category,
          product_keyword_type: filters.product_keyword_type,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          product_price_tier: filters.product_price_tier,
          product_function: filters.product_function,
          product_life_stage: filters.product_life_stage,
          product_breed_size: filters.product_breed_size,
          product_package_type: filters.product_package_type,
          product_protein: filters.product_protein,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    }
  };

  const changeFilter = (label, selectedValue) => {
    if (!isEqual(filters[label], selectedValue)) setFilterChanged(true);

    let newFilters = { ...filters };
    if (
      selectedValue &&
      selectedValue.length &&
      selectedValue.includes("all")
    ) {
      let all = [];
      if (label === "product_keyword_type") all = keywordTypes;
      else if (label === "product_feed_philosophy") all = feedingPhilosophies;
      else if (label === "product_texture") all = textures;
      else if (label === "product_price_tier") all = priceTiers;
      else if (label === "product_function") all = functions;
      else if (label === "product_life_stage") all = lifeStages;
      else if (label === "product_breed_size") all = breedSizes;
      else if (label === "product_package_type") all = packageTypes;
      else if (label === "product_protein") all = proteins;
      if (selectedValue.length === all.length + 1) selectedValue = [];
      else selectedValue = [...all];
    }

    newFilters[label] = selectedValue;
    dispatch(setFilters(newFilters));
    // setLocalFilter(newFilters);
    return newFilters;
  };

  const selectWithAll = (label, selectedValue, allValues) => {
    let newFilters = { ...filters };
    if (
      selectedValue &&
      selectedValue.length &&
      selectedValue.includes("all")
    ) {
      if (selectedValue.length === allValues.length + 1) selectedValue = [];
      else selectedValue = [...allValues];
    }

    newFilters[label] = selectedValue;
    return newFilters;
  };

  useEffect(() => {
    if (comparisonTrendData.length) setStored(false);
  }, [comparisonTrendData]);

  useEffect(() => {
    const initialFilters = {
      ...getInitialFilters(),
      // default_brand: [defaultBrand],
    };

    initialUrlFilter(initialFilters, ecomCommonFilters, filters, defaultBrand, getData, true);

    setLastDefaultBrand(defaultBrand);
  }, [defaultBrand, props.reportApply]);

  useEffect(() => {
    const processedFilters = processFilters(filters, getState);
    processedFilters.retailer_view =
      processedFilters.retailer_view[0] == 1
        ? ["Deep Dive Retailer"]
        : ["Retailer View"];
    dispatch(setExportData({ filters: processedFilters }));
  }, [filters]);


  // Column Search
  const getColumnSearchProps = ({ title, dataIndex }) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="seach-field">

        <Input
          placeholder={`Search ${title}`}
          value={tableSearch[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            dispatch(setSearch([e.target.value]))
          }}
          onPressEnter={() => handleSearch(confirm)}
          className="search-data"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            className="search-btn"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(confirm);
            }}
            size="small"
            className="search-btn"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: <SearchOutlined />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    render: (text) => text,
  });
  columns.stRetailerCols[0] = {
    ...columns.stRetailerCols[0],
    ...getColumnSearchProps(columns.stRetailerCols[0].children[0]),
  };

  const prepareTableExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));
    const brandsData = Object.values(data?.filters?.retailer_view_brand ?? []).flat();
    const retailerBrand = brands?.flatMap(item =>
      item.children.filter(brand =>
        brandsData.some(i => brand.value.includes(i))
      ).map(brand => brand.title)
    ).filter(Boolean);
    data.filters = {
      product_keyword_category: data.filters.product_keyword_category,
      product_keyword_type: data.filters.product_keyword_type,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      product_price_tier: data.filters.product_price_tier,
      product_function: data.filters.product_function,
      product_life_stage: data.filters.product_life_stage,
      product_breed_size: data.filters.product_breed_size,
      product_package_type: data.filters.product_package_type,
      product_protein: data.filters.product_protein,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
      retailer_view_brand: retailerBrand,
      retailer_view: data.filters.retailer_view,
      selected_retailer: data.filters.selected_retailer,
      searchTermPagination: data.filters.searchTermPagination,
    };

    return data;
  };
  const prepareTrendExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));

    data.filters = {
      product_keyword_category: data.filters.product_keyword_category,
      product_keyword_type: data.filters.product_keyword_type,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      product_price_tier: data.filters.product_price_tier,
      product_function: data.filters.product_function,
      product_life_stage: data.filters.product_life_stage,
      product_breed_size: data.filters.product_breed_size,
      product_package_type: data.filters.product_package_type,
      product_protein: data.filters.product_protein,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
      view_by: data.filters.view_by,
      trend_product_brand: data.filters.trend_product_brand,
      trend_view: data.filters.trend_view,
      trend_limit: data.filters.trend_limit,
      traffic_percentage_limit: data.filters.traffic_percentage_limit,
    };

    return data;
  };

  const prepareStrategyExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));
    const brandsData = Object.values(data?.filters?.strategy_brand ?? []).flat();

    const retailerBrand = brands?.flatMap(item =>
      item.children.filter(brand =>
        brandsData.some(i => brand.value.includes(i))
      ).map(brand => brand.title)
    ).filter(Boolean);
    data.filters = {
      product_keyword_category: data.filters.product_keyword_category,
      product_keyword_type: data.filters.product_keyword_type,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      product_price_tier: data.filters.product_price_tier,
      product_function: data.filters.product_function,
      product_life_stage: data.filters.product_life_stage,
      product_breed_size: data.filters.product_breed_size,
      product_package_type: data.filters.product_package_type,
      product_protein: data.filters.product_protein,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
      strategy_view_by: data.filters.strategy_view_by,
      strategy_category: data.filters.strategy_category,
      strategy_brand: retailerBrand
    };

    return data;
  };

  const exportTableData = () =>
    exportEComTable(
      stRetailerColumnsExport,
      prepareTableExport(exportData),
      getTableTitle()
    );
  const handlePPTExport = (tableColumn, appliedFilters, searchTermOverview) => {
    pptExportST(exportData, tableColumn, appliedFilters, searchTermOverview)
    exportTableData()
  }

  const exportTrends = () => exportSTTrends(prepareTrendExport(exportData));
  const exportBubbleChart = () => exportSTBubbleChart(prepareStrategyExport(exportData));
  const handleExcelExport = () => {
    exportSTExport(exportData, stRetailerColumnsExport, getTableTitle())
  }

  useEffect(() => {
    if (filters.strategy_view_by[0] === "Brand" && strategyData.length) {
      const brandColor = strategyData.map((i) => i.manufacturer_color)
      const uniqueColor = Array.from(new Set(brandColor))
      const allData = bubbleLegend.map((item) => {
        if (!!uniqueColor.find((i) => i === item.color)) {
          return { ...item, isVisible: true }
        } else {
          return { ...item, isVisible: false }
        }
      })
      setBubbleLegend(allData)
    }
  }, [strategyData])
  return (
    <Wrapper
      hideHeader={props.hideReportHeader}
      hidePage={isReport}
      heading={"1"}
      tab={"6"}
      innerHeading={"13"}
      ecomTab={"0"}
    >
      <Content
        className={
          "site-layout-background" +
          (isReport
            ? " content-none"
            : " content-body")
        }
      >
        <Loader
          tip="Due to high volume of search terms data, load might take some time. Please wait while loading."
          loading={isReport ? props.reportLoader : loading > 0}
        >
          {!props.hideReportFilters && (
            <>
              <Row>
                <Navigator refreshDate={searchTerm} />
              </Row>
              <Row className="beVisible-card">
                <div>
                  <span>
                    <img src={mdiCheck} alt="Diagnostics" width="22" />
                  </span>
                  <b className="se-info"> Be Visible</b> Optimize brand
                  listings with relevant keywords to increase visibility and
                  drive sales.
                </div>
              </Row>

              <Row className="se-tabs">
                <Col className="btn-grp">
                  <div>
                    <Radio.Group
                      buttonStyle="solid"
                      defaultValue="searchTerm"
                      onChange={(e) => handleTab(e, history)}
                    >
                      <Radio.Button value="shareOfShelf">
                        Share of Shelf
                      </Radio.Button>
                      <Radio.Button value="searchTerm">
                        Search Insights
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </Col>
                <Col>
                  <Row gutter={20} className="se-export">
                    <Col className="export-col">
                      <Dropdown
                        overlay={() => (
                          <Menu>
                            <Menu.Item onClick={() => (appInsights.trackEvent({ name: 'PPT_FOR_SEARCH_TERM' }), handlePPTExport(stRetailerColumnsPPTExport, appliedFilters, searchTermOverview))}>Download as PPT</Menu.Item>
                            <Menu.Item onClick={() => (appInsights.trackEvent({ name: 'EXCEL_FOR_SEARCH_TERM' }), handleExcelExport())}>Download as Excel</Menu.Item>
                          </Menu>
                        )}
                        className="d-more"
                      >
                        <Button
                          icon={<DownloadOutlined />}
                        >
                          Export Report
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                align="middle"
                className="header-data"
                gutter={[16, 16]}
              >
                <Col>
                  <Form
                    name="horizontal_login"
                    className="performance-filter"
                    layout="inline"
                  >
                    <div className="se-filters">
                      <Row gutter={8}>
                        <Col>
                          <label className="label-block">
                            Keyword Category
                          </label>

                          <TreeSelect
                            className="keyword-category"
                            dropdownStyle={{
                              maxHeight: "400px",
                              overflow: "hidden",
                            }}
                            treeData={keywordCategories}
                            treeCheckable
                            treeDefaultExpandAll
                            showCheckedStrategy={SHOW_PARENT}
                            placeholder="Please select"
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            maxTagCount="responsive"
                            allowClear
                            loading={dropdownLoading}
                            value={filters.product_keyword_category}
                            onMouseLeave={() =>
                              getRemainingFilters("product_keyword_category")
                            }
                            onChange={(val) => {
                              if (
                                !isEqual(filters.product_keyword_category, val)
                              )
                                setFilterChanged(true);
                              dispatch(
                                setFilters({
                                  ...filters,
                                  product_keyword_category: val,
                                })
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <label className="label-block">Keyword Type</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            className="keyword-filter"
                            maxTagCount="responsive"
                            value={filters.product_keyword_type}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            allowClear
                            placement="bottomLeft"
                            onMouseLeave={() =>
                              getRemainingFilters("product_keyword_type")
                            }
                            onChange={(val) => {
                              changeFilter("product_keyword_type", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {keywordTypes.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Feeding Philosophy
                          </label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            allowClear
                            className="keyword-filter"
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            maxTagCount="responsive"
                            value={filters.product_feed_philosophy}
                            onMouseLeave={() =>
                              getRemainingFilters("product_feed_philosophy")
                            }
                            onChange={(val) => {
                              changeFilter("product_feed_philosophy", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {feedingPhilosophies.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Texture/Format</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            loading={dropdownLoading}
                            className="keyword-filter"
                            allowClear
                            maxTagCount="responsive"
                            value={filters.product_texture}
                            autoClearSearchValue={false}
                            onMouseLeave={() =>
                              getRemainingFilters("product_texture")
                            }
                            onChange={(val) => {
                              changeFilter("product_texture", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {textures.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Price Tier</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            placement="bottomLeft"
                            className="keyword-filter"
                            allowClear
                            maxTagCount="responsive"
                            value={filters.product_price_tier}
                            onMouseLeave={() =>
                              getRemainingFilters("product_price_tier")
                            }
                            onChange={(val) => {
                              changeFilter("product_price_tier", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {priceTiers.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Function</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            placement="bottomLeft"
                            className="keyword-filter"
                            allowClear
                            maxTagCount="responsive"
                            value={filters.product_function}
                            onMouseLeave={() =>
                              getRemainingFilters("product_function")
                            }
                            onChange={(val) => {
                              changeFilter("product_function", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {functions.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Life Stage</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            placement="bottomLeft"
                            defaultValue={["all"]}
                            className="keyword-filter-second"
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            value={filters.product_life_stage}
                            onMouseLeave={() =>
                              getRemainingFilters("product_life_stage")
                            }
                            onChange={(val) => {
                              changeFilter("product_life_stage", val);
                            }}
                            showSearch
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {lifeStages.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Breed Size</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            mode="multiple"
                            defaultValue={["all"]}
                            style={{ width: 90 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            value={filters.product_breed_size}
                            onMouseLeave={() =>
                              getRemainingFilters("product_breed_size")
                            }
                            onChange={(val) => {
                              changeFilter("product_breed_size", val);
                            }}
                            showSearch
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {breedSizes.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Package Type</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            mode="multiple"
                            defaultValue={["all"]}
                            style={{ width: 90 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            value={filters.product_package_type}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_package_type")
                            }
                            onChange={(val) => {
                              changeFilter("product_package_type", val);
                            }}
                            showSearch
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {packageTypes.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Protein</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            mode="multiple"
                            defaultValue={["all"]}
                            style={{ width: 90 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            value={filters.product_protein}
                            onMouseLeave={() =>
                              getRemainingFilters("product_protein")
                            }
                            onChange={(val) => {
                              changeFilter("product_protein", val);
                            }}
                            showSearch
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {proteins.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                      <Row gutter={8} style={{ marginTop: 10 }}>
                        <Col>
                          <Tooltip title="Top 7 KPIs coming soon">
                            <label className="label-block">Retailer</label>
                            <Select
                              placeholder="All"
                              placement="bottomLeft"
                              dropdownMatchSelectWidth={false}
                              style={{ width: 95 }}
                              maxTagCount="responsive"
                              value={filters.customer_retailer_details}
                              onChange={(val) => {
                                dispatch(
                                  setFilters({
                                    ...filters,
                                    deep_dive_list: [],
                                    customer_retailer_details: [val],
                                  })
                                );
                              }}
                              showSearch
                            >
                              {retailers.map((retailer) => (
                                <Option
                                  value={retailer}
                                >
                                  {retailer}
                                </Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Time Aggregate</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            // defaultValue={["L4W"]}
                            style={{ width: 90 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            value={filters.time_aggregate}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("time_aggregate")
                            }
                            onChange={(val) => {
                              changeFilter("time_aggregate", [val]);
                            }}
                            showSearch
                          >
                            {timeAggregates.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Year Period</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            // defaultValue={["2022 p05"]}
                            dropdownStyle={{ minWidth: "130px" }}
                            style={{ width: 90 }}
                            maxTagCount="responsive"
                            value={filters.year_period}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("year_period")
                            }
                            onChange={(val) => {
                              changeFilter("year_period", [val]);
                            }}
                            showSearch
                          >
                            {yearPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Benchmark Period
                          </label>
                          <Select
                            placeholder="All"
                            // defaultValue={["P3"]}
                            style={{ width: 110 }}
                            maxTagCount="responsive"
                            dropdownStyle={{ minWidth: "130px" }}
                            loading={dropdownLoading}
                            value={filters.benchmark}
                            onChange={(val) => {
                              changeFilter("benchmark", [val]);
                            }}
                            showSearch
                          >
                            {benchmarkPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className="se-applyBtn"
                            onClick={applyFilters}
                            disabled={dropdownLoading}
                          >
                            Apply
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Col>
              </Row>
            </>
          )}
          {!isReport && (
            <Row className="sticky-filter">
              <Col span={24} className="Filters-apply">
                <div className="Report-selected-filters">
                  <div className="filter-item">
                    Keyword Category -{" "}
                    <span>
                      {/* The selected Keyword Category filters should be visible only */}
                      {Array.isArray(appliedFilters.product_keyword_category)
                        ? appliedFilters.product_keyword_category.length ===
                          0 ||
                          appliedFilters.product_keyword_category.length ===
                          keywordCategories.length
                          ? "ALL"
                          : appliedFilters.product_keyword_category.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Keyword Type -{" "}
                    <span>
                      {/* The selected Keyword Type filters should be visible only */}
                      {Array.isArray(appliedFilters.product_keyword_type)
                        ? appliedFilters.product_keyword_type.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Feeding Philosophy -{" "}
                    <span>
                      {/* The selected Feeding Philosophy filters should be visible only */}
                      {Array.isArray(appliedFilters.product_feed_philosophy)
                        ? appliedFilters.product_feed_philosophy.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Texture -{" "}
                    <span>
                      {/* The selected Texture filters should be visible only */}
                      {Array.isArray(appliedFilters.product_texture)
                        ? appliedFilters.product_texture.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Price Tier -{" "}
                    <span>
                      {/* The selected Price Tier filters should be visible only */}
                      {Array.isArray(appliedFilters.product_price_tier)
                        ? appliedFilters.product_price_tier.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Function -{" "}
                    <span>
                      {/* The selected Function filters should be visible only */}
                      {Array.isArray(appliedFilters.product_function)
                        ? appliedFilters.product_function.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Life Stage -{" "}
                    <span>
                      {/* The selected Life Stage filters should be visible only */}
                      {Array.isArray(appliedFilters.product_life_stage)
                        ? appliedFilters.product_life_stage.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Breed Size -{" "}
                    <span>
                      {/* The selected Breed Size filters should be visible only */}
                      {Array.isArray(appliedFilters.product_breed_size)
                        ? appliedFilters.product_breed_size.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Package Type -{" "}
                    <span>
                      {/* The selected Package Type filters should be visible only */}
                      {Array.isArray(appliedFilters.product_package_type)
                        ? appliedFilters.product_package_type.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Protein -{" "}
                    <span>
                      {/* The selected Protein filters should be visible only */}
                      {Array.isArray(appliedFilters.product_protein)
                        ? appliedFilters.product_protein.join()
                        : "ALL"}
                    </span>
                  </div>

                </div>
                <div className="Keyword-selected-filters">
                  <div className="filter-item">
                    Retailer -{" "}
                    <span>{appliedFilters.customer_retailer_details}</span>
                  </div>
                  <div className="filter-item">
                    Time Aggregate -{" "}
                    <span>{appliedFilters.time_aggregate}</span>
                  </div>
                  <div className="filter-item">
                    Year Period - <span>{appliedFilters.year_period}</span>
                  </div>
                  <div className="filter-item">
                    Benchmark Period - <span>{appliedFilters.benchmark}</span>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <div className="nutro-skus next-skus">
                <div className="section-title">
                  <h4>Overview &#38; Trend</h4>
                  <Dropdown
                    overlay={() => menu(exportTrends)}
                    trigger={["click"]}
                    placement="bottomRight"
                    className="d-more"
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <MoreOutlined />
                    </a>
                  </Dropdown>
                </div>
                <div className="nutro-skus-filter">
                  <div className="nutro-skus-filter-category">
                    <div className="se-viewBy">
                      <div>
                        <span className="view-label">View By</span>
                        <Select
                          placeholder="All"
                          dropdownMatchSelectWidth={false}
                          style={{ marginRight: 10 }}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          value={filters.view_by[0]}
                          onChange={(value) => {
                            const newFilters = {
                              ...filters,
                              view_by: [value],
                              deep_dive_list: [],
                            };
                            dispatch(setFilters(newFilters));
                            dispatch(getSearchTermOverview(newFilters));
                            dispatch(getComparisonTrendData(newFilters));
                          }}
                          options={viewBySearch.map((kpi) => ({
                            value: kpi,
                            label: kpi,
                          }))}
                        />
                      </div>
                      <div>
                        <span className="view-label">Brand</span>
                        <TreeSelect
                          placeholder="Please select"
                          treeCheckable
                          showCheckedStrategy={SHOW_PARENT}
                          style={{ width: 130, marginRight: 10 }}
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          treeData={trendBrands}
                          dropdownMatchSelectWidth={false}
                          autoClearSearchValue={false}
                          maxTagCount="responsive"
                          allowClear
                          value={filters.trend_product_brand}
                          onChange={(val) =>
                            dispatch(
                              setFilters({
                                ...filters,
                                trend_product_brand: val,
                              })
                            )
                          }
                          onMouseLeave={() => {
                            const trendBrandsOnMouseLeave = [
                              ...selectedTrendBrands,
                            ];
                            const trendBrandsOnChange = [
                              ...filters.trend_product_brand,
                            ];
                            if (
                              !isEqual(
                                trendBrandsOnMouseLeave.sort(),
                                trendBrandsOnChange.sort()
                              )
                            ) {
                              setSelectedTrendBrands(
                                filters.trend_product_brand
                              );

                              const newFilters = {
                                ...filters,
                                deep_dive_list: [],
                              };
                              dispatch(setFilters(newFilters));
                              dispatch(getSearchTermOverview(newFilters));
                              dispatch(getComparisonTrendData(newFilters));
                            }
                          }}
                        />
                      </div>
                      <div>
                        <span className="view-label">View</span>
                        <Radio.Group
                          style={{ marginRight: 10 }}
                          defaultValue="a"
                          size="small"
                          value={filters.trend_view[0]}
                          onChange={(e) => {
                            const newFilters = {
                              ...filters,
                              trend_view: [e.target.value],
                              deep_dive_list: [],
                            };
                            dispatch(setFilters(newFilters));
                            dispatch(getSearchTermOverview(newFilters));
                            dispatch(getComparisonTrendData(newFilters));
                          }}
                        >
                          <Radio.Button value="top">Top</Radio.Button>
                          <Radio.Button value="bottom">Bottom</Radio.Button>
                        </Radio.Group>
                      </div>
                      <div>
                        <Radio.Group
                          defaultValue="all"
                          size="small"
                          value={
                            filters.trend_limit[0] === "ALL" ? "all" : "custom"
                          }
                          onChange={(e) => {
                            const value =
                              e.target.value === "all" ? "ALL" : "10";
                            const newFilters = {
                              ...filters,
                              trend_limit: [value],
                              deep_dive_list: [],
                            };

                            dispatch(setFilters(newFilters));
                            dispatch(getSearchTermOverview(newFilters));
                            dispatch(getComparisonTrendData(newFilters));
                          }}
                        >
                          <Radio.Button value="all">All</Radio.Button>
                          <Radio.Button value="custom">Custom</Radio.Button>
                        </Radio.Group>

                        {filters.trend_limit[0] !== "ALL" && (
                          <Input
                            type="number"
                            size="small"
                            style={{ width: "7vh", marginRight: 10 }}
                            value={
                              filters.trend_limit[0] === "ALL"
                                ? 0
                                : filters.trend_limit
                            }
                            onChange={(e) => {
                              const newFilters = {
                                ...filters,
                                trend_limit: [e.target.value],
                              };
                              dispatch(setFilters(newFilters));
                            }}
                            onBlur={() => {
                              if (
                                selectedTrendLimit !== filters.trend_limit[0]
                              ) {
                                if (
                                  !hasValue(
                                    searchTermOverview.total_search_term_count
                                  )
                                ) {
                                  return openNotificationWithIcon(
                                    "error",
                                    "Value can not be greater than total search terms"
                                  );
                                }
                                if (
                                  Number(filters.trend_limit[0]) >
                                  Number(
                                    searchTermOverview.total_search_term_count
                                  )
                                ) {
                                  return openNotificationWithIcon(
                                    "error",
                                    "Value can not be greater than total search terms"
                                  );
                                }

                                const newFilters = {
                                  ...filters,
                                  deep_dive_list: [],
                                };
                                setSelectTrendLimit(filters.trend_limit[0]);
                                dispatch(setFilters(newFilters));
                                dispatch(getSearchTermOverview(newFilters));
                                dispatch(getComparisonTrendData(newFilters));
                              }
                            }}
                          />
                        )}
                      </div>
                      {(<div>
                        <span className="view-label">Filter By Traffic %</span>
                        <InputNumber
                          type="number"
                          size="small"
                          max={100}
                          min={0}
                          style={{ width: "7vh", fontSize: "12px" }}
                          value={selectedTrafficLimit}
                          onChange={(e) => {
                            setSelectTrafficLimit(e);
                          }}
                          onBlur={() => {
                            if (
                              filters.traffic_percentage_limit?.[0] !==
                              selectedTrafficLimit
                            ) {
                              const newFilters = {
                                ...filters,
                                deep_dive_list: [],
                                traffic_percentage_limit: [
                                  selectedTrafficLimit,
                                ],
                              };
                              dispatch(setFilters(newFilters));
                              dispatch(getSearchTermOverview(newFilters));
                              dispatch(getComparisonTrendData(newFilters));
                              dispatch(getTableData(newFilters));
                            }
                          }}
                        />
                      </div>
                      )}
                    </div>
                    <div className="nutro-sku-filter-description">
                      <p>
                        <span className="total-count">
                          {searchTermOverview.total_search_term_count}
                        </span>{" "}
                        Total Search Terms
                      </p>
                      {filters.trend_limit[0] !== "ALL" && (
                        <p>
                          <span>{searchTermOverview.search_term_count}</span>{" "}
                          Search Terms ={" "}
                          <span>
                            {formatNumber(
                              searchTermOverview.search_term_percent
                            )}
                            %
                          </span>{" "}
                          of Search Terms ={" "}
                          <span>
                            {formatNumber(searchTermOverview.traffic_percent)}%
                          </span>{" "}
                          of Traffic
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="sub-card-container">
                  <div className="nutro-skus sub-card-item">
                    <div className="section-title">
                      <h4>Search Terms</h4>
                      <p>
                        Select a search term bar for detailed analysis (select
                        multiple using Ctrl key)
                      </p>
                    </div>
                    <SelectColumnLineChart
                      data={comparisonTrendData}
                      xField="Search Term"
                      yField={barLegends[filters.view_by[0]]}
                      viewBy="Search Term"
                      value={filters.deep_dive_list}
                      onChange={(value) => {
                        const newFilters = {
                          ...filters,
                          deep_dive_list: value,
                        };
                        dispatch(setFilters(newFilters));
                        dispatch(getDeepdiveTrendData(newFilters));
                      }}
                      // yBarUnit="%"
                      stored={stored}
                      setStored={setStored}
                      storageKey="SearchTermComparisonTrendChart"
                    />
                  </div>
                  <div className="nutro-skus sub-card-item">
                    <div className="section-title">
                      <h4>Search Term - Trend</h4>
                      <p>
                        Select more search terms in the left graph to compare
                        them here
                      </p>
                    </div>
                    <MultiLineChart
                      data={deepdiveMultiTrendData}
                      xField="Periods"
                      yField={barLegends[filters.view_by[0]][0]}
                      seriesField="Trend"
                      legendSize={16}
                    />
                  </div>
                </div>
              </div>

            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="nutro-skus next-skus">
                <div className="section-title">
                  <h4 className="section-title-callout callout-above">
                    {getTableCallout()}
                  </h4>
                  <Dropdown
                    overlay={() => menu(exportTableData)}
                    trigger={["click"]}
                    placement="bottomRight"
                    className="d-more"
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <MoreOutlined />
                    </a>
                  </Dropdown>
                  <h4>{getTableTitle()}</h4>
                  <p>{getTableDescription()}</p>
                </div>
                <div className="nutro-skus-filter">
                  <div className="nutro-skus-filter-category">
                    <div className="retailer-view-toggle">
                      <div
                        className={
                          filters.retailer_view[0] == 0
                            ? "selected"
                            : "unselected"
                        }
                      >
                        Retailer View
                      </div>
                      <div className="switch">
                        <Switch
                          size="small"
                          checked={filters.retailer_view[0] == 1}
                          onChange={(val) => {
                            const newFilters = {
                              ...filters,
                              retailer_view: [Number(val)],
                              searchTermPagination: {
                                pageIndex: 1,
                                pageSize: 100,
                                search: ""
                              }
                            };
                            dispatch(setFilters(newFilters));
                            dispatch(getTableData(newFilters));
                            dispatch(setSearch([]))
                          }}
                        />
                      </div>
                      <div
                        className={
                          filters.retailer_view[0] == 1
                            ? "selected"
                            : "unselected"
                        }
                      >
                        Deep Dive Retailer
                      </div>
                    </div>
                    <div className="retailer-view-filter-right">
                      {filters.retailer_view[0] == 1 && (
                        <div>
                          <span className="view-label">Brand</span>
                          <TreeSelect
                            placeholder="Please select"
                            treeCheckable
                            showCheckedStrategy={SHOW_PARENT}
                            style={{ width: 130, marginRight: 10 }}
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                            treeData={brands}
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            maxTagCount="responsive"
                            allowClear
                            value={filters.retailer_view_brand}
                            onChange={(val) =>
                              dispatch(
                                setFilters({
                                  ...filters,
                                  retailer_view_brand: val,
                                })
                              )
                            }
                            onMouseLeave={() => {
                              const retailerBrandsOnMouseLeave = [
                                ...selectedRetailerBrands,
                              ];
                              const retailerBrandsOnChange = [
                                ...filters.retailer_view_brand,
                              ];
                              if (
                                !isEqual(
                                  retailerBrandsOnMouseLeave.sort(),
                                  retailerBrandsOnChange.sort()
                                )
                              ) {
                                setSelectedRetailerBrands(
                                  filters.retailer_view_brand
                                );

                                dispatch(setFilters(filters));
                                dispatch(getTableData(filters));
                              }
                            }}
                          />
                        </div>
                      )}
                      <div>
                        <span className="view-label">Retailer</span>
                        {filters.retailer_view[0] == 1 ? (
                          <Select
                            placeholder="All"
                            dropdownMatchSelectWidth={false}
                            style={{ marginRight: 10 }}
                            placement="bottomLeft"
                            maxTagCount="responsive"
                            value={filters.selected_retailer}
                            onChange={(value) => {
                              const newFilters = {
                                ...filters,
                                selected_retailer: [value],
                              };
                              dispatch(setFilters(newFilters));
                              dispatch(getTableData(newFilters));
                            }}
                            options={retailerDropdown.map((val) => ({
                              value: val,
                              label: val,
                            }))}
                          />
                        ) : (
                          <Select
                            mode="multiple"
                            placeholder="Please Select"
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            style={{ width: 110 }}
                            placement="bottomLeft"
                            maxTagCount="responsive"
                            value={filters.selected_retailer}
                            onChange={(val) =>
                              dispatch(
                                setFilters({
                                  ...filters,
                                  selected_retailer: val,
                                })
                              )
                            }
                            onMouseLeave={() => {
                              const selectedRetailerOnMouseLeave = [
                                ...selectedRetailer,
                              ];
                              const selectedRetailerOnChange = [
                                ...filters.selected_retailer,
                              ];

                              if (
                                !isEqual(
                                  selectedRetailerOnMouseLeave.sort(),
                                  selectedRetailerOnChange.sort()
                                )
                              ) {
                                dispatch(
                                  setSelectedRetailer(filters.selected_retailer)
                                );
                                dispatch(getTableData(filters));
                              }
                            }}
                          >
                            {retailerDropdown.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <CustomTable
                  hideUpperSection={true}
                  rowKey={rowKey}
                  scroll={true}
                  xScroll="max-content"
                  hideFilter={true}
                  data={tableData.data}
                  columns={stRetailerColumns}
                  onChange={onTableChange}
                  pagination={{ pageSizeOptions: ['50', '100', '500', '1000'], current: filters.searchTermPagination.pageIndex, pageSize: filters.searchTermPagination.pageSize, total: tableData.totalPages }}
                />
                {/* <Pagination
                   className="pagination-style"
                  current={pageIndex} total={tableData.length} onChange={(page, size) => {handlePagination(page, size)}} pageSizeOptions={['50', '100', '500', '1000']}
                 /> */}
              </div>
            </Col>
          </Row>
          <Row className="diagnostics-chart" style={{ marginTop: "10px" }}>
            <div className="kpiChart">
              <div>
                <h4>Search Term Strategy</h4>
                <Dropdown
                  overlay={() => menu(exportBubbleChart)}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="d-more"
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <MoreOutlined />
                  </a>
                </Dropdown>
                <h4 className="section-title-callout">Bubble size is driven by Searchterm Traffic</h4>
                <p>Select area to zoom on bubbles</p>
              </div>
              <div className="kpi-dropdown" style={{ justifyContent: 'end' }}>
                <Row>
                  {/* <Col>
                      <div>
                        <span className="view-label">View By Category</span>
                        <Select
                          placeholder="All"
                          dropdownMatchSelectWidth={false}
                          style={{ marginRight: 10, width: 150 }}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          dropdownStyle={{ minWidth: "150px" }}
                          value={filters.strategy_category}
                          onChange={(value) => {
                            const newFilters = {
                              ...filters,
                              strategy_category: [value],
                              strategy_brand: [],
                            };
                            dispatch(getScatterChartData(newFilters));
                            dispatch(setFilters(newFilters));
                          }}
                          options={categories.map((kpi) => ({
                            value: kpi,
                            label: kpi,
                          }))}
                        />
                      </div>
                    </Col> */}
                  <Col>
                    <div>
                      <span className="view-label">View By</span>
                      <Select
                        placeholder="All"
                        dropdownMatchSelectWidth={false}
                        style={{ marginRight: 10, width: 120 }}
                        placement="bottomLeft"
                        maxTagCount="responsive"
                        // defaultValue="Category"
                        value={filters.strategy_view_by}
                        onChange={(value) => {
                          const newFilters = {
                            ...filters,
                            strategy_view_by: [value],
                            strategy_brand: [],
                            strategy_category: []
                          };
                          setSelectedStrategyBrands([])
                          dispatch(getAllSubFilters(newFilters));
                          dispatch(setFilters(newFilters));
                          dispatch(getScatterChartData(newFilters));
                        }}
                        options={viewByStretagy.map((kpi) => ({
                          value: kpi,
                          label: kpi,
                        }))}
                      />
                    </div>
                  </Col>
                  {filters.strategy_view_by[0] === "Category" ? (
                    <Col>
                      <div>
                        <span className="view-label">Category</span>
                        <Select
                          placeholder="All"
                          dropdownMatchSelectWidth={false}
                          style={{ marginRight: 10, width: 100 }}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          dropdownStyle={{ minWidth: "150px" }}
                          value={filters.strategy_category}
                          onChange={(value) => {
                            const newFilters = {
                              ...filters,
                              strategy_category: [value],
                              strategy_brand: [],
                            };
                            setSelectedStrategyBrands([])
                            dispatch(getScatterChartData(newFilters));
                            dispatch(setFilters(newFilters));
                          }}
                          options={categories.map((kpi) => ({
                            value: kpi,
                            label: kpi,
                          }))}
                        />
                      </div>
                    </Col>
                  ) : (
                    <Col>
                      <div>
                        <span className="view-label">Brand</span>
                        <TreeSelect
                          style={{ width: 150 }}
                          dropdownStyle={{
                            maxHeight: "400px",
                            overflow: "hidden",
                          }}
                          treeData={brands}
                          treeCheckable
                          // treeDefaultExpandAll
                          showCheckedStrategy={SHOW_PARENT}
                          placeholder="Please select"
                          dropdownMatchSelectWidth={false}
                          autoClearSearchValue={false}
                          maxTagCount="responsive"
                          allowClear
                          value={filters.strategy_brand}
                          onChange={(value) => {
                            dispatch(
                              setFilters({
                                ...filters,
                                strategy_brand: value,
                                strategy_category: [],
                              })
                            )
                          }}
                          onMouseLeave={() => {
                            const trendBrandsOnMouseLeave = [
                              ...selectedStrategyBrands,
                            ];
                            const trendBrandsOnChange = [
                              ...filters.strategy_brand,
                            ];
                            if (
                              !isEqual(
                                trendBrandsOnMouseLeave.sort(),
                                trendBrandsOnChange.sort()
                              )
                            ) {
                              setSelectedStrategyBrands(
                                filters.strategy_brand
                              );

                              const newFilters = {
                                ...filters,
                                strategy_category: [],
                              };
                              dispatch(getScatterChartData(newFilters));
                            }
                          }}
                          filterTreeNode={(search, item) => {
                            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                          }}
                        />
                      </div>
                    </Col>
                  )}
                  <Col>
                    <label className="se-bubbleLabel">
                      Show Labels
                    </label>
                    <Switch onChange={handleLabel} checked={showLabel} />
                  </Col>
                </Row>
              </div>
              <Row>
                <div className="se-legends" >
                  <ul className='searchTerm-list'>
                    {
                      // && unique_innovation.slice(legendCount * 6, (legendCount * 6) + 6)
                      filters?.strategy_view_by && filters.strategy_view_by.length && filters.strategy_view_by[0] === "Brand" && bubbleLegend.filter((i) => i.isVisible).map((item, index) => {
                        return (
                          // getBrandData && getBrandData.map((val) => {
                          //   // return val.manufacturer_color === item.color ? item.color : ""
                          //   if(val.manufacturer_color == item.color){
                          //     console.log("val.manufacturer_color", val.manufacturer_color, item.color)
                          // return(
                          <li><span style={{ background: item.color }} ></span> &nbsp; {item.name}</li>
                          //   )
                          //     }
                          // })
                        )
                      })}
                  </ul>
                  <div>
                  </div>
                </div>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col
                  className="scatterChart"
                  style={{ width: "100%", marginTop: 20 }}
                >
                  <BubbleChart
                    data={strategyData}
                    xField="traffic_share_change"
                    yField="traffic_share"
                    xFieldName="Traffic Share Change (pts)"
                    yFieldName="Traffic Share (%)"
                    showLabel={showLabel}
                    // setChartClick={setChartClick}
                    // chartClick={chartClick}
                    // value={filters.keyword}
                    onChange={(value) => {
                      // const newFilters = {
                      //   ...filters,
                      //   keyword: value,
                      // };
                      // dispatch(setFilters(newFilters));
                      // dispatch(getTrendData(newFilters));
                      if (!value.data.length) {
                        dispatch(setStrategyData(strategyDataDuplicate));
                      }
                      if (value.data.length) {
                        dispatch(setStrategyData(value.data));
                      }
                    }}
                    storageKey="BubbleChart"
                    bubblechartLabel={bubblechartLabel}
                    isBubbleClickable={false}
                    showLegend={true}
                  />
                </Col>
              </Row>
            </div>
          </Row>
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(SearchTerm);
