import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Layout,
  Row,
  Form,
  Button,
  Select,
  Menu,
  Dropdown,
  Radio,
  TreeSelect,
  Switch,
  Tooltip,
  Popover,
} from "antd";
import { useHistory, withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  ArrowUpOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { getState } from "../../store";
import CustomTable from "../../components/customTable";
import { useSelector, useDispatch } from "react-redux";
import {
  getCardData,
  getCategoryBenchmarkData,
  getComparisonTrendData,
  getCompetitionBenchmarkData,
  getCompetitionFilterData,
  getDeepdiveTrendData,
  getScatterChartData,
  getInternalBenchmarkData,
  getMicroTableData,
  getTrendData,
  setSelectedCompetitor,
  getAllFilters,
  getFilterData,
  getInitialFilters,
  getMacroTableData,
  setAppliedFilters,
  setFilters,
  updateRetailerDropdown,
  setSelectedRetailer,
  setStrategyData,
  setShowLabel,
  processFilters,
  getInitialBottomFilters,
} from "../../slicers/shareOfShelf.slicer";
import Loader from "../../components/loader";
import {
  allBrands,
  openNotificationWithIcon,
  setWindowHistory,
  symbols,
  handleTab,
  setMinLimit,
  getTitleWidth,
  itemsListed,
  initialUrlFilter,
  replaceUrl,
  getLatestUpdatedFilter,
} from "../../utilities/helper";
import mdiCheck from "../../assets/images/mdi_eye-check.svg";
import { appInsights } from "../../utilities/appInsights";
import "../be-selected/be-selected.scss";
import { setExportData } from "../../slicers/exportData.slicer";
import {
  ColumnBulletChart,
  ColumnLineChart,
  MultiLineChart,
} from "../be-available/baUIHelper";
import {
  trafficSourceData,
  rowKeyMacro,
  viewByShare,
  shareOfShelfKPI,
  shareOfShelfKPIMulti,
  KPICard,
  declareAppliedFilters,
} from "./bvUIHelper";
import { isEqual, cloneDeep } from "lodash";
import "./be-visible.scss";
import shareColumns from "./bvColumns";
import CompetitionAndBenchmark, {
  compareVia,
} from "../../components/competition-benchmark";
import columns from "../be-available/baColumns";
import BubbleChart from "../../components/bubble-chart";
import trafficChart from "../../assets/images/keyword-traffic-chart.png";
import {
  exportBATrends,
  exportShareOfShelf,
  exportSOSKPIs,
  exportSOSScatter,
  exportSosTables,
  numFmt,
} from "../../utilities/exports/excel";
import Navigator from "../../components/bread-crumbs";
import { pptExportSos } from "../../utilities/exports/ecomppt";
import { ecomCommonFilters } from "../../slicers/diagnostics.slicer";

const { Content } = Layout;
const { Option } = Select;

function EcomBeVisible(props) {
  const dispatch = useDispatch();

  const { defaultBrand } = useSelector((state) => state.profile);
  const [selectedBrand, setSelectedBrand] = useState(defaultBrand);
  const isAllBrands = defaultBrand === allBrands;
  if (isAllBrands) viewByShare.S = "Brand";
  else viewByShare.S = "Sub Brand";

  const {
    loading,
    dropdownLoading,
    macroLoading,
    microLoading,
    pets,
    techs,
    subTechs,
    lifestage,
    feedingPhilosophies,
    textures,
    retailers,
    retailerDropdown,
    timeAggregates,
    yearPeriods,
    benchmarkPeriods,
    keyword,
    keywordType,
    keywordCategory,
    subKeywordCategory,
    competitors,

    brands,
    subBrands,
    customerRetailers,
    categoryBenchmarks,
    competitionBenchmarks,
    // kpis,
    trendData,
    filters,
    appliedFilters,
    cardData,
    comparisonTrendData,
    deepdiveSingleTrendData,
    deepdiveMultiTrendData,
    macroTableData,
    microTableData,
    competitionTableData,

    // on mouse leave
    selectedCompetitor,
    selectedRetailer,
    strategyData,
    strategyDataDuplicate,
    showLabel,
  } = useSelector((state) => state.shareOfShelf);
  const exportData = useSelector((state) => state.exportData);
  const { shareOfShelf } = useSelector((state) => state.refreshDate);

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);

  // On Mouse Leave events
  const [selectedDeepdive, setSelectedDeepdive] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState([]);

  const [competitionColumns, setCompetitionColumns] = useState(
    shareColumns.baCompetitionCols
  );

  const [lastDefaultBrand, setLastDefaultBrand] = useState("");
  const [filterChanged, setFilterChanged] = useState(false);
  const [expand, setExpand] = useState(false);
  const [newReport, setNewReport] = useState(false);
  const [createReport, setCreateReport] = useState(true);
  const [openSaveReportDialog, setOpenSaveReportDialog] = useState(false);
  const [newReportComponents, setNewReportComponents] = useState([]);
  const [components, setComponents] = useState([]);
  const [openMacro, setOpenMacro] = useState(false);
  const [openMicro, setOpenMicro] = useState(false);
  const [stored, setStored] = useState(false);
  const [chartClick, setChartClick] = useState(false);
  const [macroColumnsExport, setMacroColumnsExport] = useState(
    shareColumns.macroColsExport
  );
  const [microColumnsExport, setMicroColumnsExport] = useState(
    shareColumns.microColsExport
  );
  // const [showLabel, setShowLabel] = useState(false);
  const [chartStrategyData, setchartStrategyData] = useState([]);
  // On Mouse Leave events

  const isReport = props.isReport;
  const history = useHistory();
  const { SHOW_PARENT } = TreeSelect;
  const menu = (exportData) => (
    <Menu>
      <Menu.Item key="0" onClick={exportData}>
        Export Data
      </Menu.Item>
    </Menu>
  );

  const getTrendCallout = () =>
    "Data from 2023 forward due to increase in scraped search terms";

  const appendRetailers = (
    columnIndex,
    childIndex,
    columns,
    record,
    kpi,
    renderFunction,
    renderPPT,
    sort
  ) => {
    if (record[kpi]) {
      const sortingKpis = ["total_share_of_shelf", "product_keyword_traffic"];
      const sorting = sort && sortingKpis.includes(kpi);
      const isSingleRetailer = Object.keys(record[kpi]).length === 1;

      const parentTitle = columns[columnIndex].children[childIndex].title;
      const parentWidth = getTitleWidth(parentTitle, sorting);
      columns[columnIndex].children[childIndex].children = Object.keys(
        record[kpi]
      ).map((retailer) => {
        const retailerWidth = getTitleWidth(retailer, sorting);
        return {
          title: retailer,
          dataIndex: [kpi, retailer],
          key: `${kpi}${retailer}`,
          width: isSingleRetailer
            ? Math.max(parentWidth, retailerWidth)
            : retailerWidth,
          render: (val) => renderFunction(val),
          sorter: sorting
            ? (a, b) => (a[kpi][retailer]?.abs < b[kpi][retailer]?.abs ? -1 : 1)
            : false,
          className: "sub-td-border-left",
        };
      });

      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };

  //table column ppt export function
  const appendRetailersPPTExport = (
    columnIndex,
    childIndex,
    columns,
    record,
    kpi,
    renderFunction,
    renderPPTFunction,
    sort
  ) => {
    if (record[kpi]) {
      columns[columnIndex].children[childIndex].children = Object.keys(
        record[kpi]
      ).map((retailer) => {
        return {
          title: retailer,
          dataIndex: kpi,
          key: kpi,
          render: (val) => renderFunction(val),
          renderPPT: (val) => renderPPTFunction(val),
          className: "sub-td-border-left",
        };
      });

      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };
  const appendRetailersExport = (
    columns,
    record,
    kpi,
    title,
    renderTextAbs,
    renderTextVariance
  ) => {
    if (record[kpi]) {
      Object.keys(record[kpi]).forEach((retailer) => {
        // abs
        columns.push({
          title: `${title} ${retailer}`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) => renderTextAbs(val && val[retailer]),
        });

        // variance
        columns.push({
          title: `${title} ${retailer} vs Benchmark`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) => renderTextVariance(val && val[retailer]),
        });
      });
    }
  };
  const bubblechartLabel = {
    label: "share_of_shelf",
    sizeField: "share_of_shelf",
  };
  const handleLabel = (checked) => {
    dispatch(setShowLabel(checked));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const macroColumns = useCallback(
    (open, setOpen) => {
      // Macro table dynamic column binding
      if (macroTableData.length) {
        const record = macroTableData[0];
        const bvMacroColumns = shareColumns.macroCols(open, setOpen);

        appendRetailers(
          1,
          0,
          bvMacroColumns,
          record,
          "sales",
          shareColumns.renderAbsPercentageFunction
        );
        appendRetailers(
          1,
          1,
          bvMacroColumns,
          record,
          "sales_share_pet_tech",
          shareColumns.renderPercentagePointsFunction
        );
        appendRetailers(
          2,
          0,
          bvMacroColumns,
          record,
          "total_share_of_shelf_pet_tech",
          shareColumns.renderPercentagePointsFunction,
          true
        );
        appendRetailers(
          2,
          1,
          bvMacroColumns,
          record,
          "fair_share_of_index",
          shareColumns.renderPointsFunction
        );
        appendRetailers(
          2,
          2,
          bvMacroColumns,
          record,
          "organic_share_of_shelf",
          shareColumns.renderPercentagePointsFunction
        );
        appendRetailers(
          2,
          3,
          bvMacroColumns,
          record,
          "organic_fair_share_of_index",
          shareColumns.renderPointsFunction
        );
        appendRetailers(
          2,
          4,
          bvMacroColumns,
          record,
          "paid_share_of_shelf",
          shareColumns.renderPercentagePointsFunction
        );
        appendRetailers(
          2,
          5,
          bvMacroColumns,
          record,
          "paid_fair_share_of_index",
          shareColumns.renderPointsFunction
        );
        appendRetailers(
          3,
          0,
          bvMacroColumns,
          record,
          "number_of_placements",
          shareColumns.renderPlaneFunction
        );
        appendRetailers(
          3,
          1,
          bvMacroColumns,
          record,
          "average_placements",
          shareColumns.renderPlaneFunction
        );

        return bvMacroColumns;
      } else return shareColumns.macroCols(open, setOpen);
    },
    [macroTableData]
  );

  //create macro table columns for PPT export
  const macroColumnsPPTExport = useCallback(
    (open, setOpen) => {
      // Macro table dynamic column binding
      if (macroTableData.length) {
        const record = macroTableData[0];
        const bvMacroColumns = cloneDeep(shareColumns.macroColsPPtExport);
        appendRetailersPPTExport(
          1,
          0,
          bvMacroColumns,
          record,
          "sales",
          shareColumns.renderAbsPercentageFunction,
          shareColumns.renderNumberPercentagePPT
        );
        appendRetailersPPTExport(
          1,
          1,
          bvMacroColumns,
          record,
          "sales_share",
          shareColumns.renderPercentagePointsFunction,
          shareColumns.renderPercentagePointsPPT
        );
        appendRetailersPPTExport(
          2,
          0,
          bvMacroColumns,
          record,
          "total_share_of_shelf",
          shareColumns.renderPercentagePointsFunction,
          shareColumns.renderPercentagePointsPPT,
          true
        );
        appendRetailersPPTExport(
          2,
          1,
          bvMacroColumns,
          record,
          "fair_share_of_index",
          shareColumns.renderPointsFunction,
          shareColumns.renderNumberPointsPPT
        );
        appendRetailersPPTExport(
          2,
          2,
          bvMacroColumns,
          record,
          "organic_share_of_shelf",
          shareColumns.renderPercentagePointsFunction,
          shareColumns.renderPercentagePointsPPT
        );
        appendRetailersPPTExport(
          2,
          3,
          bvMacroColumns,
          record,
          "organic_fair_share_of_index",
          shareColumns.renderPointsFunction,
          shareColumns.renderNumberPointsPPT
        );
        appendRetailersPPTExport(
          2,
          4,
          bvMacroColumns,
          record,
          "paid_share_of_shelf",
          shareColumns.renderPercentagePointsFunction,
          shareColumns.renderPercentagePointsPPT
        );
        appendRetailersPPTExport(
          2,
          5,
          bvMacroColumns,
          record,
          "paid_fair_share_of_index",
          shareColumns.renderPointsFunction,
          shareColumns.renderNumberPointsPPT
        );
        appendRetailersPPTExport(
          3,
          0,
          bvMacroColumns,
          record,
          "number_of_placements",
          shareColumns.renderPlaneFunction,
          shareColumns.renderNumberPPT
        );
        appendRetailersPPTExport(
          3,
          1,
          bvMacroColumns,
          record,
          "average_placements",
          shareColumns.renderPlaneFunction,
          shareColumns.renderNumberPPT
        );

        return bvMacroColumns;
      } else return shareColumns.macroCols(open, setOpen);
    },
    [macroTableData]
  );

  const formatTableData = (table_data) => {
    const tableDataInStore = JSON.parse(JSON.stringify(table_data));
    const tableData = [];
    tableDataInStore.forEach((data) => {
      tableData.push(data);
      if (data.table_hierarchy_data)
        tableData.push(...data.table_hierarchy_data);
    });
    dispatch(setExportData({ macroTableData: { data: tableData } }));
  };
  useEffect(() => {
    if (macroTableData.length) {
      const record = macroTableData[0];
      const bvMacroColumns = shareColumns.macroCols(true, () => { });
      const bvMacroColumnsExport = shareColumns.macroColsExport();
      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "sales",
        bvMacroColumns[1]?.children[0].title,
        columns.renderTextAbsNumber,
        columns.renderTextVariancePercentageFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "sales_share_pet_tech",
        bvMacroColumns[1]?.children[1].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "total_share_of_shelf_pet_tech",
        bvMacroColumns[2]?.children[0].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "fair_share_of_index",
        bvMacroColumns[2]?.children[1].title,
        columns.renderTextAbsNumber,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "organic_share_of_shelf",
        bvMacroColumns[2]?.children[2].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "organic_fair_share_of_index",
        bvMacroColumns[2]?.children[3].title,
        columns.renderTextAbsNumber,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "paid_share_of_shelf",
        bvMacroColumns[2]?.children[4].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "paid_fair_share_of_index",
        bvMacroColumns[2]?.children[5].title,
        columns.renderTextAbsNumber,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "number_of_placements",
        bvMacroColumns[3]?.children[0].title,
        columns.renderTextWholeNumberFunction,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMacroColumnsExport,
        record,
        "average_placements",
        bvMacroColumns[3]?.children[1].title,
        columns.renderTextWholeNumberFunction,
        columns.renderTextVarianceNumberFunction
      );

      setMacroColumnsExport(bvMacroColumnsExport);
      formatTableData(macroTableData);
    }
  }, [macroTableData]);

  const microColumns = useCallback(
    (open, setOpen) => {
      // Micro table dynamic column binding
      if (microTableData.length) {
        const record = microTableData[0];
        const bvMicroColumns = shareColumns.microCols(open, setOpen);

        appendRetailers(
          2,
          0,
          bvMicroColumns,
          record,
          "product_keyword_traffic",
          shareColumns.renderPointsFunction,
          true
        );
        appendRetailers(
          3,
          0,
          bvMicroColumns,
          record,
          "sales",
          shareColumns.renderAbsPercentageFunction
        );
        appendRetailers(
          3,
          1,
          bvMicroColumns,
          record,
          "sales_share",
          shareColumns.renderPercentagePointsFunction
        );
        appendRetailers(
          4,
          0,
          bvMicroColumns,
          record,
          "total_share_of_shelf",
          shareColumns.renderPercentagePointsFunction,
          true
        );
        appendRetailers(
          4,
          1,
          bvMicroColumns,
          record,
          "fair_share_of_index",
          shareColumns.renderPointsFunction
        );
        appendRetailers(
          4,
          2,
          bvMicroColumns,
          record,
          "organic_share_of_shelf",
          shareColumns.renderPercentagePointsFunction
        );

        appendRetailers(
          4,
          3,
          bvMicroColumns,
          record,
          "organic_fair_share_of_index",
          shareColumns.renderPointsFunction
        );
        appendRetailers(
          4,
          4,
          bvMicroColumns,
          record,
          "paid_share_of_shelf",
          shareColumns.renderPercentagePointsFunction
        );
        appendRetailers(
          4,
          5,
          bvMicroColumns,
          record,
          "paid_fair_share_of_index",
          shareColumns.renderPointsFunction
        );
        appendRetailers(
          5,
          0,
          bvMicroColumns,
          record,
          "average_placements",
          shareColumns.renderPlaneFunction
        );
        // setMicroColumnsExport(bvMicroColumns);
        // dispatch(setExportData({ microTableData: { data: microTableData } }));
        return bvMicroColumns;
      } else return shareColumns.microCols(open, setOpen);
    },
    [microTableData]
  );

  // create micro table columns for PPT export
  const microColumnsPPTExport = useCallback(
    (open, setOpen) => {
      // Micro table dynamic column binding
      if (microTableData.length) {
        const record = microTableData[0];
        const bvMicroColumns = cloneDeep(shareColumns.microColsPPTExport);

        appendRetailersPPTExport(
          2,
          0,
          bvMicroColumns,
          record,
          "product_keyword_traffic",
          shareColumns.renderPointsFunction,
          shareColumns.renderNumberPointsPPT,
          true
        );
        appendRetailersPPTExport(
          3,
          0,
          bvMicroColumns,
          record,
          "sales",
          shareColumns.renderAbsPercentageFunction,
          shareColumns.renderNumberPercentagePPT
        );
        appendRetailersPPTExport(
          3,
          1,
          bvMicroColumns,
          record,
          "sales_share",
          shareColumns.renderPercentagePointsFunction,
          shareColumns.renderPercentagePointsPPT
        );
        appendRetailersPPTExport(
          4,
          0,
          bvMicroColumns,
          record,
          "total_share_of_shelf",
          shareColumns.renderPercentagePointsFunction,
          shareColumns.renderPercentagePointsPPT,
          true
        );
        appendRetailersPPTExport(
          4,
          1,
          bvMicroColumns,
          record,
          "fair_share_of_index",
          shareColumns.renderPointsFunction,
          shareColumns.renderNumberPointsPPT
        );
        appendRetailersPPTExport(
          4,
          2,
          bvMicroColumns,
          record,
          "organic_share_of_shelf",
          shareColumns.renderPercentagePointsFunction,
          shareColumns.renderPercentagePointsPPT
        );

        appendRetailersPPTExport(
          4,
          3,
          bvMicroColumns,
          record,
          "organic_fair_share_of_index",
          shareColumns.renderPointsFunction,
          shareColumns.renderNumberPointsPPT
        );
        appendRetailersPPTExport(
          4,
          4,
          bvMicroColumns,
          record,
          "paid_share_of_shelf",
          shareColumns.renderPercentagePointsFunction,
          shareColumns.renderPercentagePointsPPT
        );
        appendRetailersPPTExport(
          4,
          5,
          bvMicroColumns,
          record,
          "paid_fair_share_of_index",
          shareColumns.renderPointsFunction,
          shareColumns.renderNumberPointsPPT
        );
        appendRetailersPPTExport(
          5,
          0,
          bvMicroColumns,
          record,
          "average_placements",
          shareColumns.renderPlaneFunction,
          shareColumns.renderNumberPPT
        );
        // setMicroColumnsExport(bvMicroColumns);
        // dispatch(setExportData({ microTableData: { data: microTableData } }));
        return bvMicroColumns;
      } else return shareColumns.microColsPPTExport;
    },
    [microTableData]
  );

  useEffect(() => {
    if (microTableData.length) {
      const record = microTableData[0];
      const bvMicroColumns = shareColumns.microCols(true, () => { });
      const bvMicroColumnsExport = shareColumns.microColsExport();

      // Export
      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "product_keyword_traffic",
        bvMicroColumns[2].children[0].title,
        columns.renderTextAbsNumber,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "sales",
        bvMicroColumns[3].children[0].title,
        columns.renderTextAbsNumber,
        columns.renderTextVariancePercentageFunction
      );

      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "sales_share",
        bvMicroColumns[3].children[1].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "total_share_of_shelf",
        bvMicroColumns[4].children[0].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "fair_share_of_index",
        bvMicroColumns[4].children[1].title,
        columns.renderTextAbsNumber,
        columns.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "organic_share_of_shelf",
        bvMicroColumns[4].children[2].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "organic_fair_share_of_index",
        bvMicroColumns[4].children[3].title,
        columns.renderTextAbsNumber,
        columns.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "paid_share_of_shelf",
        bvMicroColumns[4].children[4].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "paid_fair_share_of_index",
        bvMicroColumns[4].children[5].title,
        columns.renderTextAbsNumber,
        columns.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        bvMicroColumnsExport,
        record,
        "average_placements",
        bvMicroColumns[5].children[0].title,
        columns.renderTextWholeNumberFunction,
        columns.renderTextVarianceNumberFunction
      );

      setMicroColumnsExport(bvMicroColumnsExport);
      dispatch(setExportData({
        microTableData: {
          data: microTableData, title: selectedRow.length > 0
            ? `${selectedRow[0].table_key} Keyword - Share of Shelf`
            : "Keyword - Share of Shelf"
        }
      }));
    }
  }, [microTableData]);
  const prepareTableExport = (exportData) => {

    const data = JSON.parse(JSON.stringify(exportData));

    data.filters = {
      product_brand: data.filters.product_brand,
      product_secondary_brand: data.filters.product_secondary_brand,
      product_pet: data.filters.product_pet,
      product_technology: data.filters.product_technology,
      product_sub_technology: data.filters.product_sub_technology,
      product_lifestage: data.filters.product_lifestage,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
      product_keyword_category: data.filters.product_keyword_category,
      product_keyword_sub_technology: data.filters.product_keyword_sub_technology,
      product_keyword_type: data.filters.product_keyword_type,
      product_keyword: data.filters.product_keyword,
      deep_dive_list: data.filters.deep_dive_list,

      view_by: data.filters.view_by,
      performance_summary_type: data.filters.performance_summary_type,
      performance_summary_keyword: data.filters.performance_summary_keyword,
      performance_summary_kpi: data.filters.performance_summary_kpi,

      callout: getTrendCallout(),
    };

    return data;
  };

  const getMicroSheetName = () =>
    selectedRow.length > 0 ? `${selectedRow[0].table_key} UPCs` : "UPCs";

  useEffect(() => {
    const processedFilters = processFilters(filters, getState);
    processedFilters.view_by = [viewByShare[processedFilters.view_by[0]]];
    dispatch(setExportData({ filters: processedFilters }));
    dispatch(setExportData({ kpi: { data: cardData } }));
  }, [filters, cardData]);

  const exportTableData = () =>
    exportSosTables(
      macroColumnsExport,
      microColumnsExport,
      prepareTableExport(exportData),
      //getMicroSheetName()
    );

  const exportKPIs = () =>
    exportSOSKPIs(prepareTableExport(exportData), "ShareOfShelf_KPI_Layer");

  const exportTrends = () => exportBATrends(prepareTableExport(exportData));
  const exportScatter = () =>
    exportSOSScatter(
      prepareTableExport(exportData),
      "ShareOfShelf_Keyword_Strategy"
    );


  const changeSelectedBrand = () => setSelectedBrand(defaultBrand);

  const getData = (filters, fetchFilterData = true, clearHistory = true, initialFilterData = true) => {

    dispatch(setAppliedFilters(declareAppliedFilters(filters, { pets, techs, subTechs, lifestage, feedingPhilosophies, textures, keywordCategory, subKeywordCategory, keywordType, keyword })));
    dispatch(setFilters(filters));

    const isBrandChanged = defaultBrand !== selectedBrand;

    let getLatestFilters = getLatestUpdatedFilter(filters)


    // API calls
    if (fetchFilterData)
      if (initialFilterData) dispatch(getAllFilters(isBrandChanged, changeSelectedBrand));
      else dispatch(getFilterData({
        allFilters: true
      }, true, isBrandChanged, changeSelectedBrand, {
        ...getLatestFilters,
        time_aggregate: filters.time_aggregate,
        year_period: filters.year_period,
        benchmark: filters.benchmark,
        keyword_refresh_flag: filters.keyword_refresh_flag
      }));
    else {
      dispatch(getComparisonTrendData(filters));
      dispatch(getCardData(filters));
      dispatch(getScatterChartData(filters));
      dispatch(getCompetitionFilterData(filters));
      dispatch(getTrendData(filters));
    }
  };

  const applyFilters = () => {
    const newFilters = {
      ...updateRetailerDropdown(filters, retailers, dispatch),
      default_brand: [defaultBrand],
      deep_dive_list: [],
    };
    setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
    getData(newFilters, false, false);
  };

  const getRemainingFilters = (label) => {
    if (!filterChanged) return;

    setFilterChanged(false);
    if (label === "product_pet") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,

          // default filters
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
          keyword_refresh_flag: filters.keyword_refresh_flag,
        })
      );
    } else if (label === "product_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
          keyword_refresh_flag: filters.keyword_refresh_flag,
        })
      );
    } else if (label === "product_sub_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
          keyword_refresh_flag: filters.keyword_refresh_flag,
        })
      );
    } else if (label === "product_lifestage") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
          keyword_refresh_flag: filters.keyword_refresh_flag,
        })
      );
    } else if (label === "product_feed_philosophy") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
          keyword_refresh_flag: filters.keyword_refresh_flag,
        })
      );
    } else if (label === "product_texture") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
          keyword_refresh_flag: filters.keyword_refresh_flag,
        })
      );
    } else if (label === "customer_retailer_details") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          keyword_refresh_flag: filters.keyword_refresh_flag,

          // default filters
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "year_period") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,
          keyword_refresh_flag: filters.keyword_refresh_flag,

          // default filters
          time_aggregate: filters.time_aggregate,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "time_aggregate") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,
          time_aggregate: filters.time_aggregate,
          keyword_refresh_flag: filters.keyword_refresh_flag,

          // default filters
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_keyword_category") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,
          time_aggregate: filters.time_aggregate,
          product_keyword_category: filters.product_keyword_category,
          keyword_refresh_flag: filters.keyword_refresh_flag,
          // default filters
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_keyword_sub_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,
          time_aggregate: filters.time_aggregate,
          product_keyword_category: filters.product_keyword_category,
          product_keyword_sub_technology: filters.product_keyword_sub_technology,
          keyword_refresh_flag: filters.keyword_refresh_flag,
          // default filters
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_keyword_type") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,
          time_aggregate: filters.time_aggregate,
          product_keyword_category: filters.product_keyword_category,
          product_keyword_sub_technology: filters.product_keyword_sub_technology,
          product_keyword_type: filters.product_keyword_type,
          keyword_refresh_flag: filters.keyword_refresh_flag,

          // default filters
          benchmark: filters.benchmark,
        })
      );
    }
  };

  const changeFilter = (label, selectedValue) => {
    if (!isEqual(filters[label], selectedValue)) setFilterChanged(true);

    let newFilters = { ...filters };
    if (
      selectedValue &&
      selectedValue.length &&
      selectedValue.includes("all")
    ) {
      let all = [];
      if (label === "product_pet") all = pets;
      else if (label === "product_technology") all = techs;
      else if (label === "product_sub_technology") all = subTechs;
      else if (label === "product_lifestage") all = lifestage;
      else if (label === "product_feed_philosophy") all = feedingPhilosophies;
      else if (label === "product_texture") all = textures;
      else if (label === "product_keyword_category") all = keywordCategory;
      else if (label === "product_keyword_sub_technology") all = subKeywordCategory;
      else if (label === "product_keyword_type") all = keywordType;
      else if (label === "product_keyword") all = keyword;
      if (selectedValue.length === all.length + 1) selectedValue = [];
      else selectedValue = [...all];
    }

    newFilters[label] = selectedValue;
    dispatch(setFilters(newFilters));
    // setLocalFilter(newFilters);
    return newFilters;
  };
  useEffect(() => {
    if (comparisonTrendData.length) setStored(false);
  }, [comparisonTrendData]);

  useEffect(() => {
    const initialFilters = {
      ...getInitialBottomFilters(),
      default_brand: [defaultBrand],
    };

    initialUrlFilter(initialFilters, ecomCommonFilters, filters, defaultBrand, getData);

    setLastDefaultBrand(defaultBrand);
  }, [defaultBrand, props.reportApply]);

  useEffect(() => {
    if (macroTableData.length) {
      setSelectedRowKey([macroTableData[0][rowKeyMacro]]);
      setSelectedRow([macroTableData[0]]);
      // formatTableData(perfTableData);
    } else {
      setSelectedRowKey([]);
      setSelectedRow([]);
    }
  }, [macroTableData]);


  const handlePPTExport = (macroColumns, microColumns) => {
    pptExportSos(exportData, macroColumns, microColumns)
    //exportTableData()
  }
  return (
    <Wrapper
      hideHeader={props.hideReportHeader}
      hidePage={isReport}
      heading={"1"}
      innerHeading={"13"}
      ecomTab={"0"}
    >
      <Content
        className={
          "site-layout-background" +
          (isReport && components.length === 0
            ? " content-none"
            : " content-body")
        }
      >
        <Loader
          tip="Due to high volume of share of shelf data, load might take some time. Please wait while loading."
          loading={isReport ? props.reportLoader : loading > 0}
        >
          {!props.hideReportFilters && (
            <>
              <Row>
                <Navigator refreshDate={shareOfShelf} />
              </Row>
              <Row className="beVisible-card">
                <div>
                  <span>
                    <img src={mdiCheck} alt="Diagnostics" width="22" />
                  </span>
                  <b className="be-info"> Be Visible</b> Search and
                  taxonomy navigation are top shopping choices for eRetailer
                  customers. First page visibility on high traffic search
                  results will boost product sales.
                </div>
              </Row>

              <Row className="be-tabs">
                <Col className="btn-grp">
                  <div>
                    <Radio.Group
                      defaultValue="shareOfShelf"
                      buttonStyle="solid"
                      onChange={(e) => e.target.value === "searchTerm" ? handleTab(e, history) : replaceUrl(e.target.value, history)}
                    >
                      <Radio.Button value="shareOfShelf">
                        Share of Shelf
                      </Radio.Button>
                      <Radio.Button value="searchTerm">
                        Search Insights
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </Col>
                <Col>
                  <Row gutter={20} className="sos-export">
                    {!newReport && (
                      <>
                        <Col className="sos-export-col">
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                <Menu.Item onClick={() => (appInsights.trackEvent({ name: 'PPT_FOR_SHARE_OF_SHELF' }), handlePPTExport(macroColumnsPPTExport(), microColumnsPPTExport()))}>Download as PPT</Menu.Item>
                                <Menu.Item
                                  onClick={() => (
                                    appInsights.trackEvent({ name: 'EXCEL_FOR_SHARE_OF_SHELF' }),
                                    exportShareOfShelf(
                                      prepareTableExport(exportData),
                                      macroColumnsExport,
                                      microColumnsExport,
                                      //getMicroSheetName()
                                    )
                                  )}
                                >
                                  Download as Excel
                                </Menu.Item>
                              </Menu>
                            )}
                            className="d-more"
                          >
                            <Button
                              style={{ marginRight: "9px" }}
                              icon={<DownloadOutlined />}
                              onClick={() => {
                                setExpand(true);
                              }}
                            >
                              Export Report
                            </Button>
                          </Dropdown>
                        </Col>
                        {/* {!isReport && (
                          <Col>
                            <Dropdown
                              placement="bottomRight"
                              overlay={() => (
                                <Menu>
                                  <Menu.Item
                                    onClick={(e) => {
                                      appInsights.trackEvent({
                                        name: "CREATE_SELF_SERVE_REPORT_PERFORMANCE",
                                      });
                                      setNewReport(true);
                                      setCreateReport(true);
                                    }}
                                  >
                                    Create self serve Report
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={(e) => {
                                      appInsights.trackEvent({
                                        name: "ADD_EXISTING_REPORT_PERFORMANCE",
                                      });
                                      setNewReport(true);
                                      setCreateReport(false);
                                    }}
                                  >
                                    Add to Existing Report
                                  </Menu.Item>
                                </Menu>
                              )}
                            >
                              <Button
                                icon={<PlusCircleOutlined />}
                                type="primary"
                              >
                                Create Report
                              </Button>
                            </Dropdown>
                          </Col>
                        )} */}
                      </>
                    )}
                    {/* {newReport && (
                      <>
                        <Col>
                          <Button
                            type="secondary"
                            onClick={(e) => {
                              setNewReport(false);
                              setNewReportComponents([]);
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            onClick={(e) => {
                              if (newReportComponents.length === 0)
                                openNotificationWithIcon(
                                  "error",
                                  "No KPI components selected"
                                );
                              else setOpenSaveReportDialog(true);
                            }}
                          >
                            Save Report
                          </Button>
                        </Col>
                      </>
                    )} */}
                  </Row>
                </Col>

              </Row>

              <Row
                align="middle"
                style={{ marginBottom: 16 }}
                gutter={[16, 16]}
              >
                <Col>
                  <Form
                    name="horizontal_login"
                    className="performance-filter"
                  >
                    <Row
                      gutter={8}
                      className="sos-filters"
                    >
                      <div className="filters-ps">
                        <Col>
                          <Tooltip title="Use filters to view selected Category, Tech, Sub Tech, Feeding Philosophy, and/or Texture by Retailer">
                            <label className="label-block">Pet</label>
                            <Select
                              placeholder="All"
                              mode="multiple"
                              defaultValue={["all"]}
                              dropdownMatchSelectWidth={false}
                              style={{ width: 82 }}
                              loading={dropdownLoading}
                              placement="bottomLeft"
                              maxTagCount="responsive"
                              value={filters.product_pet}
                              autoClearSearchValue={false}
                              onMouseLeave={() =>
                                getRemainingFilters("product_pet")
                              }
                              onChange={(val) => {
                                changeFilter("product_pet", val);
                              }}
                            >
                              <Option value={"all"}>{"All"}</Option>
                              {pets.map((val) => (
                                <Option value={val}>{val}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Technology</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            style={{ width: 113 }}
                            maxTagCount="responsive"
                            value={filters.product_technology}
                            autoClearSearchValue={false}
                            allowClear
                            placement="bottomLeft"
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_technology")
                            }
                            onChange={(val) => {
                              changeFilter("product_technology", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {techs.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Sub Technology</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            style={{ width: 113 }}
                            maxTagCount="responsive"
                            value={filters.product_sub_technology}
                            autoClearSearchValue={false}
                            allowClear
                            placement="bottomLeft"
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_sub_technology")
                            }
                            onChange={(val) => {
                              changeFilter("product_sub_technology", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {subTechs.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Life Stage</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            style={{ width: 113 }}
                            maxTagCount="responsive"
                            value={filters.product_lifestage}
                            autoClearSearchValue={false}
                            allowClear
                            placement="bottomLeft"
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_lifestage")
                            }
                            onChange={(val) => {
                              changeFilter("product_lifestage", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {lifestage.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Feeding Philosophy
                          </label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            allowClear
                            style={{ width: 125 }}
                            loading={dropdownLoading}
                            autoClearSearchValue={false}
                            maxTagCount="responsive"
                            value={filters.product_feed_philosophy}
                            onMouseLeave={() =>
                              getRemainingFilters("product_feed_philosophy")
                            }
                            onChange={(val) => {
                              changeFilter("product_feed_philosophy", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {feedingPhilosophies.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Texture</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 113 }}
                            loading={dropdownLoading}
                            allowClear
                            autoClearSearchValue={false}
                            maxTagCount="responsive"
                            value={filters.product_texture}
                            onMouseLeave={() =>
                              getRemainingFilters("product_texture")
                            }
                            onChange={(val) => {
                              changeFilter("product_texture", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {textures.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <Tooltip title="Top 7 KPIs coming soon">
                            <label className="label-block">Retailer</label>
                            <Select
                              placeholder="All"
                              placement="bottomLeft"
                              dropdownMatchSelectWidth={false}
                              style={{ width: 95 }}
                              maxTagCount="responsive"
                              loading={dropdownLoading}
                              value={filters.customer_retailer_details}
                              onMouseLeave={() =>
                                getRemainingFilters("customer_retailer_details")
                              }
                              onChange={(val) => {
                                changeFilter("customer_retailer_details", [
                                  val,
                                ]);
                                dispatch(
                                  setFilters({
                                    ...filters,
                                    deep_dive_list: [],
                                    customer_retailer_details: [val],
                                    keyword_refresh_flag: [1],
                                  })
                                );
                              }}
                              showSearch
                            >
                              {retailers?.map((retailer) => (
                                <Option value={retailer}>{retailer}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Time Aggregate</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            // defaultValue={["L4W"]}
                            style={{ width: 102 }}
                            loading={dropdownLoading}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            value={filters.time_aggregate}
                            onMouseLeave={() =>
                              getRemainingFilters("time_aggregate")
                            }
                            onChange={(val) => {
                              changeFilter("time_aggregate", [val]);
                              setFilters({
                                ...filters,
                                keyword_refresh_flag: [1],
                              });
                            }}
                            showSearch
                          >
                            {timeAggregates.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Year Period</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            // defaultValue={["2022 p05"]}
                            dropdownStyle={{ minWidth: "130px" }}
                            style={{ width: 90 }}
                            maxTagCount="responsive"
                            value={filters.year_period}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("year_period")
                            }
                            onChange={(val) => {
                              changeFilter("year_period", [val]);
                              setFilters({
                                ...filters,
                                keyword_refresh_flag: [1],
                              });
                            }}
                            showSearch
                          >
                            {yearPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Benchmark Period
                          </label>
                          <Select
                            placeholder="All"
                            // defaultValue={["P3"]}
                            style={{ width: 110 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            loading={dropdownLoading}
                            // dropdownStyle={{ minWidth: "130px" }}
                            value={filters.benchmark}
                            onChange={(val) => {
                              changeFilter("benchmark", [val]);
                            }}
                            showSearch
                          >
                            {benchmarkPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        {/* <div className="border-line" /> */}
                      </div>
                    </Row>
                    <Row
                      gutter={8}
                      className="sos-filters sos-filters2"
                    >
                      <div className="filters-ps">
                        <Col style={{ paddingRight: 0 }}>
                          <Tooltip title="Use Keyword filters to view by selected keywords &#38; mapping">
                            <label className="label-block">
                              Keyword Category
                            </label>
                            <Select
                              placeholder="All"
                              mode="multiple"
                              defaultValue={["all"]}
                              // dropdownMatchSelectWidth={false}
                              dropdownStyle={{ minWidth: "200px" }}
                              style={{ width: 120 }}
                              loading={dropdownLoading}
                              maxTagCount="responsive"
                              value={filters.product_keyword_category}
                              autoClearSearchValue={false}
                              allowClear
                              placement="bottomLeft"
                              onMouseLeave={() =>
                                getRemainingFilters("product_keyword_category")
                              }
                              onChange={(val) => {
                                changeFilter("product_keyword_category", val);
                                setFilters({
                                  ...filters,
                                  keyword_refresh_flag: [1],
                                });
                              }}
                            >
                              <Option value={"all"}>{"All"}</Option>
                              {keywordCategory.map((val) => (
                                <Option value={val}>{val}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col style={{ paddingRight: 0 }}>
                          <Tooltip title="Use Keyword filters to view by selected keywords &#38; mapping">
                            <label className="label-block">
                              Sub Technology Keyword
                            </label>
                            <Select
                              placeholder="All"
                              mode="multiple"
                              defaultValue={["all"]}
                              // dropdownMatchSelectWidth={false}
                              dropdownStyle={{ minWidth: "200px" }}
                              style={{ width: 166 }}
                              loading={dropdownLoading}
                              maxTagCount="responsive"
                              value={filters.product_keyword_sub_technology}
                              autoClearSearchValue={false}
                              allowClear
                              placement="bottomLeft"
                              onMouseLeave={() =>
                                getRemainingFilters("product_keyword_sub_technology")
                              }
                              onChange={(val) => {
                                changeFilter("product_keyword_sub_technology", val);
                                setFilters({
                                  ...filters,
                                  keyword_refresh_flag: [1],
                                });
                              }}
                            >
                              <Option value={"all"}>{"All"}</Option>
                              {subKeywordCategory.map((val) => (
                                <Option value={val}>{val}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Keyword Type</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            style={{ width: 113 }}
                            loading={dropdownLoading}
                            maxTagCount="responsive"
                            value={filters.product_keyword_type}
                            autoClearSearchValue={false}
                            allowClear
                            placement="bottomLeft"
                            onMouseLeave={() =>
                              getRemainingFilters("product_keyword_type")
                            }
                            onChange={(val) => {
                              changeFilter("product_keyword_type", val);
                              setFilters({
                                ...filters,
                                keyword_refresh_flag: [1],
                              });
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {keywordType.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Keyword</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            // dropdownMatchSelectWidth={false}
                            maxTagCount="responsive"
                            value={filters.product_keyword}
                            allowClear
                            style={{ width: 113 }}
                            loading={dropdownLoading}
                            dropdownStyle={{ minWidth: "230px" }}
                            autoClearSearchValue={false}
                            // onMouseLeave={() =>
                            //   getRemainingFilters("product_keyword")
                            // }
                            onChange={(val) => {
                              changeFilter("product_keyword", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {keyword.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            style={{ marginTop: "27%" }}
                            onClick={applyFilters}
                            disabled={dropdownLoading}
                          >
                            Apply
                          </Button>
                        </Col>
                      </div>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </>
          )}
          {!isReport && (
            <Row className="sticky-filter">
              <Col span={24} className="bv-Filters-apply">
                <div className="Report-selected-filters">
                  <label>Report Filters:</label>
                  <div className="filter-item">
                    Pet -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_pet) && appliedFilters.product_pet.length > 0 && !appliedFilters.product_pet.includes("ALL") ? appliedFilters.product_pet.join() : 'CAT,DOG'}
                    </span>
                  </div>
                  <div className="filter-item">
                    Technology -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_technology)
                        ? appliedFilters.product_technology.length === 0 ||
                          appliedFilters.product_technology.length ===
                          techs.length
                          ? "ALL"
                          : appliedFilters.product_technology.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Sub Technology -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_sub_technology)
                        ? appliedFilters.product_sub_technology.length === 0 ||
                          appliedFilters.product_sub_technology.length ===
                          subTechs.length
                          ? "ALL"
                          : appliedFilters.product_sub_technology.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Life Stage -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_lifestage)
                        ? appliedFilters.product_lifestage.length === 0 ||
                          appliedFilters.product_lifestage.length ===
                          lifestage.length
                          ? "ALL"
                          : appliedFilters.product_lifestage.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Feeding Philosophy -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_feed_philosophy)
                        ? appliedFilters.product_feed_philosophy.length === 0 ||
                          appliedFilters.product_feed_philosophy.length ===
                          feedingPhilosophies.length
                          ? "ALL"
                          : appliedFilters.product_feed_philosophy.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Texture -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_texture)
                        ? appliedFilters.product_texture.length === 0 ||
                          appliedFilters.product_texture.length === textures.length
                          ? "ALL"
                          : appliedFilters.product_texture.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Retailer -{" "}
                    <span>
                      {Array.isArray(appliedFilters.customer_retailer_details)
                        ? appliedFilters.customer_retailer_details.length ===
                          0 ||
                          appliedFilters.customer_retailer_details.length ===
                          retailers.length
                          ? "ALL"
                          : appliedFilters.customer_retailer_details.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Time Aggregate -{" "}
                    <span>{appliedFilters.time_aggregate}</span>
                  </div>
                  <div className="filter-item">
                    Year Period - <span>{appliedFilters.year_period}</span>
                  </div>
                  <div className="filter-item">
                    Benchmark Period - <span>{appliedFilters.benchmark}</span>
                  </div>
                  {/* <div className="filter-item">Competitor - <span>Nestle</span></div> */}
                </div>
                <div className="Keyword-selected-filters">
                  <label>Keyword Filters:</label>
                  <div className="filter-item">
                    Keyword Category -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_keyword_category)
                        ? appliedFilters.product_keyword_category.length ===
                          0 ||
                          appliedFilters.product_keyword_category.length ===
                          keywordCategory.length
                          ? "ALL"
                          : appliedFilters.product_keyword_category.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Sub Technology Keyword -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_keyword_sub_technology)
                        ? appliedFilters.product_keyword_sub_technology.length ===
                          0 ||
                          appliedFilters.product_keyword_sub_technology.length ===
                          subKeywordCategory.length
                          ? "ALL"
                          : appliedFilters.product_keyword_sub_technology.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Keyword Type -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_keyword_type)
                        ? appliedFilters.product_keyword_type.length === 0 ||
                          appliedFilters.product_keyword_type.length ===
                          keywordType.length
                          ? "ALL"
                          : appliedFilters.product_keyword_type.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Keyword -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_keyword)
                        ? appliedFilters.product_keyword.length === 0 ||
                          appliedFilters.product_keyword.length ===
                          keyword.length
                          ? "ALL"
                          : appliedFilters.product_keyword.join()
                        : "ALL"}
                    </span>
                  </div>
                  {/* <div className="filter-item">Competitor - <span>Nestle</span></div> */}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <div className="kpi-items" style={{ position: "relative" }}>
                <KPICard
                  title="Market Share"
                  abs={
                    cardData.fact_dollar_impressions &&
                    cardData.fact_dollar_impressions?.abs
                  }
                  variance={
                    cardData.fact_dollar_impressions &&
                    cardData.fact_dollar_impressions?.variance
                  }
                  absSymbol={symbols.percent}
                  varianceSymbol={symbols.pts}
                />
                <KPICard
                  title="Total Share of Shelf"
                  abs={
                    cardData.fact_total_impressions &&
                    cardData.fact_total_impressions?.abs
                  }
                  variance={
                    cardData.fact_total_impressions &&
                    cardData.fact_total_impressions?.variance
                  }
                  absSymbol={symbols.percent}
                  varianceSymbol={symbols.pts}
                />
                <KPICard
                  title="Organic Share of Shelf"
                  abs={
                    cardData.fact_organic_impressions &&
                    cardData.fact_organic_impressions?.abs
                  }
                  variance={
                    cardData.fact_organic_impressions &&
                    cardData.fact_organic_impressions?.variance
                  }
                  absSymbol={symbols.percent}
                  varianceSymbol={symbols.pts}
                />
                <KPICard
                  title={"Paid Share of Shelf"}
                  abs={
                    cardData.fact_paid_impressions &&
                    cardData.fact_paid_impressions?.abs
                  }
                  variance={
                    cardData.fact_paid_impressions &&
                    cardData.fact_paid_impressions?.variance
                  }
                  absSymbol={symbols.percent}
                  varianceSymbol={symbols.pts}
                  chewyCallout={
                    appliedFilters.customer_retailer_details[0] === "Chewy" &&
                    "Chewy started tracking Paid Impressions in P52023 & Walmart coming soon"
                  }
                />
                <KPICard
                  title="Average Placement"
                  abs={
                    cardData.fact_average_placements &&
                    cardData.fact_average_placements?.abs
                  }
                  variance={
                    cardData.fact_average_placements &&
                    cardData.fact_average_placements?.variance
                  }
                  exportKPIs={exportKPIs}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="nutro-skus">
                <div className="section-title">
                  <h4>Share of Shelf</h4>
                  <h4 className="section-title-callout">{getTrendCallout()}</h4>
                  <Dropdown
                    overlay={() => menu(exportTrends)}
                    trigger={["click"]}
                    placement="bottomRight"
                    className="d-more"
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <MoreOutlined />
                    </a>
                  </Dropdown>
                </div>
                <div className="nutro-skus-filter">
                  <div className="nutro-skus-filter-category">
                    <div>
                      <span className="view-label">View By</span>
                      <Radio.Group
                        // defaultValue={}
                        value={filters.view_by[0]}
                        size="small"
                        onChange={(e) => {
                          const newFilters = {
                            ...filters,
                            view_by: [e.target.value],
                            deep_dive_list: [],
                          };
                          if (e.target.value === "R") {
                            newFilters.selected_retailer = [];
                            dispatch(setSelectedRetailer([]));
                          }

                          dispatch(setFilters(newFilters));
                          dispatch(getComparisonTrendData(newFilters));
                          // dispatch(getDeepdiveTrendData(newFilters));
                          // dispatch(getMacroTableData(newFilters));
                          // dispatch(getMicroTableData(newFilters));
                        }}
                      >
                        {Object.keys(viewByShare).map((viewType) => (
                          <Radio.Button value={viewType}>
                            {viewByShare[viewType]}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>
                    <div className="sos-viewBy">
                      {filters.view_by[0] === "C" && (
                        <div className="sos-compititor">
                          <span className="view-label">Competitor</span>
                          <TreeSelect
                            placeholder="Please select"
                            treeCheckable
                            showCheckedStrategy={SHOW_PARENT}
                            style={{ width: 250 }}
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                            treeData={competitors}
                            dropdownMatchSelectWidth={false}
                            autoClearSearchValue={false}
                            maxTagCount="responsive"
                            allowClear
                            value={filters.selected_competitor}
                            onChange={(val) =>
                              dispatch(
                                setFilters({
                                  ...filters,
                                  selected_competitor: val,
                                  deep_dive_list: [],
                                })
                              )
                            }
                            onMouseLeave={() => {
                              const competitorsOnMouseLeave = [
                                ...selectedCompetitor,
                              ];
                              const competitorsOnChange = [
                                ...filters.selected_competitor,
                              ];
                              if (
                                !isEqual(
                                  competitorsOnMouseLeave.sort(),
                                  competitorsOnChange.sort()
                                )
                              ) {
                                dispatch(
                                  setSelectedCompetitor(
                                    filters.selected_competitor
                                  )
                                );
                                dispatch(setFilters(filters));
                                dispatch(getComparisonTrendData(filters));
                                // dispatch(getDeepdiveTrendData(filters));
                                // dispatch(getMacroTableData(filters));
                                // dispatch(getMicroTableData(filters));
                              }
                            }}
                          />
                        </div>
                      )}
                      <div className="sos-compititor">
                        <span className="view-label">Share of Shelf Type</span>
                        <Select
                          placeholder="All"
                          dropdownMatchSelectWidth={false}
                          style={{ width: 110 }}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          value={filters.performance_summary_type[0]}
                          onChange={(value) => {
                            const newFilters = {
                              ...filters,
                              performance_summary_type: [value],
                              deep_dive_list: filters.deep_dive_list,
                            };
                            dispatch(setFilters(newFilters));
                            dispatch(getComparisonTrendData(newFilters));
                            // dispatch(getDeepdiveTrendData(newFilters));
                            // dispatch(getMacroTableData(newFilters));
                            // dispatch(getMicroTableData(newFilters));
                          }}
                        >
                          {trafficSourceData.map((kpi) => (
                            <Option value={kpi}>{kpi}</Option>
                          ))}
                        </Select>
                      </div>
                      <div className="sos-compititor">
                        <span className="view-label">Keyword Type</span>
                        <Select
                          placeholder="All"
                          defaultValue={["all"]}
                          dropdownMatchSelectWidth={false}
                          style={{ width: 113 }}
                          maxTagCount="responsive"
                          placement="bottomLeft"
                          value={filters.performance_summary_keyword[0]}
                          onChange={(value) => {
                            const newFilters = {
                              ...filters,
                              performance_summary_keyword: [value],
                              deep_dive_list: filters.deep_dive_list,
                            };
                            dispatch(setFilters(newFilters));
                            dispatch(getComparisonTrendData(newFilters, false));
                          }}
                        >
                          <Option value={"all"}>{"All"}</Option>
                          {keywordType.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </div>
                      <div>
                        <span className="view-label">KPI</span>
                        <Select
                          placeholder="All"
                          dropdownMatchSelectWidth={false}
                          style={{ width: 110 }}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          value={filters.performance_summary_kpi[0]}
                          onChange={(value) => {
                            const newFilters = {
                              ...filters,
                              performance_summary_kpi: [value],
                              deep_dive_list: filters.deep_dive_list,
                            };
                            dispatch(setFilters(newFilters));
                            dispatch(getComparisonTrendData(newFilters, false));
                          }}
                        >
                          {(filters.deep_dive_list.length > 1 ? shareOfShelfKPIMulti : shareOfShelfKPI).map((kpi) => (
                            <Option value={kpi}>{kpi}</Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sub-card-container">
                  <div className="nutro-skus sub-card-item">
                    <div className="section-title">
                      <h4>
                        {filters.performance_summary_type[0]} Share of Shelf -{" "}
                        {viewByShare[filters.view_by[0]]} Comparison
                      </h4>
                      <p>
                        Select a retailer bar for details analysis (select
                        multiple using Ctrl key)
                      </p>
                    </div>
                    <ColumnBulletChart
                      kpi={filters.performance_summary_kpi[0]}
                      data={comparisonTrendData}
                      xField={viewByShare[filters.view_by[0]]}
                      yField={[
                        filters.performance_summary_type[0],
                        filters.performance_summary_kpi[0],
                      ]}
                      yFieldNumFmt={[
                        numFmt.percentage,
                        filters.performance_summary_kpi[0] === itemsListed
                          ? numFmt.wholeNumber
                          : numFmt.percentage,
                      ]}
                      viewBy={viewByShare[filters.view_by[0]]}
                      value={filters.deep_dive_list}
                      onChange={(value) => {
                        const newFilters = {
                          ...filters,
                          deep_dive_list: value,
                        };

                        dispatch(setFilters(newFilters));
                        dispatch(getDeepdiveTrendData(newFilters));
                        dispatch(getMacroTableData(newFilters));
                        // dispatch(getMicroTableData(newFilters));
                      }}
                      // yBarUnit="%"
                      stored={stored}
                      setStored={setStored}
                      storageKey="ComparisonTrendChart"
                    />
                    {/* <DemoDualAxes /> */}
                  </div>
                  <div className="nutro-skus sub-card-item">
                    <div className="section-title">
                      <h4>
                        {filters.deep_dive_list.length > 1
                          ? filters.performance_summary_kpi[0] + " - Deep Dive"
                          : `${filters.performance_summary_type[0]} Share of Shelf - ${filters.deep_dive_list.length === 1
                            ? filters.deep_dive_list[0]
                            : "Deep Dive"
                          }`}
                      </h4>
                      <p>
                        Select more retailers in the left graph to compare them
                        here
                      </p>
                    </div>
                    {filters.deep_dive_list.length > 1 ? (
                      <MultiLineChart
                        kpi={filters.performance_summary_kpi[0]}
                        data={deepdiveMultiTrendData}
                        xField="Periods"
                        yField={filters.performance_summary_kpi[0]}
                        yFieldNumFmt={
                          filters.performance_summary_kpi[0] === itemsListed
                            ? numFmt.wholeNumber
                            : numFmt.percentage
                        }
                        seriesField="Trend"
                      />
                    ) : (
                      <ColumnLineChart
                        kpi={filters.performance_summary_kpi[0]}
                        data={deepdiveSingleTrendData}
                        xField="Periods"
                        yField={[
                          filters.performance_summary_type[0],
                          filters.performance_summary_kpi[0],
                        ]}
                        yFieldNumFmt={[
                          numFmt.percentage,
                          filters.performance_summary_kpi[0] === itemsListed
                            ? numFmt.wholeNumber
                            : numFmt.percentage,
                        ]}
                      />
                    )}
                  </div>
                </div>
                <div className="next-skus">
                  <CustomTable
                    loading={macroLoading}
                    // hideDropdown={false}
                    rowKey={rowKeyMacro}
                    heading="Category - Share of Shelf"
                    description="Category level information"
                    childrenColumnName="table_hierarchy_data"
                    hideFilter={true}
                    IscompareVia={true}
                    scroll={true}
                    xScroll="max-content"
                    data={macroTableData}
                    columns={
                      openMacro
                        ? macroColumns(openMacro, setOpenMacro)
                        : macroColumns(openMacro, setOpenMacro)?.map((i) => {
                          return {
                            ...i,
                            children: i.children.filter((ci) => !ci.extra),
                          };
                        })
                    }
                    onExpand={(expanded, record) => {
                      if (expanded)
                        dispatch(
                          getMacroTableData(
                            {
                              ...filters,
                              filter_key: record.filter_key,
                            },
                            record.index
                          )
                        );
                    }}
                    compareDropdown={
                      filters.view_by[0] === "R"
                        ? false
                        : {
                          options: retailerDropdown,
                          value: filters.selected_retailer,
                          onChange: (val) =>
                            dispatch(
                              setFilters({
                                ...filters,
                                selected_retailer: val,
                              })
                            ),
                          onMouseLeave: () => {
                            const selectedRetailerOnMouseLeave = [
                              ...selectedRetailer,
                            ];
                            const selectedRetailerOnChange = [
                              ...filters.selected_retailer,
                            ];

                            if (
                              !isEqual(
                                selectedRetailerOnMouseLeave.sort(),
                                selectedRetailerOnChange.sort()
                              )
                            ) {
                              dispatch(
                                setSelectedRetailer(filters.selected_retailer)
                              );
                              dispatch(getMacroTableData(filters));
                              // dispatch(getMicroTableData(filters));
                            }
                          },
                        }
                    }
                    rowSelection={{
                      checkStrictly: true,
                      selectedRowKeys: selectedRowKey,
                      type: "radio",
                      onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKey(selectedRowKeys);
                        setSelectedRow(selectedRows);
                        dispatch(
                          getMicroTableData({
                            ...filters,
                            filter_key: selectedRows[0].filter_key,
                          })
                        );
                      },
                      hideSelectAll: true,
                    }}
                    exportData={exportTableData}
                  />
                </div>
                {/* <div className="next-skus">
                  <CustomTable
                    loading={microLoading}
                    hideDropdown={true}
                    rowKey={rowKeyMicro}
                    scroll={true}
                    xScroll="max-content"
                    heading={
                      selectedRow.length > 0
                        ? `${selectedRow[0].table_key} Keyword - Share of Shelf`
                        : "Keyword - Share of Shelf"
                    }
                    description="Keyword level information"
                    hideFilter={true}
                    data={microTableData}
                    columns={
                      openMicro
                        ? microColumns(openMicro, setOpenMicro)
                        : microColumns(openMicro, setOpenMicro).map((i) => {
                            return {
                              ...i,
                              children: i.children.filter((ci) => !ci.extra),
                            };
                          })
                    }
                  />
                </div> */}
                <Row className="traffic-chart">
                  <p>
                    <a>Keyword - Share of Shelf coming soon</a>
                  </p>
                </Row>
                <Row className="diagnostics-chart">
                  <div className="kpiChart">
                    <div>
                      <h4>Keyword Strategy</h4>
                      <h4 className="section-title-callout">
                        Bubble size is driven by Share of Shelf
                      </h4>
                      <p>Select area to zoom on bubbles</p>
                      <Dropdown
                        overlay={() => menu(exportScatter)}
                        trigger={["click"]}
                        placement="bottomRight"
                        className="d-more"
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <MoreOutlined />
                        </a>
                      </Dropdown>
                    </div>
                    <div className="kpi-dropdown">
                      <Row>
                        {/* <Col style={{width: '200px'}}>
                          <Slider value={slider} onChange={(value) => { setSlider(value) }} />
                        </Col> */}
                        <Col>
                          <label className="bubble-label">
                            Show Labels
                          </label>
                          <Switch onChange={handleLabel} checked={showLabel} />
                        </Col>
                      </Row>
                    </div>
                    <Row style={{ width: "100%" }}>
                      <Col
                        className="scatterChart"
                        style={{ width: "100%", marginTop: 20 }}
                      >
                        {/* <Scatter {...Keywordconfig} /> */}
                        <BubbleChart
                          data={strategyData}
                          xField="share_of_shelf_change"
                          yField="traffic"
                          xFieldName="Share of Shelf Change %"
                          yFieldName="Traffic %"
                          showLabel={showLabel}
                          setChartClick={setChartClick}
                          chartClick={chartClick}
                          // value={filters.keyword}
                          onChange={(value) => {
                            // if (value.keyword.length > 0) {
                            //   const newFilters = {
                            //     ...filters,
                            //     keyword: value.keyword,
                            //   };
                            //   dispatch(setFilters(newFilters));
                            //   dispatch(getTrendData(newFilters));
                            // }
                            // if (!value.data.length && !value.keyword.length) {
                            //   dispatch(setStrategyData(strategyDataDuplicate));
                            // }
                            // if (value.data.length && !value.keyword.length) {
                            //   dispatch(setStrategyData(value.data));
                            // }
                            if (!value.data.length) {
                              dispatch(setStrategyData(strategyDataDuplicate));
                            }
                            if (value.data.length) {
                              dispatch(setStrategyData(value.data));
                            }
                          }}
                          storageKey="BubbleChart"
                          bubblechartLabel={bubblechartLabel}
                          isBubbleClickable={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
          {!isReport && (
            <Row className="traffic-chart">
              <p>
                <Popover
                  placement="bottom"
                  content={<img src={trafficChart} style={{ width: "100%", background: "orange" }} />}
                  trigger="hover"
                >
                  <a>Keyword Traffic Share vs Share of Shelf coming soon</a>
                </Popover>
              </p>
              <Button
                onClick={() =>
                  openNotificationWithIcon(
                    "success",
                    "Coming soon! We will notify you"
                  )
                }
              >
                Click here to register interest section
              </Button>
            </Row>
          )}
          {/* <Row className="diagnostics-chart" style={{ marginTop: 15 }}>
            <div className="kpiChart">
              <div>
                <h4>
                  {filters.keyword.length ? filters.keyword : "Keyword"} Traffic
                  Share vs Share of Shelf
                </h4>
              </div>
              <Row style={{ width: "100%" }}>
                <Col style={{ width: "100%", marginTop: 20 }}>
                  <ColumnLineChart
                    data={trendData}
                    xField="Periods"
                    yField={["Traffic Share", "Share of Shelf"]}
                  />
                </Col>
              </Row>
            </div>
          </Row> */}

          {/* <Row className="compitition-table">
            <CompetitionAndBenchmark
              isAllBrands={isAllBrands}
              brandData={brands}
              subBrandData={subBrands}
              retailerData={customerRetailers}
              categoryData={categoryBenchmarks}
              competitionData={competitionBenchmarks}
              kpiData={competitionKpiData}
              columns={competitionColumns}
              tableData={competitionTableData}
              rowKey={rowKeyCompetition}
              childrenColumnName="table_hierarchy_data"
              defaultExpandedRowKeys={
                competitionTableData.length
                  ? competitionTableData.map((item) => item[rowKeyCompetition])
                  : []
              }
              // Compare via
              compareViaValue={filters.compare_via[0]}
              compareViaOnChange={(val) => {
                setSelectedDeepdive([]);
                const newFilters = {
                  ...filters,
                  compare_via: [val],
                  compare_deep_dive_list: [],
                };
                dispatch(setFilters(newFilters));
                dispatch(getCompetitionFilterData(newFilters));
                dispatch(getInternalBenchmarkData(newFilters));
                dispatch(getCategoryBenchmarkData(newFilters));
                dispatch(getCompetitionBenchmarkData(newFilters));
              }}
              // Compare Deepdive
              deepdiveValue={filters.compare_deep_dive_list}
              deepdiveOnChange={(val) =>
                dispatch(
                  setFilters({ ...filters, compare_deep_dive_list: val })
                )
              }
              deepdiveOnMouseLeave={() => {
                const selectedDeepdiveOnMouseLeave = [...selectedDeepdive];
                const selectedDeepdiveOnChange = [
                  ...filters.compare_deep_dive_list,
                ];
                if (
                  !isEqual(
                    selectedDeepdiveOnMouseLeave.sort(),
                    selectedDeepdiveOnChange.sort()
                  )
                ) {
                  setSelectedDeepdive(filters.compare_deep_dive_list);
                  dispatch(getInternalBenchmarkData(filters));
                  dispatch(getCategoryBenchmarkData(filters));
                  dispatch(getCompetitionBenchmarkData(filters));
                }
              }}
              // Category
              categoryValue={filters.category_benchmark}
              categoryOnChange={(val) =>
                dispatch(setFilters({ ...filters, category_benchmark: val }))
              }
              categoryOnMouseLeave={() => {
                const selectedCategoryOnMouseLeave = [...selectedCategory];
                const selectedCategoryOnChange = [
                  ...filters.category_benchmark,
                ];
                if (
                  !isEqual(
                    selectedCategoryOnMouseLeave.sort(),
                    selectedCategoryOnChange.sort()
                  )
                ) {
                  setSelectedCategory(filters.category_benchmark);
                  dispatch(getCategoryBenchmarkData(filters));
                }
              }}
              // Competition
              competitionValue={filters.competition_benchmark}
              competitionOnChange={(val) =>
                dispatch(setFilters({ ...filters, competition_benchmark: val }))
              }
              competitionOnMouseLeave={() => {
                const selectedCompetitionOnMouseLeave = [
                  ...selectedCompetition,
                ];
                const selectedCompetitionOnChange = [
                  ...filters.competition_benchmark,
                ];
                if (
                  !isEqual(
                    selectedCompetitionOnMouseLeave.sort(),
                    selectedCompetitionOnChange.sort()
                  )
                ) {
                  setSelectedCompetition(filters.competition_benchmark);
                  dispatch(getCompetitionBenchmarkData(filters));
                }
              }}
              // Competition KPI
              kpiValue={filters.competition_kpi}
              kpiOnChange={(val) =>
                dispatch(setFilters({ ...filters, competition_kpi: val }))
              }
            />
          </Row> */}
        </Loader>
      </Content>
    </Wrapper >
  );
}

export default withRouter(EcomBeVisible);
