import { createSlice } from "@reduxjs/toolkit";
import CustomAxios, { CustomAxiosDE, CustomAxiosTimeout } from "../utilities/services/api.service";
import { ENDPOINTS } from "../utilities/constants";
import { openNotification, openNotificationWithIcon } from "../utilities/helper";

/**
 * DataSource Slicer
 * It manages the Redux State/Reducer/Actions for DataSource usecase.
 */

// Define the initial state of the DataSource slice in the Redux store
const initialState = {
    loading: 0,
    dropdownLoading: 0,
    tableLoading: 0,
    task_id: "",
    period_task_id: "",
    task_completion: true,
    period_task_completion: true,
    data_filters: {
    },
    ecom_data_filters: {
    },
    data_aggregation: {},
    ecom_data_aggregation: {},
    filters: {
        product_pet: [],
        product_technology: [],
        product_sub_technology: [],
        product_brand: [],
        product_manufacturer: [],
        product_sub_brand: [],
        product_pricing_tier: [],
        product_pricing_tier_partition: [],
        product_texture: [],
        product_package: [],
        product_package_size: [],
        customer_channel: "",
        customer_retailer_details: [],
        period_year: [
            '2023:P08'
        ],
    },
    ecomFilters: {
        product_pet: [],
        product_technology: [],
        product_feed_philosophy: [],
        product_sub_technology: [],
        product_brand: [],
        product_manufacturer: [],
        product_sub_brand: [],
        product_texture: [],
        product_package_size: [],
        customer_channel: "",
        customer_retailer_details: [],
        period_year: [
        ],
    },
    loadFilterTable: [],
    groupBy: ['Pet', 'Technology', 'Sub Technology', 'Manufacturer', 'Brand', 'Channel', 'Retailer Details'],
    groupByEcom: ['Pet', 'Technology', 'Manufacturer', 'Brand', 'Retailer Details'],

    keyword_category: [],
    keyword_type: [],
    keywords: [],
    clusterNotification: false,
    dataPreview: [],
    dataPreviewColumns: [],
    ecomDataPreview: [],
    ecomDataPreviewColumns: [],
    timePeriodCheckbox: ["4W"],
    ecomTimePeriodCheckbox: ["4W"],
    row_limit: 1000000,
    table_name: "DYNAMIC NIELSEN",
    filterCount: [0],
    kpis: ["MKT RSV"],
    ecomKpis: ["MARKET RSV"],
    periods: {},
    aggregates: {},
    ecomPeriods: {},
    ecomAggregates: {},
};

// Create the dataSourcesSlice using the createSlice function from Redux Toolkit
const dataSourcesSlicer = createSlice({
    name: "dataSources",
    initialState,
    reducers: {
        setLoading: (state, action) => ({
            ...state,
            loading: state.loading + (action.payload ? 1 : -1)
        }),
        setDropdownLoading: (state, action) => ({
            ...state,
            dropdownLoading: state.dropdownLoading + (action.payload ? 1 : -1)
        }),
        setTableLoading: (state, action) => ({
            ...state,
            tableLoading: state.tableLoading + (action.payload ? 1 : -1)
        }),
        setClusterNotification: (state, action) => ({
            ...state,
            clusterNotification: action.payload,
        }),
        setTaskID: (state, action) => ({
            ...state,
            task_id: action.payload,
        }),
        setPeriodTaskID: (state, action) => ({
            ...state,
            period_task_id: action.payload,
        }),
        setFilterCount: (state, action) => ({
            ...state,
            filterCount: action.payload,
        }),
        setKpis: (state, action) => ({
            ...state,
            kpis: action.payload,
        }),
        setEcomKpis: (state, action) => ({
            ...state,
            ecomKpis: action.payload,
        }),
        setPeriods: (state, action) => ({
            ...state,
            periods: action.payload,
        }),
        setAggregates: (state, action) => ({
            ...state,
            aggregates: action.payload,
        }),
        setTaskCompletion: (state, action) => ({
            ...state,
            task_completion: action.payload,
        }),
        setPeriodTaskCompletion: (state, action) => ({
            ...state,
            period_task_completion: action.payload,
        }),
        setDataFilters: (state, action) => ({
            ...state,
            data_filters: action.payload,
        }),
        setEcomDataFilters: (state, action) => ({
            ...state,
            ecom_data_filters: action.payload,
        }),
        setDataDropdown: (state, action) => ({
            ...state,
            [action.payload[0]]: action.payload[1]
        }),
        setEcomDataDropdown: (state, action) => ({
            ...state,
            ['ecom_' + action.payload[0]]: action.payload[1]
        }),
        setDataAggregations: (state, action) => ({
            ...state,
            data_aggregation: action.payload,
        }),
        setEcomDataAggregations: (state, action) => ({
            ...state,
            ecom_data_aggregation: action.payload,
        }),
        setFilters: (state, action) => ({
            ...state,
            filters: action.payload,
        }),
        setEcomFilters: (state, action) => ({
            ...state,
            ecomFilters: action.payload,
        }),
        setLoadFilterTable: (state, action) => ({
            ...state,
            loadFilterTable: action.payload,
        }),
        setGroupBy: (state, action) => ({
            ...state,
            groupBy: action.payload,
        }),
        setGroupByEcom: (state, action) => ({
            ...state,
            groupByEcom: action.payload,
        }),
        setDataPreview: (state, action) => ({
            ...state,
            dataPreview: action.payload,
        }),
        setDataPreviewColumns: (state, action) => ({
            ...state,
            dataPreviewColumns: action.payload,
        }),
        setEcomDataPreview: (state, action) => ({
            ...state,
            ecomDataPreview: action.payload,
        }),
        setEcomDataPreviewColumns: (state, action) => ({
            ...state,
            ecomDataPreviewColumns: action.payload,
        }),
        setTimePeriodCheckbox: (state, action) => ({
            ...state,
            timePeriodCheckbox: action.payload,
        }),
        setEcomTimePeriodCheckbox: (state, action) => ({
            ...state,
            ecomTimePeriodCheckbox: action.payload,
        }),
        setRowLimit: (state, action) => ({
            ...state,
            row_limit: action.payload,
        }),
        setTableName: (state, action) => ({
            ...state,
            table_name: action.payload,
        }),
    }
})

// Export the actions generated by createSlice for use in other parts of the application
export const { setLoading,
    setDropdownLoading,
    setTableLoading,
    setPets,
    setTaskID,
    setPeriodTaskID,
    setTaskCompletion,
    setPeriodTaskCompletion,
    setClusterNotification,
    setDataFilters,
    setDataAggregations,
    setEcomDataAggregations,
    setDataDropdown,
    setEcomDataDropdown,
    setFilters,
    setEcomDataFilters,
    setLoadFilterTable,
    setEcomFilters,
    setGroupBy,
    setGroupByEcom,
    setDataPreview,
    setDataPreviewColumns,
    setEcomDataPreview,
    setEcomDataPreviewColumns,
    setTimePeriodCheckbox,
    setEcomTimePeriodCheckbox,
    setRowLimit,
    setTableName,
    setFilterCount,
    setKpis,
    setEcomKpis,
    setPeriods,
    setAggregates } = dataSourcesSlicer.actions;

export const initiateCluster = () => async (dispatch, getState) => {
    CustomAxios(ENDPOINTS.initiateCluster, 'GET').then((response) => {

    }).catch((err) => { }
    )
}

// Export the asynchronous actions that can be used to perform API calls.
export const initiateTask = (filters, groupBy, kpis, periods, aggregates, timePeriodCheckbox) => async (dispatch, getState) => {
    let state = getState().dataSources
    let profile = getState().profile
    let kpiArray = [...kpis]
    let kpi_combination = {}
    let customerSplit = Object.keys(filters).length !== 0 && filters.customer_retailer_details && filters.customer_retailer_details.length !== 0 ? Array.from(new Set(filters.customer_retailer_details.map(val => val.split(":")[0]))) : []
    let retailerSplit = Object.keys(filters).length !== 0 && filters.customer_retailer_details && filters.customer_retailer_details.length !== 0 ? Array.from(new Set(filters.customer_retailer_details.map(val => val.split(":")[1]))) : []

    let urlFilters = { ...filters, customer_channel: customerSplit, customer_retailer_details: retailerSplit }


    dispatch(setLoading(true))
    let newFilters = { ...urlFilters }

    Object.keys(newFilters).forEach(key => {
        if (newFilters[key].length === 0) {
            delete newFilters[key];
        }
    })

    // Set Time Period Format
    let periodYear = state.data_filters.period_year
    let periodYearArray = {}
    Object.keys(periodYear).map(val => periodYearArray[val] = [])
    filters.period_year.map(period => {
        if (Object.keys(periodYear).includes(period)) {
            periodYearArray = {
                ...periodYearArray,
                [period]: periodYear[period]
            }
        }
        else {
            let category = periodYearArray[period.split(":")[0]]
            category.push(period.split(":")[1])
            periodYearArray = {
                ...periodYearArray,
                [period.split(":")[0]]: category
            }
        }
    }
    )
    Object.keys(periodYearArray).forEach(key => {
        if (periodYearArray[key].length === 0) {
            delete periodYearArray[key];
        }
    })

    if (filters.period_year.length > 0) {
        newFilters = {
            ...newFilters,
            period_year: periodYearArray
        }
    }

    // kpiArray.map(val => {
    //     kpi_combination = {
    //         ...kpi_combination,
    //         [kpis[val]]: {
    //             fact_period: periods[val],
    //             // fact_aggregation: aggregates[val]
    //         }
    //     }
    // })

    let encodedURL = encodeURIComponent(JSON.stringify({
        column_filters: newFilters,
        group_by_columns: groupBy,
        kpi_combination: kpiArray,
        row_limit: state.row_limit,
        user_email: profile.user.user_email,
        time_period: timePeriodCheckbox,
    }))
    CustomAxios(ENDPOINTS.start_file + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.task_id) {
            dispatch(setTaskID(response.data.task_id))
            dispatch(setTaskCompletion(false))
        }
        else {
            openNotificationWithIcon('error', 'Download not initiated')
        }
        dispatch(setLoading(false))

    }).catch((err) => {
        openNotificationWithIcon('error', 'Error initiating download')
        dispatch(setLoading(false))
    })
}

export const initiatePeriodTask = () => async (dispatch, getState) => {
    let profile = getState().profile

    let encodedURL = encodeURIComponent(JSON.stringify({
        row_limit: 10,
        user_email: profile.user.user_email,

    }))
    CustomAxios(ENDPOINTS.start_period_file + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.task_id) {
            dispatch(setPeriodTaskID(response.data.task_id))
            dispatch(setPeriodTaskCompletion(false))
        }
        else {
            openNotificationWithIcon('error', 'Download not initiated')
        }
        dispatch(setLoading(false))

    }).catch((err) => {
        openNotificationWithIcon('error', 'Error initiating download')
        dispatch(setLoading(false))
    })
}

export const initiateEcomTask = (filters, groupByEcom, kpis, timePeriodCheckbox) => async (dispatch, getState) => {
    let state = getState().dataSources
    let profile = getState().profile
    let kpiArray = [...kpis]

    dispatch(setLoading(true))
    let newEcomFilters = { ...filters }

    // Remove Empty Key Value Pairs
    Object.keys(newEcomFilters).forEach(key => {
        if (newEcomFilters[key].length === 0) {
            delete newEcomFilters[key];
        }
    })

    // Set Time Period Format
    let periodYear = state.ecom_data_filters.period_year
    let periodYearArray = {}
    periodYear && Object.keys(periodYear).map(year => {
        periodYearArray[year] = {}
        Object.keys(periodYear[year]).map(period => periodYearArray[year][period] = [])
    })
    filters.period_year.map(year => {
        let categoryYear = year.split(":")[0]
        let categoryPeriod = year.split(":")[1]
        let categoryWeek = year.split(":")[2]
        periodYearArray[categoryYear][categoryPeriod].push(categoryWeek)
    }
    )



    Object.keys(periodYearArray).forEach(year => {
        Object.keys(periodYearArray[year]).forEach(period => {
            if (periodYearArray[year][period].length === 0) {
                delete periodYearArray[year][period];
            }
        })

        if (Object.keys(periodYearArray[year]).length === 0) {
            delete periodYearArray[year];
        }
    })

    if (filters.period_year.length > 0) {
        newEcomFilters = {
            ...newEcomFilters,
            period_year: periodYearArray
        }
    }

    // kpiArray.map(val => {
    //     kpi_combination = {
    //         ...kpi_combination,
    //         [kpis[val]]: {
    //             fact_period: periods[val],
    //             // fact_aggregation: aggregates[val]
    //         }
    //     }
    // })

    let encodedURL = encodeURIComponent(JSON.stringify({
        column_filters: newEcomFilters,
        group_by_columns: groupByEcom,
        kpi_combination: kpiArray,
        row_limit: state.row_limit,
        user_email: profile.user.user_email,
        time_period: timePeriodCheckbox,
    }))
    CustomAxios(ENDPOINTS.ecom_start_file + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.task_id) {
            dispatch(setTaskID(response.data.task_id))
            dispatch(setTaskCompletion(false))
        }
        else {
            openNotificationWithIcon('error', 'Download not initiated')
        }
        dispatch(setLoading(false))

    }).catch((err) => {
        openNotificationWithIcon('error', 'Error initiating download')
        dispatch(setLoading(false))
    })
}

// Function to set default filters and applied filters in the state
const setFiltersDefault = (dispatch, data_filters, getState) => {
    // Extract the required data from the state and response
    const state = getState().dataSources;
    const filters = state.filters;
    const ecomFilters = state.ecomFilters;

    const newFilters = { ...filters };
    const newEcomFilters = { ...ecomFilters };

    const filterNames = [
        "product_pet",
        "product_technology",
        "product_sub_technology",
        "product_manufacturer",
        "product_brand",
        "product_sub_brand",
        "product_pricing_tier",
        "product_pricing_tier_partition",
        "product_texture",
        "product_package",
        "product_package_size",
    ];


    Object.keys(filters).forEach((filterName) => {
        if (data_filters[filterName] && data_filters[filterName].length && filterNames.includes(filterName)) {
            newFilters[filterName] = newFilters[filterName].filter((item) =>
                (data_filters[filterName] || state[filterName]).includes(item)
            );
        }
    });

    dispatch(setFilters(newFilters));
};

const setEcomFiltersDefault = (dispatch, data_filters, getState) => {
    // Extract the required data from the state and response
    const state = getState().dataSources;
    const ecomFilters = state.ecomFilters;

    const newEcomFilters = { ...ecomFilters };

    const ecomFilterNames = [
        "product_pet",
        "product_technology",
        "product_sub_technology",
        "product_manufacturer",
        "product_brand",
        "product_sub_brand",
        "product_pricing_tier",
        "product_pricing_tier_partition",
        "product_texture",
        "product_package",
        "product_package_size",
    ];

    Object.keys(ecomFilters).forEach((filterName) => {
        if (data_filters[filterName] && data_filters[filterName].length && ecomFilterNames.includes(filterName)) {
            newEcomFilters[filterName] = newEcomFilters[filterName].filter((item) =>
                (data_filters[filterName] || state[filterName]).includes(item)
            );
        }
    });

    dispatch(setEcomFilters(newEcomFilters));
};

export const getDropdownFilters = (filters, filterName) => async (dispatch, getState) => {
    let urlChannel = filters && Object.keys(filters).length !== 0 && filters.customer_channel && filters.customer_channel.length !== 0 ? [filters.customer_channel] : []
    let urlFilters = { ...filters, customer_channel: urlChannel }
    let isSelectedFilters = false;
    Object.keys(urlFilters).forEach(key => {
        if (urlFilters[key].length === 0) {
            delete urlFilters[key];
        } else if (isSelectedFilters) {
            delete urlFilters[key];
        }

        if (key === filterName) isSelectedFilters = true;
    })
    let encodedURL = encodeURIComponent(JSON.stringify(urlFilters))
    dispatch(setDropdownLoading(true));
    CustomAxios(ENDPOINTS.dataSourcesDropdown + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.error_code === undefined) {
            let data_filters = response.data.data_filters
            dispatch(setDataFilters(data_filters))
            data_filters.product_pet && data_filters.product_pet.length && dispatch(setDataDropdown(['product_pet', data_filters.product_pet]))
            data_filters.product_technology && data_filters.product_technology.length && dispatch(setDataDropdown(['product_technology', data_filters.product_technology]))
            data_filters.product_sub_technology && data_filters.product_sub_technology.length && dispatch(setDataDropdown(['product_sub_technology', data_filters.product_sub_technology]))
            data_filters.product_manufacturer && data_filters.product_manufacturer.length && dispatch(setDataDropdown(['product_manufacturer', data_filters.product_manufacturer]))
            data_filters.product_brand && data_filters.product_brand.length && dispatch(setDataDropdown(['product_brand', data_filters.product_brand]))
            data_filters.product_sub_brand && data_filters.product_sub_brand.length && dispatch(setDataDropdown(['product_sub_brand', data_filters.product_sub_brand]))
            data_filters.product_pricing_tier && data_filters.product_pricing_tier.length && dispatch(setDataDropdown(['product_pricing_tier', data_filters.product_pricing_tier]))
            data_filters.product_pricing_tier_partition && data_filters.product_pricing_tier_partition.length && dispatch(setDataDropdown(['product_pricing_tier_partition', data_filters.product_pricing_tier_partition]))
            data_filters.product_texture && data_filters.product_texture.length && dispatch(setDataDropdown(['product_texture', data_filters.product_texture]))
            data_filters.product_package && data_filters.product_package.length && dispatch(setDataDropdown(['product_package', data_filters.product_package]))
            data_filters.product_package_size && data_filters.product_package_size.length && dispatch(setDataDropdown(['product_package_size', data_filters.product_package_size]))
            dispatch(setDataAggregations(response.data.data_aggregation))
            setFiltersDefault(dispatch, data_filters, getState);

        }
        dispatch(setDropdownLoading(false))
    }).catch((err) => {
        console.error("Data Sources Dropdown API Failed", err);
        dispatch(setDropdownLoading(false))
    })
}

export const customerChannelDefault = "Total Market"

export const customerRetailerDefault = ["Total Market:Total Market"]

export const loadFilters = () => async (dispatch, getState) => {
    let profile = getState().profile
    let loadFilterSet = {
        user_email: profile.user.user_email
    }
    let encodedURL = encodeURIComponent(JSON.stringify(loadFilterSet))
    dispatch(setDropdownLoading(true));
    CustomAxios(ENDPOINTS.loadFilters + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.error_code === undefined) {
            dispatch(setLoadFilterTable(response.data))
        }
        dispatch(setDropdownLoading(false))

    }).catch((err) => {
        console.error("Load Filters Failed", err);
        dispatch(setDropdownLoading(false))
    })
}

export const saveFilters = (filterSetName) => async (dispatch, getState) => {
    const state = getState().dataSources;
    const pcdFilters = state.filters;
    const groupByFilters = state.groupBy;
    const timePeriodCheckbox = state.timePeriodCheckbox;
    const kpi_selection = state.kpis
    let profile = getState().profile
    let loadFilterTable = [...state.loadFilterTable]
    let savedFilterSet = {
        ...pcdFilters, group_by: groupByFilters, selection_name: filterSetName,
        user_email: profile.user.user_email, length_of_time: timePeriodCheckbox, kpi: kpi_selection
    }
    let encodedURL = encodeURIComponent(JSON.stringify(savedFilterSet))
    dispatch(setDropdownLoading(true));
    CustomAxios(ENDPOINTS.saveFilters + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.status.toLowerCase() === "success") {
            openNotificationWithIcon('success', 'Saved Filter Set')
            let newSavedRow = response.data.data
            console.log("loadFilterTable", newSavedRow)
            loadFilterTable.push(newSavedRow)
            dispatch(setLoadFilterTable(loadFilterTable));
        }
        else {
            openNotificationWithIcon('error', 'Error loading saved selections (filter set)')
        }
        dispatch(setDropdownLoading(false))

    }).catch((err) => {
        console.error("Load Filters Failed", err);
        dispatch(setDropdownLoading(false))
    })
}


export const deleteFilter = (filterSetName) => async (dispatch, getState) => {
    let deleteFilterSet = { selection_name: filterSetName }
    let encodedURL = encodeURIComponent(JSON.stringify(deleteFilterSet))
    dispatch(setDropdownLoading(true));
    CustomAxios(ENDPOINTS.deleteFilters + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.status.toLowerCase() === "success") {
            openNotificationWithIcon('success', 'Deleted Filter Set')
        }
        else {
            openNotificationWithIcon('error', 'Error delete selection (filter set)')
        }
        dispatch(setDropdownLoading(false))

    }).catch((err) => {
        console.error("Delete Filter Failed", err);
        dispatch(setDropdownLoading(false))
    })
}

export const getEcomDropdownFilters = (filters, filterName) => async (dispatch, getState) => {
    let urlChannel = filters && Object.keys(filters).length !== 0 && filters.customer_channel && filters.customer_channel.length !== 0 ? [filters.customer_channel] : []
    let urlFilters = { ...filters, customer_channel: urlChannel }
    let isSelectedFilters = false;
    Object.keys(urlFilters).forEach(key => {
        if (urlFilters[key].length === 0) {
            delete urlFilters[key];
        }
    })

    let encodedURL = encodeURIComponent(JSON.stringify(urlFilters))
    dispatch(setDropdownLoading(true));
    CustomAxios(ENDPOINTS.ecomDataSourcesDropdown + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.error_code === undefined) {
            let ecom_data_filters = response.data.data_filters

            dispatch(setEcomDataFilters(ecom_data_filters))

            Object.keys(ecom_data_filters).forEach(prop => {
                if (ecom_data_filters[prop]?.length) {
                    dispatch(setEcomDataDropdown([prop, ecom_data_filters[prop]]));
                }
            });

            dispatch(setEcomDataAggregations(response.data.data_aggregation))
            setEcomFiltersDefault(dispatch, ecom_data_filters, getState);
        }
        dispatch(setDropdownLoading(false))
    }).catch((err) => {
        console.error("Ecom Data Sources Dropdown API Failed", err);
        dispatch(setDropdownLoading(false))
    })
}


// export const getEcomDropdownFilters = (filters, filterName) => async (dispatch, getState) => {
//     let urlChannel = filters?.customer_channel?.length ? [filters.customer_channel] : [];
//     let urlFilters = { ...filters, customer_channel: urlChannel };
//     let isSelectedFilters = false;

//     Object.keys(urlFilters).forEach(key => {
//         if (!urlFilters[key]?.length) {
//             delete urlFilters[key];
//         } else if (isSelectedFilters) {
//             delete urlFilters[key];
//         }

//         if (key === filterName) isSelectedFilters = true;
//     });

//     let encodedURL = encodeURIComponent(JSON.stringify(urlFilters));
//     dispatch(setDropdownLoading(true));

//     try {
//         let response = await CustomAxios(`${ENDPOINTS.ecomDataSourcesDropdown}?${encodedURL}`, 'GET');
//         if (response.data.error_code === undefined) {
//             let {
//                 product_pet: ecom_product_pet,
//                 product_technology: ecom_product_technology,
//                 product_feed_philosophy: ecom_product_feed_philosophy,
//                 product_manufacturer: ecom_product_manufacturer,
//                 product_brand: ecom_product_brand,
//                 product_sub_brand: ecom_product_sub_brand,
//                 product_texture: ecom_product_texture,
//                 product_package_size: ecom_product_package_size,
//                 customer_retailer_details: ecom_customer_retailer_details,
//                 product_upc: ecom_product_upc,
//                 period_year: ecom_period_year,
//                 group_drop_down: ecom_group_drop_down,
//             } = response.data.data_filters;

//             let data_filters = {
//                 ecom_product_pet,
//                 ecom_product_technology,
//                 ecom_product_feed_philosophy,
//                 ecom_product_manufacturer,
//                 ecom_product_brand,
//                 ecom_product_sub_brand,
//                 ecom_product_texture,
//                 ecom_product_package_size,
//                 ecom_customer_retailer_details,
//                 ecom_product_upc,
//                 ecom_period_year,
//                 ecom_group_drop_down
//             };

//             dispatch(setDataFilters(data_filters));

//             const dropdownProperties = [
//                 'ecom_product_pet',
//                 'ecom_product_technology',
//                 'ecom_product_feed_philosophy',
//                 'ecom_product_manufacturer',
//                 'ecom_product_brand',
//                 'ecom_product_sub_brand',
//                 'ecom_product_texture',
//                 'ecom_product_package_size',
//                 'ecom_customer_retailer_details',
//                 'ecom_product_upc',
//                 'ecom_group_drop_down'
//             ];

//             dropdownProperties.forEach(prop => {
//                 if (data_filters[prop]?.length) {
//                     dispatch(setEcomDataDropdown([prop, data_filters[prop]]));
//                 }
//             });

//             dispatch(setDataAggregations(response.data.data_aggregation));
//             setFiltersDefault(dispatch, data_filters, getState);
//         }

//     } catch (err) {
//         console.error("Data Sources Dropdown API Failed", err);
//     } finally {
//         dispatch(setDropdownLoading(false));
//     }
// }


export const getDataPreview = (filters, groupBy, kpis, periods, aggregates, timePeriodCheckbox) => async (dispatch, getState) => {
    let state = getState().dataSources
    let profile = getState().profile
    let kpiArray = [...kpis]
    let kpi_combination = {}
    let customerSplit = Object.keys(filters).length !== 0 && filters.customer_retailer_details && filters.customer_retailer_details.length !== 0 ? Array.from(new Set(filters.customer_retailer_details.map(val => val.split(":")[0]))) : []
    let retailerSplit = Object.keys(filters).length !== 0 && filters.customer_retailer_details && filters.customer_retailer_details.length !== 0 ? Array.from(new Set(filters.customer_retailer_details.map(val => val.split(":")[1]))) : []

    let newFilters = { ...filters, customer_channel: customerSplit, customer_retailer_details: retailerSplit }

    // Remove Empty Key Value Pairs
    Object.keys(newFilters).forEach(key => {
        if (newFilters[key].length === 0) {
            delete newFilters[key];
        }
    })

    // Set Time Period Format
    let periodYear = state.data_filters.period_year
    let periodYearArray = {}
    Object.keys(periodYear).map(val => periodYearArray[val] = [])
    filters.period_year.map(period => {
        if (Object.keys(periodYear).includes(period)) {
            periodYearArray = {
                ...periodYearArray,
                [period]: periodYear[period]
            }
        }
        else {
            let category = periodYearArray[period.split(":")[0]]
            category.push(period.split(":")[1])
            periodYearArray = {
                ...periodYearArray,
                [period.split(":")[0]]: category
            }
        }
    }
    )


    Object.keys(periodYearArray).forEach(key => {
        if (periodYearArray[key].length === 0) {
            delete periodYearArray[key];
        }
    })

    if (filters.period_year.length > 0) {
        newFilters = {
            ...newFilters,
            period_year: periodYearArray
        }
    }

    //  Set KPI Combination Format
    // kpiArray.map(val => {
    //     kpi_combination = {
    //         ...kpi_combination,
    //         [kpis[val]]: {
    //             fact_period: periods[val],
    //             // fact_aggregation: aggregates[val]
    //         }
    //     }
    // })

    let encodedURL = encodeURIComponent(JSON.stringify({
        column_filters: newFilters,
        group_by_columns: groupBy,
        kpi_combination: kpiArray,
        row_limit: state.row_limit,
        user_email: profile.user.user_email,
        time_period: timePeriodCheckbox
    }))
    dispatch(setTableLoading(true));

    function timeout(ms, promise) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                reject(new Error("timeout"))
            }, ms)
            promise.then(resolve, reject)
        })
    }

    timeout(210000, CustomAxiosTimeout(ENDPOINTS.dataPreviewTable + '?' + encodedURL, 'GET', 210000)).then((response) => {
        if (response.data.preview_data.length > 0) {
            dispatch(setDataPreview(response.data.preview_data))
            let columns = Object.keys(response.data.preview_data[0]).map(val => {
                return (
                    {
                        title: val.split("_").join(' ').toUpperCase(),
                        dataIndex: val,
                        width: val.length * 5,
                    })
            }
            )
            dispatch(setDataPreviewColumns(columns))
        }
        else {
            // openNotificationWithIcon('error', 'Data Preview Error')
            openNotificationWithIcon('error', 'No preview data available', 5)
            dispatch(setDataPreviewColumns([]))

        }
        dispatch(setTableLoading(false))
    }).catch(function (error) {

        openNotificationWithIcon('error', 'Preview Error: ' + error.message, 5)
        console.log("Preview Error", error)
        dispatch(setTableLoading(false))
        // might be a timeout error
    })

    // .catch((err) => {
    //     if (err.timeout) {

    //     }
    //     else {

    //     }
    //     // openNotificationWithIcon("Data Preview API Error")
    //     dispatch(setTableLoading(false))
    // })
}

export const getEcomDataPreview = (ecomFilters, groupBy, ecomKpis, periods, aggregates, timePeriodCheckbox) => async (dispatch, getState) => {
    let state = getState().dataSources
    let profile = getState().profile
    let kpiArray = [...ecomKpis]

    let newEcomFilters = { ...ecomFilters }

    // Remove Empty Key Value Pairs
    Object.keys(newEcomFilters).forEach(key => {
        if (newEcomFilters[key].length === 0) {
            delete newEcomFilters[key];
        }
    })

    // Set Time Period Format
    let periodYear = state.ecom_data_filters.period_year
    let periodYearArray = {}
    periodYear && Object.keys(periodYear).length > 0 && Object.keys(periodYear).map(year => {
        periodYearArray[year] = {}
        Object.keys(periodYear[year]).map(period => periodYearArray[year][period] = [])
    })
    ecomFilters.period_year.map(year => {
        let categoryYear = year.split(":")[0]
        let categoryPeriod = year.split(":")[1]
        let categoryWeek = year.split(":")[2]
        periodYearArray[categoryYear][categoryPeriod].push(categoryWeek)
    }
    )



    Object.keys(periodYearArray).forEach(year => {
        Object.keys(periodYearArray[year]).forEach(period => {
            if (periodYearArray[year][period].length === 0) {
                delete periodYearArray[year][period];
            }
        })

        if (Object.keys(periodYearArray[year]).length === 0) {
            delete periodYearArray[year];
        }
    })

    if (ecomFilters.period_year.length > 0) {
        newEcomFilters = {
            ...newEcomFilters,
            period_year: periodYearArray
        }
    }

    //  Set KPI Combination Format
    // kpiArray.map(val => {
    //     kpi_combination = {
    //         ...kpi_combination,
    //         [kpis[val]]: {
    //             fact_period: periods[val],
    //             // fact_aggregation: aggregates[val]
    //         }
    //     }
    // })

    let encodedURL = encodeURIComponent(JSON.stringify({
        column_filters: newEcomFilters,
        group_by_columns: groupBy,
        kpi_combination: kpiArray,
        row_limit: state.row_limit,
        user_email: profile.user.user_email,
        time_period: timePeriodCheckbox
    }))
    dispatch(setTableLoading(true));

    function timeout(ms, promise) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                reject(new Error("timeout"))
            }, ms)
            promise.then(resolve, reject)
        })
    }

    timeout(210000, CustomAxiosTimeout(ENDPOINTS.ecomDataPreviewTable + '?' + encodedURL, 'GET', 210000)).then((response) => {
        if (response.data.preview_data.length > 0) {
            dispatch(setEcomDataPreview(response.data.preview_data))
            let columns = Object.keys(response.data.preview_data[0]).map(val => {
                return (
                    {
                        title: val.split("_").join(' ').toUpperCase(),
                        dataIndex: val,
                        width: val.length * 5,
                    })
            }
            )
            dispatch(setEcomDataPreviewColumns(columns))
        }
        else {
            // openNotificationWithIcon('error', 'Data Preview Error')
            openNotificationWithIcon('error', 'No preview data available', 5)
            dispatch(setEcomDataPreviewColumns([]))

        }
        dispatch(setTableLoading(false))
    }).catch(function (error) {

        openNotificationWithIcon('error', 'Preview Error: ' + error.message, 5)
        console.log("Preview Error", error)
        dispatch(setTableLoading(false))
        // might be a timeout error
    })

    // .catch((err) => {
    //     if (err.timeout) {

    //     }
    //     else {

    //     }
    //     // openNotificationWithIcon("Data Preview API Error")
    //     dispatch(setTableLoading(false))
    // })
}

export const deleteTask = (task_id) => async (dispatch, getState) => {
    let url = "task_id=" + task_id
    CustomAxios(ENDPOINTS.deleteTask + '?' + url, 'GET').then((response) => {
        if (response.data.error_code) {
            openNotificationWithIcon('error', response.data.message)
        }
    }).catch((err) => {
        console.error("Task could not be deleted ", task_id, err)
    })
}


export default dataSourcesSlicer.reducer;
