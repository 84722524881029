import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import './waterfall.scss';
import { MoreOutlined } from '@ant-design/icons';
import { formatNumber, menu } from '../../utilities/helper';
import { Dropdown, Select, Radio, Checkbox } from 'antd';
import { useDispatch } from "react-redux";
import { setExportData } from '../../slicers/exportData.slicer';
import { formatNumberExcel, numFmt } from '../../utilities/exports/excel';

const { Option } = Select;

const getWaterfallData = (dta) => {
  let temp = [];
  let acc = 0;
  for (let i = 0; i < dta.length; i++) {
    temp.push({
      ...dta[i],
      values: [(i === 0 || i === dta.length - 1 ? 0 : acc), (i === 0 || i === dta.length - 1 ? dta[i].value : acc + dta[i].value)]
    })
    acc += dta[i].value
  }
  let temp2 = temp.map((val, i) => {
    return {
      ...val,
      legend: (i === 0 || i === dta.length - 1 ? 'Total' : (val.values[1] - val.values[0] >= 0 ? 'Increase' : 'Decrease'))
    }
  })
  return temp2
}

const getWaterfallDataExport = (dta) => {
  return dta.map(({ label, value }) => ({ label, value: formatNumberExcel(value), numFmt: numFmt.dollar }));
}


const getVSWaterfallData = (dta) => {
  let temp = dta.map((val, i) => {
    return {
      ...val,
      values: [(i && (i !== dta.length - 1) ? Number(dta[i - 1].value) : 0), Number(dta[i].value)]
    }
  }).map((val, i) => {
    return {
      ...val,
      legend: (i === 0 || i === dta.length - 1 ? 'Total' : (val.values[1] - val.values[0] >= 0 ? 'Increase' : 'Decrease'))
    }
  })
  return temp
}

const addPercentWaterfallData = (data, pData) => {
  let temp = data.map(val => {
    let pVal = pData.filter(val1 => val.label === val1.label)[0]["value"]
    return {
      ...val,
      pvalue: pVal,
    }
  })
  return temp
}

const getWaterfallPercentData = (dta) => {
  let temp = dta.map((val, i) => {
    return {
      ...val,
      pvalues: [(i && i !== 0 && i !== dta.length - 1 ? Number(dta[i - 1].pvalue) : 0), Number(dta[i].pvalue)]
    }
  })
  return temp
}

/**

 * Waterfall Chart

 * It manages displaying of data through waterfall chart.

 */
export default function WaterfallChart(props) {
  const dispatch = useDispatch();
  const [quarter, setQuarter] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [toggle, setToggle] = useState("percentage");
  const data = props.data
  const config = {
    // height:500,
    xField: 'label',
    yField: 'values',
    seriesField: 'legend',
    yAxis: toggle === "absolute" || props.dropdown ? {
      grid: null, label: {
        formatter: (text) => {
          return formatNumber(text, 0).replace(',', '')
        }
      }
    } : {
      label: {
        formatter: (text) => {
          return formatNumber(text, 0).replace(',', '')
        }
      }
    },
    xAxis: {
      label: {
        autoHide: false,
        autoEllipsis: true
      }
    },
    isRange: true,
    // meta:{
    //   values:{
    //     alias:'range',
    //     formatter:(v)=>{
    //       if(v===0) return v
    //       else return `${formatNumber(Number(v),1)}`
    //     }
    //   }
    // },
    color: ({ legend }) => {
      if (legend === "Total") {
        return "#8080cf";
      } else if (legend === "Increase") {
        return "#00c853";
      } else return "#f06055";
    },
    animation: false,
    label: {
      position: 'top',
      style: {
        fill: 'black'
      },
      content: ({ label, values, pvalues, legend }) => {
        if (toggle === "absolute" || props.dropdown || legend === "Total") {
          return formatNumber(values[1] - values[0]).replace(",", "")
        }
        else {
          return (pvalues[1]) + '%'
        }
      },
      offsetY: 10
    },
    legend: {
      layout: "horizontal",
      position: "top",
      itemHeight: 16,
      offsetY: -4,
      marker: {
        symbol: "square"
      }
    },
    tooltip: !props.hideTooltip && {
      customContent: (title, data) => {
        if (data.length && data[0]?.data) {
          const { label, value_ya, value, pvalues = [], legend } = data[0].data;
          const yaRegex = /^P\d+\sYA$/;
          const ya = yaRegex.test(label) ? formatNumber(value) : formatNumber(value_ya);
          const cy = yaRegex.test(label) ? formatNumber(value_ya) : formatNumber(value_ya + value);
      
          const pya = pvalues[0] || 0;
          const pcy = pvalues[1] || 0;
          return `<div class="g2-tooltip-title" style="margin-bottom: 12px; margin-top: 12px;">${title}</div>
        <ul class="g2-tooltip-list" style="margin: 0px; list-style-type: none; padding: 0px;">
          <li class="g2-tooltip-list-item" data-index="" style="list-style-type: none; padding: 0px; margin: 12px 0px;">
            <span class="g2-tooltip-marker" style="background: ${legend === "Total" ? "#8080cf" : legend === "Increase" ? "#00c853" : "#f06055"}; width: 8px; float: left; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;"></span>
            <span class="g2-tooltip-name" style="float: left"> YA </span>:
            <span class="g2-tooltip-value" style="display: inline-block; float: right; margin-left: 30px;">${toggle === "absolute" || props.dropdown ? ya : pya} </span>
          </li>
          <li class="g2-tooltip-list-item" data-index="" style="list-style-type: none; padding: 0px; margin: 12px 0px;">
            <span class="g2-tooltip-marker" style="background: ${legend === "Total" ? "#8080cf" : legend === "Increase" ? "#00c853" : "#f06055"}; width: 8px; float: left; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;"></span>
            <span class="g2-tooltip-name" style="float: left"> CY </span>:
            <span class="g2-tooltip-value" style="display: inline-block; float: right; margin-left: 30px;"> ${toggle === "absolute" || props.dropdown ? cy : pcy} </span>
          </li>
        </ul>
        </div>`;
        }
        else {
          return `<div>${title}</div>`;
        }
      }
    }
  };


  useEffect(() => {
    if (props.options.length) setSelectedFilter(props.options[0])
  }, [])

  useEffect(() => {
    // Update data and export data when data, selectedFilter, quarter, or toggle changes
    if (!data) return;
    if (Object.keys(data).length === 0) return;

    let filters = {};
    let visualData = {};
    if (props.dropdown) {
      // Data for simple waterfall chart and export
      filters = { "Product Granularity": [selectedFilter] };
      visualData = Object.keys(data).length
        ? getWaterfallDataExport(data[selectedFilter] ?? [])
        // ? data[selectedFilter] ?? []
        : [];
    } else {
      // Data for vertical stacking waterfall chart and export
      visualData = Object.keys(data).length
        ? getWaterfallPercentData(
          addPercentWaterfallData(
            getVSWaterfallData(data ?? []),
            props.percentData
          )
        )
        : [];
    }

    // Dispatch the export data to the Redux store
    dispatch(setExportData({ waterfall: { filters, data: visualData } }));
  }, [data, selectedFilter, quarter, toggle]);

  return (
    <div className={`waterfall-container ${props.checkbox ? 'trackboard-edit' : ''} ${props.checkbox && props.value ? 'trackboard-selected' : ''}`}>
      <div className="section-title" style={{ alignItems: 'center' }}>
        <div className={props.dropdown ? 'filter' : data ? 'filter-vs' : ''}>
          {props.checkbox && <Checkbox style={{ marginRight: '5%' }} value={props.value} onChange={props.onChange} className="d-check"></Checkbox>}
          {props.dropdown && <div>
            <label className="label-block">Product Granularity</label>
            <Select value={selectedFilter} onChange={(e) => setSelectedFilter(e)} style={{ width: 180 }} >
              {props.options.map((val) => (<Option value={val}>{val}</Option>))}
            </Select>
          </div>}
          {!props.dropdown && data && Object.keys(data).length && <div className="select-button">
            <Radio.Group onChange={(e) => { setToggle(e.target.value) }} defaultValue="percentage" value={toggle}>
              <Radio.Button value="percentage">Percentage</Radio.Button>
            </Radio.Group>
          </div>}
        </div>
        <h4>{props.heading}</h4>
        <div className={data ? "dropdown-container" : ''}>
          <Dropdown overlay={() => menu(props.exportData)} trigger={['click']} placement="bottomRight" className="d-more">
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <MoreOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
      {
        props.dropdown && <div className='waterfall-chart'>
          {data && Object.keys(data).length ? <Column data={getWaterfallData(data[selectedFilter] ?? [])} {...config} /> : <div className="no-data">No data available to display</div>}
        </div>
      }
      {
        !props.dropdown && <div className='waterfall-chart'>
          {data && Object.keys(data).length ? <Column data={getWaterfallPercentData(addPercentWaterfallData(getVSWaterfallData(data ?? []), props.percentData))} {...config} /> : <div className="no-data">No data available to display for this selection</div>}
        </div>
      }
    </div >);
};

