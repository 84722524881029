import { Spin, Radio } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import mdiCart from "../../assets/images/mdi_cart_green.svg";
import mdiDecagram from "../../assets/images/mdi_check-decagram_blue.svg";
import mdiCheck from "../../assets/images/mdi_eye-check_pink.svg";
import mdiView from "../../assets/images/mdi_view-dashboard_orange.svg";
import { replaceUrl } from "../../utilities/helper";

/**
 * Navigation
 * The component provides a navigation bar and data refresh dates for the application.
 */

function Navigator({ refreshDate, trafficDataToggle }) {
    const history = useHistory();
    const pathMapping = {
        "/search-term": "/search-term",
        "/traffic": "/traffic",
    };
    const beSelectedPathMapping = {
        "/ratings-reviews": "/ratings-reviews",
    };

    return (
        <div className="refreshed-on">
            {/* Navigation Bar */}
            <div className="nested-breadcrumbs">
                <Radio.Group
                    defaultValue={history.location.pathname}
                    buttonStyle="solid"
                >
                    {/* Button to navigate to Diagnostics page */}
                    <Radio.Button
                        onChange={() => replaceUrl("diagnostics", history)}
                        value="/diagnostics"
                    >
                        <img src={mdiView} alt="Diagnostics" width="20" />
                        Diagnostics
                    </Radio.Button>
                    {/* Button to navigate to Be Available page */}
                    <Radio.Button
                        onChange={() => replaceUrl("be-available", history)}
                        value="/be-available"
                    >
                        <img src={mdiDecagram} alt="Be Available" width="20" />
                        Be Available
                    </Radio.Button>
                    {/* Button to navigate to Be Visible or Search Term page based on the current path */}
                    <Radio.Button
                        onChange={() => replaceUrl("share-of-shelf", history)}
                        value={pathMapping[history.location.pathname] || "/share-of-shelf"}
                    >
                        <img src={mdiCheck} alt="Be Visible" width="20" />
                        Be Visible
                    </Radio.Button>

                    {/* Button to navigate to Be Selected page (Disabled) */}
                    <Radio.Button
                        onChange={() => replaceUrl("conversion", history)}
                        value={beSelectedPathMapping[history.location.pathname] || "/conversion"}
                    >
                        <img src={mdiCart} alt="Be Selected" width="20" />
                        Be Selected
                    </Radio.Button>
                </Radio.Group>
            </div>
            {refreshDate &&
                (<span>
                    {/* Refresh Date Information */}
                    Last Refreshed on: {refreshDate.last_refresh}, Data upto:{" "}
                    {trafficDataToggle === "Similarweb"
                        ? "2024 P05"
                        : refreshDate.latest_period_available}{" "}
                    &nbsp; | Next Refresh on: {refreshDate.next_refresh}
                </span>)
            }
        </div>
    );
}
export default Navigator;
