import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import CustomAxios from "../utilities/services/api.service";
import { ENDPOINTS } from "../utilities/constants";
import {
  openNotificationWithIcon,
  decodeTrendTreeSelectValue,
  decodeTreeSelectValue,
  decodeTreeSelectKey,
  encodeTreeSelectValue,
  encodeTreeSelectKey,
} from "../utilities/helper";
import {
  kpiDropdown,
  kpiDropdownSales,
  rowKeyMacro,
  viewBy,
} from "../pages/be-available/baUIHelper";
import {
  competitionBenchmarkFormat,
  trafficSourceData,
  viewByShare,
  shareOfShelfKPI,
} from "../pages/be-visible/bvUIHelper";
import {
  defaultCompetitionKpi,
  ecomTableData,
  ecomTableData2,
  declareAppliedFilters,
} from "../pages/ecom/dgUIHelper";
import { getState } from "../store";

export const ecomCommonFilters = ['product_pet',
  'product_technology',
  'product_sub_technology',
  'product_lifestage',
  'product_feed_philosophy',
  'product_texture',
  'customer_retailer_details',
  'year_period',
  'time_aggregate',
  'benchmark'
]

/**
 * Diagnostics Slicer
 * It manages the Redux State/Reducer/Actions for Diagnostics usecase.
 */

// Define the initial state for the diagnostics slice
const initialState = {
  loading: 0,
  microLoading: false,
  dropdownLoading: false,
  // Top filters data
  pets: [],
  techs: [],
  subTechs: [],
  lifestage: [],
  feedingPhilosophies: [],
  textures: [],
  retailers: [],
  timeAggregates: [],
  yearPeriods: [],
  benchmarkPeriods: [],
  keywordCategory: [],
  keywordType: [],
  keyword: [],

  // Performance filters data
  competitors: [],

  // Competition & benchmark filters data
  brands: [],
  trendBrands: [],
  subBrands: [],
  trendSubBrands: [],
  customerRetailers: [],
  categoryBenchmarks: [],
  competitionBenchmarks: [],
  trendCompetitionBenchmarks: [],
  // kpis: [],

  filters: {
    view: ["diagnostics"],
    product_pet: [],
    product_technology: [],
    product_sub_technology: [],
    product_lifestage: [],
    product_feed_philosophy: [],
    product_texture: [],
    customer_retailer_details: ["Amazon"],
    year_period: [],
    time_aggregate: [],
    benchmark: [],
    default_brand: ["NUTRO"],
    view_by: [Object.keys(viewByShare)[0]], //"R- Retailer/S-Sub Brand/C-Competitor" (initial load-R),
    selected_competitor: [],
    performance_summary_kpi: [shareOfShelfKPI[0]],
    deep_dive_list: [],
    selected_retailer: [],
    performance_summary_type: [trafficSourceData[0]],
    performance_summary_keyword: ["ALL"],
    keyword: [],
    kpi1: ["$ Share"],
    kpi2: ["Share of Shelf"],
    // competition & benchmark
    compare_via: [],
    compare_deep_dive_list: [],
    trend_compare_via: [],
    trend_compare_deep_dive_list: [],
    category_benchmark: [],
    competition_benchmark: [],
    competition_kpi: [...defaultCompetitionKpi],
    carry: [true],
  },
  appliedFilters: {
    view: ["share_of_shelf"],
    product_pet: ["ALL"],
    product_technology: ["ALL"],
    product_sub_technology: ["ALL"],
    product_lifestage: ["ALL"],
    product_feed_philosophy: ["ALL"],
    product_texture: ["ALL"],
    customer_retailer_details: [],
    year_period: [],
    time_aggregate: [],
    benchmark: [],
    default_brand: ["NUTRO"],
    view_by: [Object.keys(viewByShare)[0]],
    selected_competitor: [],
    performance_summary_kpi: [shareOfShelfKPI[0]],
    deep_dive_list: [],
    selected_retailer: [],
    performance_summary_type: [trafficSourceData[0]],
    performance_summary_keyword: ["ALL"],
    keyword: [],
    kpi1: [],
    kpi2: [],
    // competition & benchmark
    compare_via: [],
    compare_deep_dive_list: [],
    trend_compare_via: [],
    trend_compare_deep_dive_list: [],
    category_benchmark: [],
    competition_benchmark: [],
    competition_kpi: [...defaultCompetitionKpi],
    carry: [true],
  },
  kpiTrendsData: [],
  cardData: {},
  macroTableData: [],
  competitionTableData: cloneDeep(competitionBenchmarkFormat),

  // onMouseLeave
  selectedCompetitor: [],
};

// Create the diagnostics slice using createSlice function from Redux Toolkit
const diagnosticsSlicer = createSlice({
  name: "diagnostics",
  initialState,
  reducers: {
    resetDiagnostics: () => initialState,
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setMicroLoading: (state, action) => ({
      ...state,
      microLoading: action.payload,
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: action.payload,
    }),
    // Set top filters
    setPets: (state, action) => ({ ...state, pets: action.payload }),
    setTechs: (state, action) => ({ ...state, techs: action.payload }),
    setSubTechs: (state, action) => ({ ...state, subTechs: action.payload }),
    setLifeStage: (state, action) => ({ ...state, lifestage: action.payload }),
    setFeedingPhilosophies: (state, action) => ({
      ...state,
      feedingPhilosophies: action.payload,
    }),
    setTextures: (state, action) => ({ ...state, textures: action.payload }),
    setRetailers: (state, action) => ({ ...state, retailers: action.payload }),
    setRetailerDropdown: (state, action) => ({
      ...state,
      retailerDropdown: action.payload,
    }),
    setTimeAggregates: (state, action) => ({
      ...state,
      timeAggregates: action.payload,
    }),
    setYearPeriods: (state, action) => ({
      ...state,
      yearPeriods: action.payload,
    }),
    setBenchmarkPeriods: (state, action) => ({
      ...state,
      benchmarkPeriods: action.payload,
    }),
    setKeywordCategory: (state, action) => ({
      ...state,
      keywordCategory: action.payload,
    }),
    setKeywordType: (state, action) => ({
      ...state,
      keywordType: action.payload,
    }),
    setKeyword: (state, action) => ({
      ...state,
      keyword: action.payload,
    }),

    // Set performance filters
    setCompetitors: (state, action) => ({
      ...state,
      competitors: action.payload,
    }),

    // set trend chart filters
    setTrendBrands: (state, action) => ({
      ...state,
      trendBrands: action.payload,
    }),
    setTrendSubBrands: (state, action) => ({
      ...state,
      trendSubBrands: action.payload,
    }),
    setTrendCompetitionBenchmarks: (state, action) => ({
      ...state,
      trendCompetitionBenchmarks: action.payload,
    }),
    // Set Competition filters
    setBrands: (state, action) => ({
      ...state,
      brands: action.payload,
    }),
    setSubBrands: (state, action) => ({
      ...state,
      subBrands: action.payload,
    }),
    setCustomerRetailers: (state, action) => ({
      ...state,
      customerRetailers: action.payload,
    }),
    setCategoryBenchmarks: (state, action) => ({
      ...state,
      categoryBenchmarks: action.payload,
    }),
    setCompetitionBenchmarks: (state, action) => ({
      ...state,
      competitionBenchmarks: action.payload,
    }),
    // setKpis: (state, action) => ({
    //   ...state,
    //   kpis: action.payload,
    // }),

    // Report
    setFilters: (state, action) => ({
      ...state,
      filters: action.payload,
    }),
    setAppliedFilters: (state, action) => ({
      ...state,
      appliedFilters: action.payload,
    }),
    setCardData: (state, action) => ({
      ...state,
      cardData: action.payload,
    }),
    setMacroTableData: (state, action) => ({
      ...state,
      macroTableData: action.payload,
    }),
    setKpiTrendsData: (state, action) => ({
      ...state,
      kpiTrendsData: action.payload,
    }),
    setKpi1: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        kpi1: action.payload
      }
    }),
    setKpi2: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        kpi2: action.payload
      }
    }),
    setCompetitionTableData: (state, action) => ({
      ...state,
      competitionTableData: action.payload,
    }),

    // on Mouse Leave
    setSelectedCompetitor: (state, action) => ({
      ...state,
      selectedCompetitor: action.payload,
    }),
    // on Mouse Leave
    setSelectedRetailer: (state, action) => ({
      ...state,
      selectedRetailer: action.payload,
    }),
  },
});

// Export the actions generated by createSlice
export const {
  resetDiagnostics,
  setLoading,
  setMicroLoading,
  setDropdownLoading,
  setPets,
  setTechs,
  setSubTechs,
  setLifeStage,
  setFeedingPhilosophies,
  setTextures,
  setRetailers,
  setRetailerDropdown,
  setTimeAggregates,
  setYearPeriods,
  setBenchmarkPeriods,
  setKeywordCategory,
  setKeywordType,
  setKeyword,
  setCompetitors,

  setBrands,
  setTrendBrands,
  setSubBrands,
  setTrendSubBrands,
  setCustomerRetailers,
  setCategoryBenchmarks,
  setCompetitionBenchmarks,
  setTrendCompetitionBenchmarks,
  // setKpis,

  setFilters,
  setAppliedFilters,
  setCardData,
  setMacroTableData,
  setKpiTrendsData,
  setKpi1,
  setKpi2,
  setCompetitionTableData,

  // on Mouse Leave
  setSelectedCompetitor,

  setSelectedRetailer,
} = diagnosticsSlicer.actions;

// Get a deep copy of the initial filters from the diagnostics state
export const getInitialFilters = () => cloneDeep(initialState.filters);

export const getInitialBottomFilters = () => {
  const state = getState().diagnostics;

  const initialFilters = cloneDeep(initialState.filters);
  const oldFilters = cloneDeep(state.filters);

  return {
    ...initialFilters,
    product_pet: oldFilters.product_pet,
    product_technology: oldFilters.product_technology,
    product_sub_technology: oldFilters.product_sub_technology,
    product_lifestage: oldFilters.product_lifestage,
    product_feed_philosophy: oldFilters.product_feed_philosophy,
    product_texture: oldFilters.product_texture,
    customer_retailer_details: oldFilters.customer_retailer_details,
    year_period: oldFilters.year_period,
    time_aggregate: oldFilters.time_aggregate,
    benchmark: oldFilters.benchmark,
  }
}

// Process and modify the provided filters based on the current state in the diagnostics slice
export const processFilters = (filters, getState) => {
  let state = getState().diagnostics;
  let brand = getState().profile.defaultBrand;

  let tempFilters = { ...filters };
  if (
    tempFilters.product_pet &&
    tempFilters.product_pet.length === 0
  ) {
    tempFilters["product_pet"] = "ALL";
  }
  if (
    tempFilters.product_technology &&
    tempFilters.product_technology.length === 0
  ) {
    tempFilters["product_technology"] = "ALL";
  }
  if (
    tempFilters.product_sub_technology &&
    tempFilters.product_sub_technology.length === 0
  ) {
    tempFilters["product_sub_technology"] = "ALL";
  }
  if (
    tempFilters.product_lifestage &&
    tempFilters.product_lifestage.length === 0
  ) {
    tempFilters["product_lifestage"] = "ALL";
  }
  if (
    tempFilters.product_feed_philosophy &&
    tempFilters.product_feed_philosophy.length === 0
  ) {
    tempFilters["product_feed_philosophy"] = "ALL";
  }
  if (
    tempFilters.product_texture &&
    tempFilters.product_texture.length === 0
  ) {
    tempFilters["product_texture"] = "ALL";
  }

  if (brand) {
    if (brand.split(":").length === 2) {
      tempFilters["product_brand"] = brand.split(":")[0];
      tempFilters["product_secondary_brand"] = brand.split(":")[1];
    } else {
      tempFilters["product_brand"] = brand;
      tempFilters["product_secondary_brand"] = "";
    }
  }

  if (tempFilters["selected_competitor"])
    tempFilters["selected_competitor"] = encodeTreeSelectKey(
      tempFilters["selected_competitor"]
    );
  if (tempFilters["category_benchmark"])
    tempFilters["category_benchmark"] = encodeTreeSelectValue(
      tempFilters["category_benchmark"]
    );
  if (tempFilters["competition_benchmark"])
    tempFilters["competition_benchmark"] = encodeTreeSelectValue(
      tempFilters["competition_benchmark"]
    );
  if (tempFilters["competition_kpi"])
    tempFilters["competition_kpi"] = encodeTreeSelectKey(
      tempFilters["competition_kpi"]
    );

  return tempFilters;
};


export const updateRetailerDropdown = (filters, retailers, dispatch) => {
  const newFilters = { ...filters };
  if (retailers.length === 0) return [];
  const retailerDropdown = retailers.filter(
    (retailer) => !filters.customer_retailer_details.includes(retailer)
  );

  const filteredSelectedRetailers = [...newFilters.selected_retailer].filter(
    (retailer) => retailerDropdown.includes(retailer)
  );
  const selectedRetailers = [...newFilters.selected_retailer];
  if (!isEqual(filteredSelectedRetailers.sort(), selectedRetailers.sort())) {
    newFilters.selected_retailer = filteredSelectedRetailers;
    dispatch(setSelectedRetailer(filteredSelectedRetailers));
  }

  dispatch(setRetailerDropdown(retailerDropdown));
  return newFilters;
};

const setFiltersDefault = (
  dispatch,
  response,
  getState,
  initialLoad = false,
  getCarryFilters = {},
  isBrandChanged = false
) => {
  const state = getState().diagnostics;
  let filters = state.filters;
  const appliedFilters = state.appliedFilters;

  const newFilters = { ...filters };
  if (response.data.default_customer_retailer_details)
    if (filters.customer_retailer_details[0] === "Amazon") {
      newFilters.customer_retailer_details = [
        "Amazon"
        // response.data.default_customer_retailer_details,
      ]
    }
  if (newFilters.time_aggregate.length === 0 && response.data.default_time_aggregate)
    newFilters.time_aggregate = [response.data.default_time_aggregate];
  if (newFilters.year_period.length === 0 && response.data.default_year_period)
    newFilters.year_period = [response.data.default_year_period];
  if (newFilters.benchmark.length === 0 && response.data.default_benchmark_period)
    newFilters.benchmark = [response.data.default_benchmark_period];

  newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
  newFilters.product_technology = newFilters.product_technology.filter(item => (response.data.product_technology || state.techs).includes(item));
  newFilters.product_sub_technology = newFilters.product_sub_technology.filter(item => (response.data.product_sub_technology || state.subTechs).includes(item));
  newFilters.product_lifestage = newFilters.product_lifestage.filter(item => (response.data.product_lifestage || state.lifestage).includes(item));
  newFilters.product_feed_philosophy = newFilters.product_feed_philosophy.filter(item => (response.data.product_feed_philosophy || state.feedingPhilosophies).includes(item));
  newFilters.product_texture = newFilters.product_texture.filter(item => (response.data.product_texture || state.textures).includes(item));

  dispatch(setFilters(newFilters));

  if (initialLoad) {
    const newAppliedFilters = { ...appliedFilters };
    if (response.data.default_customer_retailer_details)
      if (filters.customer_retailer_details[0] === "Amazon") {
        newAppliedFilters.customer_retailer_details = [
          "Amazon"
          // response.data.default_customer_retailer_details,
        ];
      }
    if (response.data.default_time_aggregate && newAppliedFilters.time_aggregate.length === 0)
      newAppliedFilters.time_aggregate = [response.data.default_time_aggregate];
    if (response.data.default_year_period && newAppliedFilters.year_period.length === 0)
      newAppliedFilters.year_period = [response.data.default_year_period];
    if (response.data.default_benchmark_period && newAppliedFilters.benchmark.length === 0)
      newAppliedFilters.benchmark = [response.data.default_benchmark_period];



    newAppliedFilters.product_pet = newFilters.product_pet;
    newAppliedFilters.product_technology = newFilters.product_technology;
    newAppliedFilters.product_feed_philosophy = newFilters.product_feed_philosophy;
    newAppliedFilters.product_texture = newFilters.product_texture;
    dispatch(setAppliedFilters(declareAppliedFilters(newAppliedFilters, state)));
  }

  return newFilters;
};

const getFilterForBrandChange = (filters) => {
  const filterKeys = [
    'product_pet',
    'product_technology',
    'product_sub_technology',
    'product_lifestage',
    'product_feed_philosophy',
    'product_texture',
  ];

  // Initialize newFilters as an empty object
  let newFilters = {};

  // Loop over each key and add it to newFilters if the corresponding filter has values
  filterKeys.forEach(key => {
    if (filters[key]?.length > 0) {
      newFilters[key] = filters[key];
    }
  });

  // Add the remaining filters directly
  newFilters = {
    ...newFilters,
    customer_retailer_details: filters.customer_retailer_details,
    time_aggregate: filters.time_aggregate,
    year_period: filters.year_period,
    benchmark: filters.benchmark,
  };

  return newFilters;
}


// Fetch all filters data from the server and update the diagnostics state
export const getAllFilters = (isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.diagnosticsDropdown, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_technology)
        dispatch(setTechs(response.data.product_technology));
      if (response.data.product_sub_technology)
        dispatch(setSubTechs(response.data.product_sub_technology));
      if (response.data.product_lifestage)
        dispatch(setLifeStage(response.data.product_lifestage));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));

      const newFilters = setFiltersDefault(dispatch, response, getState, true, true);
      // Update the retailerDropdown and selected_retailer filters based on the new retailers fetched
      if (response.data.customer_retailer_details)
        updateRetailerDropdown(
          newFilters,
          response.data.customer_retailer_details,
          dispatch
        );

      dispatch(getCardData(newFilters));
      dispatch(getMacroTableData(newFilters));
      dispatch(getKpiTrendsData(newFilters));
      dispatch(getCompetitionFilterData(newFilters));
      dispatch(getCompetitionFilterDataForTrendChart(newFilters));
      dispatch(getTotalPetBenchmarkData(newFilters));
      dispatch(getInternalBenchmarkData(newFilters));
      dispatch(getCategoryBenchmarkData(newFilters));
      dispatch(getCompetitionBenchmarkData(newFilters));

      if (isBrandChanged && changeSelectedBrand) {
        changeSelectedBrand();
        dispatch(getFilterData(getFilterForBrandChange(getState().diagnostics.filters)));
      }

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
    });
};

// Fetch filter data for the diagnostics dropdown based on the provided filters
export const getFilterData = (filters, loadPage = false, isBrandChanged = false, changeSelectedBrand = null, getCarryFilters = []) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  let endpoint = filters["allFilters"] ? ENDPOINTS.diagnosticsDropdown : ENDPOINTS.diagnosticsDropdown + "?" + encodedURL

  dispatch(setDropdownLoading(true));
  // Make a GET request to fetch the diagnostics dropdown data from the server
  CustomAxios(endpoint, "GET")
    .then((response) => {
      // Update various filters in the diagnostics state with the response data
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_technology)
        dispatch(setTechs(response.data.product_technology));
      if (response.data.product_sub_technology)
        dispatch(setSubTechs(response.data.product_sub_technology));
      if (response.data.product_lifestage)
        dispatch(setLifeStage(response.data.product_lifestage));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));

      // Set default filters based on the response data and current state
      const newFilters = setFiltersDefault(dispatch, response, getState, loadPage, getCarryFilters);

      if (loadPage) {
        dispatch(getCardData(newFilters));
        dispatch(getMacroTableData(newFilters));
        dispatch(getKpiTrendsData(newFilters));
        dispatch(getCompetitionFilterData(newFilters));
        dispatch(getCompetitionFilterDataForTrendChart(newFilters));
        dispatch(getTotalPetBenchmarkData(newFilters));
        dispatch(getInternalBenchmarkData(newFilters));
        dispatch(getCategoryBenchmarkData(newFilters));
        dispatch(getCompetitionBenchmarkData(newFilters));
      }

      if (isBrandChanged && changeSelectedBrand) {
        changeSelectedBrand();
        // dispatch(getFilterData(getFilterForBrandChange(getState().diagnostics.filters)));
      }

      let brandLimitedFilters = Object.entries(newFilters).reduce((acc, [key, value]) => {
        if (key in acc) acc[key] = [...value];
        return acc;
      }, { ...getCarryFilters, customer_retailer_details: newFilters["customer_retailer_details"] })

      if (Object.keys(getCarryFilters).length > 0) {
        console.log("diagnostics 2nd carry")
        dispatch(getFilterData(brandLimitedFilters))
      }

      // Set the dropdown loading state to false
      dispatch(setDropdownLoading(false));
    })
    .catch((err) => {
      // Set the dropdown loading state to false in case of error
      dispatch(setDropdownLoading(false));
    });
};

// Fetch filter data for the competition dropdown based on the provided filters
export const getCompetitionFilterData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    // Make a GET request to fetch the competition dropdown data from the server
    CustomAxios(ENDPOINTS.ecomCompetitionDropdown + "?" + encodedURL, "GET")
      .then((response) => {
        if (response.data.brand_list)
          dispatch(setBrands(response.data.brand_list));
        if (response.data.sub_brand_list)
          dispatch(setSubBrands(response.data.sub_brand_list));
        if (response.data.retailer_list)
          dispatch(setCustomerRetailers(response.data.retailer_list));

        if (response.data.category_benchmark)
          dispatch(
            setCategoryBenchmarks(
              decodeTreeSelectValue(response.data.category_benchmark)
            )
          );

        if (response.data.competition_benchmark)
          dispatch(
            setCompetitionBenchmarks(
              decodeTreeSelectValue(response.data.competition_benchmark)
            )
          );

        if (response.data.competitor_list)
          dispatch(
            setCompetitors(decodeTreeSelectKey(response.data.competitor_list))
          );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));

      });
  };

function dispatchIfDefined(data, action, dispatch) {
  if (data) {
    dispatch(action(data));
  }
}

// Fetch filter data for the competition dropdown based on the provided filters
export const getCompetitionFilterDataForTrendChart =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    // Make a GET request to fetch the competition dropdown data from the server
    CustomAxios(ENDPOINTS.ecomDiagnosticsCompetition + "?" + encodedURL, "GET")
      .then((response) => {
        dispatchIfDefined(response.data.brand_list, setTrendBrands, dispatch);
        dispatchIfDefined(response.data.sub_brand_list, setTrendSubBrands, dispatch);
        dispatchIfDefined(
          response.data.competition_benchmark,
          (data) =>
            setTrendCompetitionBenchmarks(
              decodeTrendTreeSelectValue(data, true, "", false)
            ),
          dispatch);

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));

      });
  };

// Fetch card data for the diagnostics card based on the provided filters
export const getCardData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  // Make a GET request to fetch the diagnostics card data from the server
  CustomAxios(ENDPOINTS.diagnosticsCardData + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setCardData(response.data));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setCardData(initialState.cardData));
    });
};

// Process the hierarchy data for the macro table
function processMacro(data) {
  return data.reduce((previousValue, currentValue) => {
    const table_hierarchy_data = processMacro(
      currentValue.table_hierarchy_data || []
    );
    // if (table_hierarchy_data.length)
    previousValue.push({
      ...currentValue,
      [rowKeyMacro]: currentValue.table_hierarchy_data
        ? `parent_key:${currentValue.table_key}`
        : `child_key:${currentValue.table_key}`,
      table_hierarchy_data: table_hierarchy_data.length
        ? table_hierarchy_data
        : null,
    });
    return previousValue;
  }, []);
}

// Fetch table data for the macro table based on the provided filters
export const getMacroTableData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.diagnosticsTableData + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setMacroTableData(processMacro(response.data)));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setMacroTableData(initialState.macroTableData));
    });
};

function processLineChartItem(item, kpiName, newDataArray) {
  let modifyItem = { ...item, [kpiName]: item.value };
  delete modifyItem.value;
  newDataArray.push(modifyItem);
}

function processData(response, chartType, kpiName, kpi, newDataArray) {
  if (response?.data?.perf_summary_deepdive?.[chartType]?.length) {
    for (const item of response.data.perf_summary_deepdive[chartType]) {
      if (item.name === kpi) {
        processLineChartItem(item, kpiName, newDataArray);
      }
    }
  }
}
// Fetch KPI trends data based on the provided filters
export const getKpiTrendsData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.diagnosticsKpiTrends + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.perf_summary_deepdive) {
        let data = { line_chart: [], line_chart_graph: [] }
        processData(response, 'line_chart', 'kpi_1_3', 'kpi_1', data.line_chart);
        processData(response, 'line_chart', 'kpi_2_4', 'kpi_2', data.line_chart_graph);
        processData(response, 'line_chart_graph', 'kpi_1_3', 'kpi_3', data.line_chart);
        processData(response, 'line_chart_graph', 'kpi_2_4', 'kpi_4', data.line_chart_graph);
        dispatch(setKpiTrendsData(data));
      }


      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setKpiTrendsData(initialState.kpiTrendsData));
    });
}

// const processCompetitionTableData = (parentIndex, responseData, stateData) => {
//   stateData[parentIndex].table_hierarchy_data = responseData.map(
//     (item, childIndex) => ({ ...item, index: `${parentIndex}-${childIndex}` })
//   );
//   return stateData;
// };

// export const getInternalBenchmarkData =
//   (filters) => async (dispatch, getState) => {
//     let tempFilters = processFilters(filters, getState);
//     let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

//     dispatch(setLoading(true));
//     CustomAxios(ENDPOINTS.benchmarkInternal + "?" + encodedURL, "GET")
//       .then((response) => {
//         const competitionTableData = cloneDeep(
//           getState().diagnostics.competitionTableData
//         );
//         dispatch(setCompetitionTableData(processCompetitionTableData(
//           0,
//           response.data,
//           competitionTableData
//         )));

//         dispatch(setLoading(false));
//       })
//       .catch((err) => {
//         dispatch(setLoading(false));
//         const competitionTableData = cloneDeep(
//           getState().diagnostics.competitionTableData
//         );
//         dispatch(setCompetitionTableData(processCompetitionTableData(
//           0,
//           [],
//           competitionTableData
//         )));
//       });
//   };
// export const getCategoryBenchmarkData =
//   (filters) => async (dispatch, getState) => {
//     let tempFilters = processFilters(filters, getState);
//     let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

//     dispatch(setLoading(true));
//     CustomAxios(ENDPOINTS.benchmarkCategory + "?" + encodedURL, "GET")
//       .then((response) => {
//         const competitionTableData = cloneDeep(
//           getState().diagnostics.competitionTableData
//         );
//         dispatch(setCompetitionTableData(processCompetitionTableData(
//           1,
//           response.data,
//           competitionTableData
//         )));

//         dispatch(setLoading(false));
//       })
//       .catch((err) => {
//         dispatch(setLoading(false));
//         const competitionTableData = cloneDeep(
//           getState().diagnostics.competitionTableData
//         );
//         dispatch(setCompetitionTableData(processCompetitionTableData(
//           1,
//           [],
//           competitionTableData
//         )));
//       });
//   };

// export const getCompetitionBenchmarkData =
//   (filters) => async (dispatch, getState) => {
//     let tempFilters = processFilters(filters, getState);
//     let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

//     dispatch(setLoading(true));
//     CustomAxios(ENDPOINTS.benchmarkCompetition + "?" + encodedURL, "GET")
//       .then((response) => {
//         const competitionTableData = cloneDeep(
//           getState().diagnostics.competitionTableData
//         );
//         dispatch(setCompetitionTableData(processCompetitionTableData(
//           2,
//           response.data,
//           competitionTableData
//         )));

//         dispatch(setLoading(false));
//       })
//       .catch((err) => {
//         dispatch(setLoading(false));
//         const competitionTableData = cloneDeep(
//           getState().diagnostics.competitionTableData
//         );
//         dispatch(setCompetitionTableData(processCompetitionTableData(
//           2,
//           [],
//           competitionTableData
//         )));
//       });
//   };


// Process competition table data to include hierarchy index for rendering
const processCompetitionTableData = (parentIndex, responseData, stateData, isSingle = false) => {
  if (isSingle && responseData)
    stateData[parentIndex] = { ...stateData[parentIndex], ...responseData };
  else
    stateData[parentIndex].table_hierarchy_data = responseData.map(
      (item, childIndex) => ({ ...item, index: `${parentIndex}-${childIndex}` })
    );

  return stateData;
};

// Fetch total pet benchmark data based on the provided filters
export const getTotalPetBenchmarkData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    // Make a GET request to fetch the total pet benchmark data from the server
    CustomAxios(ENDPOINTS.diagnosticsBenchmarkTotalPet + "?" + encodedURL, "GET")
      .then((response) => {
        const competitionTableData = cloneDeep(
          getState().diagnostics.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(0, response.data?.[0], competitionTableData, true)
          )
        );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().diagnostics.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(0, {}, competitionTableData, true)
          )
        );
      });
  };

// Fetch internal benchmark data based on the provided filters
export const getInternalBenchmarkData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    // Make a GET request to fetch the internal benchmark data from the server
    CustomAxios(ENDPOINTS.diagnosticsBenchmarkInternal + "?" + encodedURL, "GET")
      .then((response) => {
        const competitionTableData = cloneDeep(
          getState().diagnostics.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(1, response.data, competitionTableData)
          )
        );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().diagnostics.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(1, [], competitionTableData)
          )
        );
      });
  };

// Fetch competition benchmark data based on the provided filters
export const getCompetitionBenchmarkData =
  (filters) => async (dispatch, getState) => {
    if (filters.competition_benchmark.length === 0) {
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().diagnostics.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(2, [], competitionTableData)
          )
        );
      });
    } else {
      let tempFilters = processFilters(filters, getState);
      let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

      dispatch(setLoading(true));
      // CustomAxios(ENDPOINTS.diagnosticsBenchmarkCompetition + "?" + encodedURL, "GET")
      CustomAxios(ENDPOINTS.diagnosticsBenchmarkCompetition, "POST", tempFilters)
        .then((response) => {
          const competitionTableData = cloneDeep(
            getState().diagnostics.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(
                2,
                response.data,
                competitionTableData
              )
            )
          );

          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          const competitionTableData = cloneDeep(
            getState().diagnostics.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(2, [], competitionTableData)
            )
          );
        });
    }
  };

// Fetch category benchmark data based on the provided filters
export const getCategoryBenchmarkData =
  (filters) => async (dispatch, getState) => {
    // If the category_benchmark filter is empty, set the loading state and return an empty array for the table
    if (filters.category_benchmark.length === 0) {
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().diagnostics.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(3, [], competitionTableData)
          )
        );
      });
    } else {
      let tempFilters = processFilters(filters, getState);
      let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

      dispatch(setLoading(true));
      // Make a GET request to fetch the category benchmark data from the server
      CustomAxios(ENDPOINTS.diagnosticsBenchmarkCategory + "?" + encodedURL, "GET")
        .then((response) => {
          const competitionTableData = cloneDeep(
            getState().diagnostics.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(
                3,
                response.data,
                competitionTableData
              )
            )
          );

          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          const competitionTableData = cloneDeep(
            getState().diagnostics.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(3, [], competitionTableData)
            )
          );
        });
    }
  };

export default diagnosticsSlicer.reducer;
