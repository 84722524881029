import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import CustomAxios from "../utilities/services/api.service";
import { ENDPOINTS } from "../utilities/constants";
import {
  encodeTreeSelectKey,
  decodeTreeSelect,
  encodeTreeSelectValue,
  decodeTreeSelectValue,
} from "../utilities/helper";
import {
  viewBySearch,
  viewByStretagy,
  barLegends,
} from "../pages/search-term/stUIHelper";

/**
 * Search Insights Slicer
 * It manages the Redux State/Reducer/Actions for Search Insights usecase.
 */

const initialState = {
  loading: 0,
  dropdownLoading: false,

  // Top filters data
  keywordCategories: [],
  keywordTypes: [],
  feedingPhilosophies: [],
  textures: [],
  priceTiers: [],
  functions: [],
  lifeStages: [],
  breedSizes: [],
  packageTypes: [],
  proteins: [],

  retailers: [],
  retailerDropdown: [],
  timeAggregates: [],
  yearPeriods: [],
  benchmarkPeriods: [],
  brands: [],
  trendBrands: [],
  categories: [],

  filters: {
    view: ["search_term"],
    product_keyword_category: [],
    product_keyword_type: [],
    product_feed_philosophy: [],
    product_texture: [],
    product_price_tier: [],
    product_function: [],
    product_life_stage: [],
    product_breed_size: [],
    product_package_type: [],
    product_protein: [],

    customer_retailer_details: ["Amazon"],
    time_aggregate: [],
    year_period: [],
    benchmark: [],
    carry: [true],

    // default_brand: ["NUTRO"],
    view_by: [viewBySearch[0]],
    trend_product_brand: [],
    trend_view: ["top"],
    trend_limit: ["10"],
    traffic_percentage_limit: [50],
    deep_dive_list: [],

    retailer_view: [0],
    retailer_view_brand: [],
    selected_retailer: [],
    searchTermPagination: {
      pageIndex: 1,
      pageSize: 100,
      search: ''
    },
    strategy_view_by: [viewByStretagy[0]],
    strategy_category: [],
    strategy_brand: [],
  },
  appliedFilters: {
    view: ["search_term"],
    product_keyword_category: ["ALL"],
    product_keyword_type: ["ALL"],
    product_feed_philosophy: ["ALL"],
    product_texture: ["ALL"],
    product_price_tier: ["ALL"],
    product_function: ["ALL"],
    product_life_stage: [],
    product_breed_size: [],
    product_package_type: [],
    product_protein: [],

    customer_retailer_details: [],
    time_aggregate: [],
    year_period: [],
    benchmark: [],
    carry: [true],

    // default_brand: ["NUTRO"],
    view_by: [viewBySearch[0]],
    trend_product_brand: [],
    trend_view: ["top"],
    trend_limit: ["10"],
    deep_dive_list: [],
    searchTermPagination: {
      pageIndex: 1,
      pageSize: 100,
      search: ''
    },

    retailer_view: [0],
    retailer_view_brand: [],
    selected_retailer: [],

    strategy_view_by: [viewByStretagy[0]],
    strategy_category: [],
    strategy_brand: [],
  },
  cardData: {},
  strategyData: [],
  strategyDataDuplicate: [],
  comparisonTrendData: [],
  deepdiveSingleTrendData: [],
  deepdiveMultiTrendData: [],
  trendData: [],
  tableData: {},
  searchTermOverview: {},
  tableSearch: [],
  // onMouseLeave
  selectedRetailer: [],
};

//
const searchTermSlicer = createSlice({
  name: "searchTerm",
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: action.payload,
    }),
    // Set top filters
    setKeywordCategories: (state, action) => ({
      ...state,
      keywordCategories: action.payload,
    }),
    setKeywordTypes: (state, action) => ({
      ...state,
      keywordTypes: action.payload,
    }),
    setFeedingPhilosophies: (state, action) => ({
      ...state,
      feedingPhilosophies: action.payload,
    }),
    setTextures: (state, action) => ({ ...state, textures: action.payload }),
    setPriceTeirs: (state, action) => ({
      ...state,
      priceTiers: action.payload,
    }),
    setFunctions: (state, action) => ({ ...state, functions: action.payload }),
    setLifeStages: (state, action) => ({
      ...state,
      lifeStages: action.payload,
    }),
    setBreedSizes: (state, action) => ({
      ...state,
      breedSizes: action.payload,
    }),
    setPackageTypes: (state, action) => ({
      ...state,
      packageTypes: action.payload,
    }),
    setProteins: (state, action) => ({ ...state, proteins: action.payload }),

    setRetailers: (state, action) => ({ ...state, retailers: action.payload }),
    setRetailerDropdown: (state, action) => ({
      ...state,
      retailerDropdown: action.payload,
    }),
    setTimeAggregates: (state, action) => ({
      ...state,
      timeAggregates: action.payload,
    }),
    setYearPeriods: (state, action) => ({
      ...state,
      yearPeriods: action.payload,
    }),
    setBenchmarkPeriods: (state, action) => ({
      ...state,
      benchmarkPeriods: action.payload,
    }),
    setBrands: (state, action) => ({
      ...state,
      brands: action.payload,
    }),
    setTrendBrands: (state, action) => ({
      ...state,
      trendBrands: action.payload,
    }),
    setCategories: (state, action) => ({
      ...state,
      categories: action.payload,
    }),

    // Report
    setFilters: (state, action) => ({
      ...state,
      filters: action.payload,
    }),
    setAppliedFilters: (state, action) => ({
      ...state,
      appliedFilters: action.payload,
    }),
    setStrategyData: (state, action) => ({
      ...state,
      strategyData: action.payload,
    }),
    setDuplicateStrategyData: (state, action) => ({
      ...state,
      strategyDataDuplicate: action.payload,
    }),
    setSearchTermOverview: (state, action) => ({
      ...state,
      searchTermOverview: action.payload,
    }),
    setComparisonTrendData: (state, action) => ({
      ...state,
      comparisonTrendData: action.payload,
    }),
    setDeepdiveSingleTrendData: (state, action) => ({
      ...state,
      deepdiveSingleTrendData: action.payload,
    }),
    setDeepdiveMultiTrendData: (state, action) => ({
      ...state,
      deepdiveMultiTrendData: action.payload,
    }),
    setTableData: (state, action) => ({
      ...state,
      tableData: action.payload,
    }),
    setSearchTermPagination: (state, action) => ({
      ...state,
      searchTermPagination: action.payload
    }),
    setSearch: (state, action) => ({
      ...state,
      tableSearch: action.payload
    }),

    // on Mouse Leave
    setSelectedRetailer: (state, action) => ({
      ...state,
      selectedRetailer: action.payload,
    }),
  },
});

export const {
  resetBeAvailable,
  setLoading,
  setKeywordCategories,
  setKeywordTypes,
  setFeedingPhilosophies,
  setTextures,
  setPriceTeirs,
  setFunctions,
  setLifeStages,
  setBreedSizes,
  setPackageTypes,
  setProteins,

  setRetailers,
  setRetailerDropdown,
  setTimeAggregates,
  setYearPeriods,
  setBenchmarkPeriods,
  setBrands,
  setTrendBrands,
  setCategories,

  setFilters,
  setAppliedFilters,
  setStrategyData,
  setDuplicateStrategyData,
  setSearchTermOverview,
  setComparisonTrendData,
  setDeepdiveSingleTrendData,
  setDeepdiveMultiTrendData,
  setTrendData,
  setTableData,
  setSearchTermPagination,
  setSearch,
  // on Mouse Leave
  setSelectedCompetitor,
  setSelectedRetailer,
  setDropdownLoading
} = searchTermSlicer.actions;

export const getInitialFilters = () => cloneDeep(initialState.filters);

export const processFilters = (filters) => {
  let tempFilters = { ...filters };
  // Special case for keyword type filter
  if (
    tempFilters.product_keyword_type &&
    tempFilters.product_keyword_type.length === 0
  ) {
    tempFilters["product_keyword_type"] = "ALL";
  }
  // Special case for feed philosophy filter
  if (
    tempFilters.product_feed_philosophy &&
    tempFilters.product_feed_philosophy.length === 0
  ) {
    tempFilters["product_feed_philosophy"] = "ALL";
  }
  // Special case for texture filter
  if (
    tempFilters.product_texture &&
    tempFilters.product_texture.length === 0
  ) {
    tempFilters["product_texture"] = "ALL";
  }
  // Special case for price tier filter
  if (
    tempFilters.product_price_tier &&
    tempFilters.product_price_tier.length === 0
  ) {
    tempFilters["product_price_tier"] = "ALL";
  }
  // Special case for function filter
  if (
    tempFilters.product_function &&
    tempFilters.product_function.length === 0
  ) {
    tempFilters["product_function"] = "ALL";
  }
  // Special case for life stage filter
  if (
    tempFilters.product_life_stage &&
    tempFilters.product_life_stage.length === 0
  ) {
    tempFilters["product_life_stage"] = "ALL";
  }
  // Special case for breed size filter
  if (
    tempFilters.product_breed_size &&
    tempFilters.product_breed_size.length === 0
  ) {
    tempFilters["product_breed_size"] = "ALL";
  }
  // Special case for package type filter
  if (
    tempFilters.product_package_type &&
    tempFilters.product_package_type.length === 0
  ) {
    tempFilters["product_package_type"] = "ALL";
  }
  // Special case for protein filter
  if (
    tempFilters.product_protein &&
    tempFilters.product_protein.length === 0
  ) {
    tempFilters["product_protein"] = "ALL";
  }

  const keysToEncode = {
    product_keyword_category: encodeTreeSelectKey,
    trend_product_brand: encodeTreeSelectValue,
    retailer_view_brand: encodeTreeSelectKey,
    strategy_brand: encodeTreeSelectKey,
  };

  Object.keys(keysToEncode).forEach((key) => {
    if (tempFilters[key] && tempFilters[key].length > 0) {
      tempFilters[key] = keysToEncode[key](tempFilters[key]);
    }
  });

  return tempFilters;
};

export const updateRetailerDropdown = (filters, retailers, dispatch) => {
  const newFilters = { ...filters };
  if (retailers.length === 0) return [];
  const retailerDropdown = retailers.filter(
    (retailer) => !filters.customer_retailer_details.includes(retailer)
  );

  const filteredSelectedRetailers = [...newFilters.selected_retailer].filter(
    (retailer) => retailerDropdown.includes(retailer)
  );
  const selectedRetailers = [...newFilters.selected_retailer];
  if (!isEqual(filteredSelectedRetailers.sort(), selectedRetailers.sort())) {
    newFilters.selected_retailer = filteredSelectedRetailers;
    dispatch(setSelectedRetailer(filteredSelectedRetailers));
  }

  dispatch(setRetailerDropdown(retailerDropdown));
  return newFilters;
};

const setFiltersDefault = (
  dispatch,
  response,
  getState,
  initialLoad = false
) => {
  const state = getState().searchTerm;
  let filters = state.filters;
  const appliedFilters = state.appliedFilters;

  const newFilters = { ...filters };
  if (
    // newFilters.customer_retailer_details.length === 0 &&
    response.data.default_customer_retailer_details
  )
    if (filters.customer_retailer_details[0] === "Amazon") {
      newFilters.customer_retailer_details = [
        "Amazon"
        // response.data.default_customer_retailer_details,
      ];
    }
  if (
    // newFilters.time_aggregate.length === 0 &&
    response.data.default_time_aggregate
  )
    newFilters.time_aggregate = [response.data.default_time_aggregate];
  if (newFilters.year_period.length === 0 && response.data.default_year_period)
    newFilters.year_period = [response.data.default_year_period];
  if (
    // newFilters.benchmark.length === 0 &&
    response.data.default_benchmark_period
  )
    newFilters.benchmark = [response.data.default_benchmark_period];
  dispatch(setFilters(newFilters));

  if (initialLoad) {
    const newAppliedFilters = { ...appliedFilters };
    if (
      // newAppliedFilters.customer_retailer_details.length === 0 &&
      response.data.default_customer_retailer_details
    )
      if (filters.customer_retailer_details[0] === "Amazon") {
        newAppliedFilters.customer_retailer_details = [
          "Amazon"
          // response.data.default_customer_retailer_details,
        ];
      }
    if (
      // newAppliedFilters.time_aggregate.length === 0 &&
      response.data.default_time_aggregate
    )
      newAppliedFilters.time_aggregate = [response.data.default_time_aggregate];
    if (
      // newAppliedFilters.year_period.length === 0 &&
      response.data.default_year_period
    )
      newAppliedFilters.year_period = [response.data.default_year_period];
    if (
      // newAppliedFilters.benchmark.length === 0 &&
      response.data.default_benchmark_period
    )
      newAppliedFilters.benchmark = [response.data.default_benchmark_period];
    dispatch(getFilterData({
      // default filters
      customer_retailer_details: newAppliedFilters.customer_retailer_details,
      time_aggregate: newAppliedFilters.time_aggregate,
      year_period: newAppliedFilters.year_period,
      benchmark: newAppliedFilters.benchmark,
    }))
    dispatch(setAppliedFilters(newAppliedFilters));
    return newAppliedFilters;
  }

  return null;
};

export const getAllFilters = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.searchTermDropdown, "GET")
    .then((response) => {
      if (response.data.product_keyword_type)
        dispatch(setKeywordTypes(response.data.product_keyword_type));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.product_price_tier)
        dispatch(setPriceTeirs(response.data.product_price_tier));
      if (response.data.product_function)
        dispatch(setFunctions(response.data.product_function));
      if (response.data.product_life_stage)
        dispatch(setLifeStages(response.data.product_life_stage));
      if (response.data.product_breed_size)
        dispatch(setBreedSizes(response.data.product_breed_size));
      if (response.data.product_package_type)
        dispatch(setPackageTypes(response.data.product_package_type));
      if (response.data.product_protein)
        dispatch(setProteins(response.data.product_protein));

      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));

      const newAppliedFilters = setFiltersDefault(
        dispatch,
        response,
        getState,
        true
      );
      if (response.data.customer_retailer_details)
        updateRetailerDropdown(
          newAppliedFilters,
          response.data.customer_retailer_details,
          dispatch
        );

      if (response.data.product_keyword_category)
        dispatch(
          setKeywordCategories(
            decodeTreeSelect(response.data.product_keyword_category)
          )
        );

      dispatch(setLoading(false));
    })
    .catch((err) => {

      dispatch(setLoading(false));
    });
};

export const getAllSubFilters = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.searchTermSubDropdown + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.brand_list) {
        dispatch(setBrands(decodeTreeSelectValue(response.data.brand_list)));
        dispatch(
          setTrendBrands(decodeTreeSelectValue(response.data.brand_list))
        );
      }
      if (response.data.category_list)
        dispatch(setCategories(response.data.category_list));

      dispatch(setLoading(false));
    })
    .catch((err) => {

      dispatch(setLoading(false));
    });
};

export const getFilterData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setDropdownLoading(true));
  CustomAxios(ENDPOINTS.searchTermDropdown + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.product_keyword_type)
        dispatch(setKeywordTypes(response.data.product_keyword_type));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.product_price_tier)
        dispatch(setPriceTeirs(response.data.product_price_tier));
      if (response.data.product_function)
        dispatch(setFunctions(response.data.product_function));
      if (response.data.product_life_stage)
        dispatch(setLifeStages(response.data.product_life_stage));
      if (response.data.product_breed_size)
        dispatch(setBreedSizes(response.data.product_breed_size));
      if (response.data.product_package_type)
        dispatch(setPackageTypes(response.data.product_package_type));
      if (response.data.product_protein)
        dispatch(setProteins(response.data.product_protein));

      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));
      setFiltersDefault(dispatch, response, getState);

      if (response.data.product_keyword_category)
        dispatch(
          setKeywordCategories(
            decodeTreeSelect(response.data.product_keyword_category)
          )
        );

      dispatch(setDropdownLoading(false));
    })
    .catch((err) => {

      dispatch(setDropdownLoading(false));
    });
};

const updateDeepdiveList = (comparisonTrendData, dispatch, filters) => {
  const newFilters = { ...filters };
  if (comparisonTrendData.length === 0) newFilters.deep_dive_list = [];
  else newFilters.deep_dive_list = [comparisonTrendData[0]["Search Term"]];

  dispatch(setFilters(newFilters));
  return newFilters;
};

export const getSearchTermOverview =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.searchTermOverview + "?" + encodedURL, "GET")
      .then((response) => {
        dispatch(setSearchTermOverview(response.data));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(setSearchTermOverview(initialState.searchTermOverview));
      });
  };

export const getComparisonTrendData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.searchTermBarChart + "?" + encodedURL, "GET")
      .then((response) => {
        const filtersInState = cloneDeep(getState().searchTerm.filters);

        if (response.data.bar_chart) {
          const comparisonTrend = response.data.bar_chart.bar_view.map(
            ({ search_term, kpi_value, cumulative_traffic }) => ({
              "Search Term": search_term,
              [barLegends[filters.view_by[0]][0]]: kpi_value,
              [barLegends[filters.view_by[0]][1]]: cumulative_traffic,
            })
          );
          dispatch(setComparisonTrendData(comparisonTrend));
          const newFilters = updateDeepdiveList(
            comparisonTrend,
            dispatch,
            filtersInState
          );
          dispatch(getDeepdiveTrendData(newFilters));
        } else {
          dispatch(setComparisonTrendData(initialState.comparisonTrendData));
          updateDeepdiveList(
            initialState.comparisonTrendData,
            dispatch,
            filtersInState
          );
        }

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        const filtersInState = getState().searchTerm.filters;
        dispatch(setComparisonTrendData(initialState.comparisonTrendData));
        updateDeepdiveList(
          initialState.comparisonTrendData,
          dispatch,
          filtersInState
        );
      });
  };

const addMissingDeepdive = (yearPeriodList, dataItem, trend, filters) =>
  yearPeriodList.map((yearPeriod) => {
    const item = dataItem.find(
      ({ time_year_period }) => time_year_period === yearPeriod
    );

    if (item)
      return {
        Periods: item.time_year_period,
        [barLegends[filters.view_by[0]][0]]: item.kpi_value,
        Trend: trend,
      };

    return {
      Periods: yearPeriod,
      [barLegends[filters.view_by[0]][0]]: 0,
      Trend: trend,
    };
  });

export const getDeepdiveTrendData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.searchTermLineChart + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.search_term_deepdive) {
        if (filters.deep_dive_list.length > 1) {
          const { line_kpi, ...lineData } =
            response.data.search_term_deepdive.line_dict;

          Object.keys(lineData).forEach((trend) => {
            const lineItem = lineData[trend];

            lineData[trend] = addMissingDeepdive(
              response.data.year_period_list,
              lineItem,
              trend,
              filters
            );
          });

          const deepdiveTrend = [];
          Object.keys(lineData).forEach((trend) => {
            deepdiveTrend.push(...lineData[trend]);
          });
          dispatch(setDeepdiveMultiTrendData(deepdiveTrend));
        } else {
          const barItem = response.data.search_term_deepdive.bar_dict.bar_grid;
          const search_term = barItem?.[0].search_term;

          const deepdiveTrend = addMissingDeepdive(
            response.data.year_period_list,
            barItem,
            search_term,
            filters
          );

          // dispatch(setDeepdiveSingleTrendData(deepdiveTrend));
          dispatch(setDeepdiveMultiTrendData(deepdiveTrend));
        }
      } else {
        dispatch(
          setDeepdiveSingleTrendData(initialState.deepdiveSingleTrendData)
        );
        dispatch(
          setDeepdiveMultiTrendData(initialState.deepdiveMultiTrendData)
        );
      }

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(
        setDeepdiveSingleTrendData(initialState.deepdiveSingleTrendData)
      );
      dispatch(setDeepdiveMultiTrendData(initialState.deepdiveMultiTrendData));
    });
};

export const getTableData = (filters) => async (dispatch, getState) => {

  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.searchTermTable + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setTableData(response.data));
      dispatch(setSearchTermPagination(filters.searchTermPagination));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setTableData(initialState.tableData));
      dispatch(setSearchTermPagination(initialState.filters.searchTermPagination));
    });
};

export const getScatterChartData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.searchTermBubbleChart + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setStrategyData(response.data[0]));
      dispatch(setDuplicateStrategyData(response.data[0]));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setStrategyData(initialState.strategyData));
      dispatch(setDuplicateStrategyData(initialState.strategyData));
    });
};

export default searchTermSlicer.reducer;
