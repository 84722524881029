import React, { useEffect, useState } from "react";
import { isEqual, cloneDeep } from "lodash";
import { useHistory, withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import {
  Col,
  Layout,
  Row,
  Form,
  Button,
  Select,
  Menu,
  Radio,
  TreeSelect,
  Tooltip,
  Dropdown,
} from "antd";
import { appInsights } from "../../utilities/appInsights";

import Loader from "../../components/loader";
import {
  ArrowUpOutlined,
  DownloadOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import "./be-available.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFilters,
  getCardData,
  getComparisonTrendData,
  getCompetitionFilterData,
  getDeepdiveTrendData,
  getFilterData,
  getInitialBottomFilters,
  getInitialFilters,
  getMacroTableData,
  getMicroTableData,
  processFilters,
  setAppliedFilters,
  setFilters,
  setSelectedCompetitor,
} from "../../slicers/be-available.slicer";
import { isPackSize } from "../volume-shifting";
import {
  ColumnBulletChart,
  ColumnLineChart,
  KPICard,
  kpiDropdown,
  kpiDropdownSales,
  MultiLineChart,
  rowKeyMacro,
  rowKeyMicro,
  UPCColumnLineChart,
  upcGraphData,
  upcGraphDropdown,
  viewBy,
  declareAppliedFilters,
} from "./baUIHelper";
import columns from "./baColumns";
import CustomTable from "../../components/customTable";
import { compareVia } from "../../components/competition-benchmark";
import mdiView from "../../assets/images/mdi_view-dashboard.svg";
import mdiCart from "../../assets/images/mdi_cart.svg";
import mdiDecagram from "../../assets/images/mdi_check-decagram.svg";
import { ReactComponent as MdiDecagram } from "../../assets/images/mdi_check-decagram.svg";
import mdiCheck from "../../assets/images/mdi_eye-check.svg";
import { allBrands, setWindowHistory, symbols, getTitleWidth, itemsListed, formatNumber, initialUrlFilter, getLatestUpdatedFilter } from "../../utilities/helper";
import Navigator from "../../components/bread-crumbs";
import { setExportData } from "../../slicers/exportData.slicer";
import { getState } from "../../store";
import {
  exportBAKPIs,
  exportBATables,
  exportBeAvailable,
  formatNumberExcel,
  numFmt,
  exportBATrends
} from "../../utilities/exports/excel";
import { pptExportBeAvailable } from "../../utilities/exports/ecomppt";
import { ecomCommonFilters } from "../../slicers/diagnostics.slicer";

const { Content } = Layout;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

function BeAvailable(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [lastDefaultBrand, setLastDefaultBrand] = useState("");
  const [filterChanged, setFilterChanged] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);

  const [retailerDropdown, setRetailerDropdown] = useState([]);

  // On Mouse Leave events
  const [selectedRetailer, setSelectedRetailer] = useState([]);

  const [macroColumns, setMacroColumns] = useState(columns.baMacroCols);
  const [microColumns, setMicroColumns] = useState(columns.baMicroCols);
  const [macroColumnsExport, setMacroColumnsExport] = useState(
    columns.baMacroColsExport
  );
  const [microColumnsExport, setMicroColumnsExport] = useState(
    columns.baMicroColsExport
  );

  // get store
  const exportData = useSelector((state) => state.exportData);
  const { beAvailable } = useSelector((state) => state.refreshDate);
  const { defaultBrand } = useSelector((state) => state.profile);
  const [selectedBrand, setSelectedBrand] = useState(defaultBrand);
  const isAllBrands = defaultBrand === allBrands;

  if (isAllBrands) viewBy.S = "Brand";
  else viewBy.S = "Sub Brand";



  const {
    loading,
    microLoading,
    dropdownLoading,
    pets,
    techs,
    subTechs,
    lifestage,
    feedingPhilosophies,
    textures,
    retailers,
    timeAggregates,
    yearPeriods,
    benchmarkPeriods,
    competitors,
    filters,
    appliedFilters,
    cardData,
    comparisonTrendData,
    deepdiveSingleTrendData,
    deepdiveMultiTrendData,
    macroTableData,
    microTableData,
    selectedCompetitor,
  } = useSelector((state) => state.beAvailable);
  const [components, setComponents] = useState([]);
  const [newReport, setNewReport] = useState(false);
  const [newReportComponents, setNewReportComponents] = useState([]);
  const [sortLoading, setSortLoading] = useState(false);

  const [stored, setStored] = useState(false);

  const isReport = props.isReport;
  const menu = (exportData) => (
    <Menu>
      <Menu.Item key="0" onClick={exportData}>
        Export Data
      </Menu.Item>
    </Menu>
  );

  const appendRetailers = (
    columnIndex,
    columns,
    record,
    kpi,
    renderFunction,
    renderPPTFunction,
    sort
  ) => {
    if (record[kpi]) {
      const sorting = kpi === "sales" && sort === "sortTrue";
      columns[columnIndex].children = Object.keys(record[kpi]).map(
        (retailer) => ({
          title: retailer,
          dataIndex: [kpi, retailer],
          dataIndexPPT: kpi,
          key: retailer,
          width: getTitleWidth(retailer, sorting),
          render: (val) => renderFunction(val),
          renderPPT: (val) => renderPPTFunction(val),
          sorter: sorting
            ? (a, b) => a.sales[retailer]?.abs - b.sales[retailer]?.abs
            : false,
          className: "sub-td-border-left",
        })
      );
      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };

  const appendRetailersExport = (
    columns,
    record,
    kpi,
    title,
    renderTextAbs,
    renderTextVariance
  ) => {

    if (record[kpi]) {
      Object.keys(record[kpi]).forEach((retailer) => {
        // abs
        columns.push({
          title: `${title} ${retailer}`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) => renderTextAbs(val && val[retailer]),
        });

        // variance
        columns.push({
          title: `${title} ${retailer} vs Benchmark`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) => renderTextVariance(val && val[retailer]),
        });
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatTableData = (table_data) => {
    const tableDataInStore = JSON.parse(JSON.stringify(table_data));
    const tableData = [];
    tableDataInStore.forEach((data) => {
      tableData.push(data);
      if (data.table_hierarchy_data)
        tableData.push(...data.table_hierarchy_data);
    });

    dispatch(setExportData({ macroTableData: { data: tableData } }));
  };

  useEffect(() => {
    // Macro table dynamic column binding
    if (macroTableData.length) {
      const record = macroTableData[0];
      const baMacroColumns = cloneDeep(columns.baMacroCols);
      const baMacroColumnsExport = cloneDeep(columns.baMacroColsExport);

      if (filters.view_by[0] === "C") baMacroColumns[0].title = "Brand";
      if (filters.view_by[0] === "C") baMacroColumnsExport[0].title = "Brand";

      // UI
      appendRetailers(
        1,
        baMacroColumns,
        record,
        "sales",
        columns.renderDollarPercentFunction,
        columns.renderPPTDollarPercentageFunction,
        "sortTrue"
      );
      appendRetailers(
        2,
        baMacroColumns,
        record,
        "items_listed",
        columns.renderWholeNumberPercentFunction,
        columns.renderPPTWholeNumberPercentageFunction,
        "sortFalse"
      );
      appendRetailers(
        3,
        baMacroColumns,
        record,
        "in_stock",
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction,
        "sortFalse"
      );
      appendRetailers(
        4,
        baMacroColumns,
        record,
        "csl",
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction,
        "sortFalse"
      );

      // Export
      appendRetailersExport(
        baMacroColumnsExport,
        record,
        "sales",
        baMacroColumns[1].title,
        columns.renderTextDollarFunction,
        columns.renderTextVariancePercentageFunction
      );

      appendRetailersExport(
        baMacroColumnsExport,
        record,
        "items_listed",
        baMacroColumns[2].title,
        columns.renderTextWholeNumberFunction,
        columns.renderTextVariancePercentageFunction
      );

      appendRetailersExport(
        baMacroColumnsExport,
        record,
        "in_stock",
        baMacroColumns[3].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        baMacroColumnsExport,
        record,
        "csl",
        baMacroColumns[4].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      setMacroColumns(baMacroColumns);
      setMacroColumnsExport(baMacroColumnsExport);
      formatTableData(macroTableData);
    }
  }, [macroTableData]);

  // Micro table dynamic column binding
  useEffect(() => {
    if (microTableData.length) {
      const record = microTableData[0];
      const baMicroColumns = cloneDeep(columns.baMicroCols);
      const baMicroColumnsExport = cloneDeep(columns.baMicroColsExport);

      appendRetailers(
        2,
        baMicroColumns,
        record,
        "sales",
        columns.renderDollarPercentFunction,
        columns.renderPPTDollarPercentageFunction,
        "sortTrue"
      );
      appendRetailers(
        3,
        baMicroColumns,
        record,
        "in_stock",
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction,
        "sortFalse"
      );
      appendRetailers(
        4,
        baMicroColumns,
        record,
        "csl",
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction,
        "sortFalse"
      );

      // Export
      appendRetailersExport(
        baMicroColumnsExport,
        record,
        "sales",
        baMicroColumns[2].title,
        columns.renderTextDollarFunction,
        columns.renderTextVariancePercentageFunction
      );

      appendRetailersExport(
        baMicroColumnsExport,
        record,
        "in_stock",
        baMicroColumns[3].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      appendRetailersExport(
        baMicroColumnsExport,
        record,
        "csl",
        baMicroColumns[4].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );

      setMicroColumns(baMicroColumns);
      setMicroColumnsExport(baMicroColumnsExport);
      dispatch(setExportData({ microTableData: { title: getMicroSheetName(), data: microTableData } }));
    }
  }, [microTableData]);

  useEffect(() => {
    const processedFilters = processFilters(filters, getState);
    processedFilters.view_by = [viewBy[processedFilters.view_by[0]]];
    dispatch(setExportData({ filters: processedFilters }));
  }, [filters]);

  useEffect(() => {
    if (!cardData) return;
    if (Object.keys(cardData).length === 0) {
      dispatch(setExportData({ kpi: {} }));
      return;
    }

    dispatch(
      setExportData({
        kpi: {
          listed: {
            title: "# of items listed",
            abs: formatNumberExcel(
              cardData.fact_items_listed && cardData.fact_items_listed?.abs
            ),
            absNumFmt: numFmt.wholeNumber,
            variance: formatNumberExcel(
              cardData.fact_items_listed &&
              cardData.fact_items_listed?.variance,
              true
            ),
            varianceNumFmt: numFmt.percentage,
            value: formatNumber(cardData?.fact_items_listed?.abs, 0),
            change: cardData?.fact_items_listed?.variance,
            changeSymbol: symbols.percent
          },
          in_stock: {
            title: "% of items in stock",
            abs: formatNumberExcel(
              cardData.fact_items_in_stock && cardData.fact_items_in_stock?.abs,
              true
            ),
            absNumFmt: numFmt.percentage,
            variance: formatNumberExcel(
              cardData.fact_items_in_stock &&
              cardData.fact_items_in_stock?.variance
            ),
            varianceNumFmt: numFmt.number,
            value: formatNumber(cardData?.fact_items_in_stock?.abs),
            change: cardData.fact_items_in_stock?.variance,
            valueSymbol: symbols.percent,
            changeSymbol: symbols.pts,
          },
          csl: {
            title: "CSL",
            callout: "CSL available for Mars only",
            abs: formatNumberExcel(
              cardData.fact_csl && cardData.fact_csl?.abs,
              true
            ),
            absNumFmt: numFmt.percentage,
            variance: formatNumberExcel(
              cardData.fact_csl && cardData.fact_csl?.variance
            ),
            varianceNumFmt: numFmt.number,
            value: formatNumber(cardData?.fact_csl?.abs),
            change: cardData.fact_csl?.variance,
            valueSymbol: symbols.percent,
            changeSymbol: symbols.pts,
          },
        },
      })
    );
  }, [cardData]);

  // Update the applied filters section
  // if nothing is selecte set ALL otherwise display the actual items

  // Set the filters applied based on the filters selected on the UI while clicking on Apply button

  const changeSelectedBrand = () => setSelectedBrand(defaultBrand);

  const getData = (filters, fetchFilterData = true, clearHistory = true, initialFilterData = true) => {
    dispatch(setAppliedFilters(declareAppliedFilters(filters, { pets, techs, feedingPhilosophies, textures })));
    dispatch(setFilters(filters));

    const isBrandChanged = defaultBrand !== selectedBrand;

    let getLatestFilters = getLatestUpdatedFilter(filters)

    // API calls
    if (fetchFilterData)
      if (initialFilterData) dispatch(getAllFilters(isBrandChanged, changeSelectedBrand));
      else
        dispatch(getFilterData({ allFilters: true }, true, isBrandChanged, changeSelectedBrand, {
          ...getLatestFilters,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark
        }));

    else {
      dispatch(getComparisonTrendData(filters));
      dispatch(getCardData(filters));
      dispatch(getCompetitionFilterData(filters));
    }
    // if (clearHistory) setWindowHistory("");
  };

  const updateRetailerDropdown = (filters, isApply = false) => {
    const newFilters = { ...filters };
    // If there is no retailer selected then return the filters object instead of an empty array.
    if (retailers.length === 0) return newFilters;
    const retailerDropdown = retailers.filter(
      (retailer) =>
        !(
          isApply ? filters : appliedFilters
        ).customer_retailer_details.includes(retailer)
    );

    const filteredSelectedRetailers = [...newFilters.selected_retailer].filter(
      (retailer) => retailerDropdown.includes(retailer)
    );
    const selectedRetailers = [...newFilters.selected_retailer];
    if (!isEqual(filteredSelectedRetailers.sort(), selectedRetailers.sort())) {
      newFilters.selected_retailer = filteredSelectedRetailers;
      setSelectedRetailer(filteredSelectedRetailers);
    }

    setRetailerDropdown(retailerDropdown);
    return newFilters;
  };

  const applyFilters = () => {
    const newFilters = {
      ...updateRetailerDropdown(filters, true),
      default_brand: [defaultBrand],
      deep_dive_list: [],
    };

    setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
    getData(newFilters, false, false);
  };

  const getRemainingFilters = (label) => {
    if (!filterChanged) return;

    setFilterChanged(false);
    const isProductPackage =
      filters.product_technology.length === 1 &&
      isPackSize.includes(filters.product_technology[0]);

    if (label === "product_pet") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,

          // default filters
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_sub_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_lifestage") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_feed_philosophy") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_texture") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "customer_retailer_details") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,

          // default filters
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "year_period") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,

          // default filters
          time_aggregate: filters.time_aggregate,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "time_aggregate") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,
          time_aggregate: filters.time_aggregate,

          // default filters
          benchmark: filters.benchmark,
        })
      );
    }
  };

  const changeFilter = (label, selectedValue) => {
    if (!isEqual(filters[label], selectedValue)) setFilterChanged(true);

    let newFilters = { ...filters };
    if (
      selectedValue &&
      selectedValue.length &&
      selectedValue.includes("all")
    ) {
      let all = [];
      if (label === "product_pet") all = pets;
      else if (label === "product_technology") all = techs;
      else if (label === "product_sub_technology") all = subTechs;
      else if (label === "product_lifestage") all = lifestage;
      else if (label === "product_feed_philosophy") all = feedingPhilosophies;
      else if (label === "product_texture") all = textures;
      if (selectedValue.length === all.length + 1) selectedValue = [];
      else selectedValue = [...all];
    }

    newFilters[label] = selectedValue;
    dispatch(setFilters(newFilters));
    // setLocalFilter(newFilters);
    return newFilters;
  };

  useEffect(() => {
    const initialFilters = {
      ...getInitialBottomFilters(),
      default_brand: [defaultBrand],
    };

    initialUrlFilter(initialFilters, ecomCommonFilters, filters, defaultBrand, getData);

    setLastDefaultBrand(defaultBrand);
  }, [defaultBrand, props.reportApply]);

  useEffect(() => {
    if (macroTableData.length) {
      setSelectedRowKey([macroTableData[0][rowKeyMacro]]);
      setSelectedRow([macroTableData[0]]);
    } else {
      setSelectedRowKey([]);
      setSelectedRow([]);
    }
  }, [macroTableData]);

  useEffect(() => {
    if (comparisonTrendData.length) setStored(false);
  }, [comparisonTrendData]);

  const prepareKPIExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));
    data.filters = {
      product_pet: data.filters.product_pet,
      product_technology: data.filters.product_technology,
      product_sub_technology: data.filters.product_sub_technology,
      product_lifestage: data.filters.product_lifestage,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
    };

    return data;
  };

  const prepareTableExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));

    data.filters = {
      product_brand: data.filters.product_brand,
      product_secondary_brand: data.filters.product_secondary_brand,
      product_pet: data.filters.product_pet,
      product_technology: data.filters.product_technology,
      product_sub_technology: data.filters.product_sub_technology,
      product_lifestage: data.filters.product_lifestage,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
      view_by: data.filters.view_by,
      selected_competitor: data.filters.selected_competitor,
      deep_dive_list: data.filters.deep_dive_list,
      selected_retailer: data.filters.selected_retailer,
    };

    return data;
  };

  const prepareTrendExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));

    data.filters = {
      product_brand: data.filters.product_brand,
      product_secondary_brand: data.filters.product_secondary_brand,
      product_pet: data.filters.product_pet,
      product_technology: data.filters.product_technology,
      product_sub_technology: data.filters.product_sub_technology,
      product_lifestage: data.filters.product_lifestage,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
      view_by: data.filters.view_by,
      selected_competitor: data.filters.selected_competitor,
      performance_summary_comparison_kpi:
        data.filters.performance_summary_comparison_kpi,
      performance_summary_deepdive_kpi:
        data.filters.performance_summary_deepdive_kpi,
      deep_dive_list: data.filters.deep_dive_list,
    };

    return data;
  };

  // Export functions
  const exportKPIs = () =>
    exportBAKPIs(prepareKPIExport(exportData), "BeAvailable_KPI_Layer");

  const getMicroSheetName = () =>
    selectedRow.length > 0 ? `${selectedRow[0].table_key} UPCs` : "UPCs";

  const exportTableData = () =>
    exportBATables(
      macroColumnsExport,
      microColumnsExport,
      prepareTableExport(exportData),
      getMicroSheetName()
    );

  const exportTrends = () => exportBATrends(prepareTrendExport(exportData));

  const getComparisonTitle = () =>
    `${filters.performance_summary_comparison_kpi[0]} - ${viewBy[filters.view_by[0]]
    } Comparison`;
  const getDeepdiveTitle = () =>
    `${filters.performance_summary_deepdive_kpi[0]} - ${filters.deep_dive_list.length === 1
      ? filters.deep_dive_list[0]
      : "Deep Dive"
    }`;

  return (
    <Wrapper
      hideHeader={props.hideReportHeader}
      hidePage={isReport}
      heading={"1"}
      innerHeading={"12"}
      ecomTab={"0"}
    >
      <Content
        className={
          "site-layout-background" +
          (isReport && components.length === 0
            ? " content-none"
            : " content-body")
        }
      >
        <Loader
          loading={(isReport ? props.reportLoader : loading > 0) || sortLoading}
        >
          {!props.hideReportFilters && (
            <>
              {" "}
              <Row>
                <Navigator refreshDate={beAvailable} />
              </Row>
              <Row className="beAvailable-card">
                <div>
                  <span>
                    <img src={mdiDecagram} alt="Diagnostics" width="22" />
                  </span>{" "}
                  <b className="ba-info">Be Available</b> In stock
                  products are critical to online sales and conversion. Out of
                  stock items not only impact sales but also search ranking,
                  requiring reinvestment to recover.
                </div>
              </Row>
              <Row
                align="bottom"
                style={{ marginBottom: 16 }}
                gutter={[16, 16]}
              >
                <Col span={20}>
                  <Form
                    name="horizontal_login"
                    className="performance-filter"
                    layout="inline"
                  >
                    <Row gutter={12}>
                      <div className="filters-ps">
                        <Col>
                          <Tooltip title="Use filters to view selected Category, Tech, Sub Tech, Feeding Philosophy, and/or Texture by Retailer">
                            <label className="label-block">Pet</label>
                            <Select
                              placeholder="All"
                              mode="multiple"
                              defaultValue={["all"]}
                              dropdownMatchSelectWidth={false}
                              autoClearSearchValue={false}
                              style={{ width: 70 }}
                              placement="bottomLeft"
                              maxTagCount="responsive"
                              value={filters.product_pet}
                              onMouseLeave={() =>
                                getRemainingFilters("product_pet")
                              }
                              onChange={(val) => {
                                changeFilter("product_pet", val);
                              }}
                            >
                              <Option value={"all"}>{"All"}</Option>
                              {pets.map((val) => (
                                <Option value={val}>{val}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Technology</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 76 }}
                            maxTagCount="responsive"
                            value={filters.product_technology}
                            loading={dropdownLoading}
                            autoClearSearchValue={false}
                            onMouseLeave={() =>
                              getRemainingFilters("product_technology")
                            }
                            onChange={(val) => {
                              changeFilter("product_technology", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {techs.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Sub Technology</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 110 }}
                            maxTagCount="responsive"
                            value={filters.product_sub_technology}
                            loading={dropdownLoading}
                            autoClearSearchValue={false}
                            onMouseLeave={() =>
                              getRemainingFilters("product_sub_technology")
                            }
                            onChange={(val) => {
                              changeFilter("product_sub_technology", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {subTechs.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Life Stage</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 110 }}
                            maxTagCount="responsive"
                            value={filters.product_lifestage}
                            loading={dropdownLoading}
                            autoClearSearchValue={false}
                            onMouseLeave={() =>
                              getRemainingFilters("product_lifestage")
                            }
                            onChange={(val) => {
                              changeFilter("product_lifestage", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {lifestage.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Feeding Philosophy
                          </label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 128 }}
                            maxTagCount="responsive"
                            value={filters.product_feed_philosophy}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_feed_philosophy")
                            }
                            onChange={(val) => {
                              changeFilter("product_feed_philosophy", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {feedingPhilosophies.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Texture</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 80 }}
                            autoClearSearchValue={false}
                            maxTagCount="responsive"
                            value={filters.product_texture}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_texture")
                            }
                            onChange={(val) => {
                              changeFilter("product_texture", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {textures.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <Tooltip title="Top 7 KPIs coming soon">
                            <label className="label-block">Retailer</label>
                            <Select
                              placeholder="All"
                              placement="bottomLeft"
                              dropdownMatchSelectWidth={false}
                              style={{ width: 100 }}
                              maxTagCount="responsive"
                              value={filters.customer_retailer_details}
                              onChange={(val) => {
                                dispatch(
                                  setFilters({
                                    ...filters,
                                    deep_dive_list: [],
                                    customer_retailer_details: [val],
                                  })
                                );
                              }}
                            >
                              {retailers?.map((retailer) => (
                                <Option value={retailer}>{retailer}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Time Aggregate</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            style={{ width: 105 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            value={filters.time_aggregate}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("time_aggregate")
                            }
                            onChange={(val) => {
                              changeFilter("time_aggregate", [val]);
                            }}
                          >
                            {timeAggregates.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Year Period</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            dropdownMatchSelectWidth={false}
                            style={{ width: 105 }}
                            maxTagCount="responsive"
                            value={filters.year_period}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("year_period")
                            }
                            onChange={(val) => {
                              changeFilter("year_period", [val]);
                            }}
                          >
                            {yearPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Benchmark Period
                          </label>
                          <Select
                            placeholder="All"
                            style={{ width: 130 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            value={filters.benchmark}
                            loading={dropdownLoading}
                            onChange={(val) => {
                              changeFilter("benchmark", [val]);
                            }}
                          >
                            {benchmarkPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                      </div>
                      <Col>
                        <Button
                          type="primary"
                          style={{ marginTop: "27%" }}
                          onClick={applyFilters}
                          disabled={dropdownLoading}
                        >
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col span={4}>
                  <Row gutter={20} justify="end">
                    {!newReport && (
                      <>
                        <Col>
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  onClick={() => {
                                    appInsights.trackEvent({ name: 'PPT_FOR_BE_AVAILABLE' })
                                    pptExportBeAvailable(
                                      exportData,
                                      "Be Available",
                                      macroColumns,
                                      microColumns,
                                      defaultBrand,
                                    );
                                    exportTableData();
                                  }}
                                >
                                  Download as PPT
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => {
                                    appInsights.trackEvent({ name: 'EXCEL_FOR_BE_AVAILABLE' })
                                    exportBeAvailable(
                                      {
                                        kpiData: prepareKPIExport(exportData),
                                        trendData:
                                          prepareTrendExport(exportData),
                                        tableData:
                                          prepareTableExport(exportData),
                                      },
                                      macroColumnsExport,
                                      microColumnsExport,
                                      getMicroSheetName()
                                    );
                                  }}
                                >
                                  Download as Excel
                                </Menu.Item>
                              </Menu>
                            )}
                            className="d-more"
                          >
                            <Button icon={<DownloadOutlined />}>
                              Export Report
                            </Button>
                          </Dropdown>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {!isReport && (
            <Row className="sticky-filter">
              <Col span={24}>
                <div className="selected-filters">
                  <label>Filters Applied:</label>
                  <div className="filter-item">
                    Pet -{" "}
                    <span>{Array.isArray(appliedFilters.product_pet) && appliedFilters.product_pet.length > 0 && !appliedFilters.product_pet.includes("ALL") ? appliedFilters.product_pet.join() : 'CAT,DOG'}</span>
                  </div>
                  <div className="filter-item">
                    Technology -{" "}
                    <span>{Array.isArray(appliedFilters.product_technology) && appliedFilters.product_technology.length > 0 ? appliedFilters.product_technology.join() : "ALL"}</span>
                  </div>
                  <div className="filter-item">
                    Sub Technology -{" "}
                    <span>
                      {/* The selected Sub Technology filters should be visible only */}
                      {Array.isArray(appliedFilters.product_sub_technology)
                        ? appliedFilters.product_sub_technology.length === 0 ||
                          appliedFilters.product_sub_technology.length ===
                          subTechs.length
                          ? "ALL"
                          : appliedFilters.product_sub_technology.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Life Stage -{" "}
                    <span>
                      {/* The selected Life Stage filters should be visible only */}
                      {Array.isArray(appliedFilters.product_lifestage)
                        ? appliedFilters.product_lifestage.length === 0 ||
                          appliedFilters.product_lifestage.length ===
                          lifestage.length
                          ? "ALL"
                          : appliedFilters.product_lifestage.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Feeding Philosophy -{" "}
                    <span>
                      {/* The selected Feeding Philosophy filters should be visible only */}
                      {Array.isArray(appliedFilters.product_feed_philosophy)
                        ? appliedFilters.product_feed_philosophy.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Texture -{" "}
                    <span>
                      {/* The selected Texture filters should be visible only */}
                      {Array.isArray(appliedFilters.product_texture)
                        ? appliedFilters.product_texture.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Retailer -{" "}
                    <span>
                      {/* The selected Retailer filters should be visible only */}
                      {Array.isArray(appliedFilters.customer_retailer_details)
                        ? appliedFilters.customer_retailer_details.length === 0
                          ? "ALL"
                          : appliedFilters.customer_retailer_details.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Time Aggregate -{" "}
                    <span>{appliedFilters.time_aggregate}</span>
                  </div>
                  <div className="filter-item">
                    Year Period - <span>{appliedFilters.year_period}</span>
                  </div>
                  <div className="filter-item">
                    Benchmark Period - <span>{appliedFilters.benchmark}</span>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={24}>
              <div className="ba-kpi-items" style={{ position: "relative" }}>
                <KPICard
                  title={itemsListed}
                  abs={
                    cardData.fact_items_listed &&
                    cardData.fact_items_listed?.abs
                  }
                  variance={
                    cardData.fact_items_listed &&
                    cardData.fact_items_listed?.variance
                  }
                  varianceSymbol={symbols.percent}
                />
                <KPICard
                  title="% of items in stock"
                  abs={
                    cardData.fact_items_in_stock &&
                    cardData.fact_items_in_stock?.abs
                  }
                  variance={
                    cardData.fact_items_in_stock &&
                    cardData.fact_items_in_stock?.variance
                  }
                  absSymbol={symbols.percent}
                  varianceSymbol={symbols.pts}
                />
                <Tooltip title="CSL available for Mars only" placement="top">
                  <KPICard
                    title="CSL"
                    abs={cardData.fact_csl && cardData.fact_csl?.abs}
                    variance={cardData.fact_csl && cardData.fact_csl?.variance}
                    absSymbol={symbols.percent}
                    varianceSymbol={symbols.pts}
                    exportKPIs={exportKPIs}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>

          <div className="collapse-expand">
            <Row>
              <Col span={24}>
                <div className="nutro-skus">
                  <div className="section-title">
                    <h4>
                      {filters.performance_summary_comparison_kpi[0]} - Trends
                    </h4>
                    <Dropdown
                      overlay={() => menu(exportTrends)}
                      trigger={["click"]}
                      placement="bottomRight"
                      className="d-more"
                    >
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        <MoreOutlined />
                      </a>
                    </Dropdown>
                  </div>
                  <div className="nutro-skus-filter">
                    <div className="nutro-skus-filter-category">
                      <div>
                        <span className="view-label">View By</span>
                        <Radio.Group
                          value={filters.view_by[0]}
                          size="small"
                          onChange={(e) => {
                            const newFilters = {
                              ...updateRetailerDropdown(filters),
                              view_by: [e.target.value],
                              deep_dive_list: [],
                            };
                            dispatch(setFilters(newFilters));
                            dispatch(getComparisonTrendData(newFilters));
                          }}
                        >
                          {Object.keys(viewBy).map((viewType) => (
                            <Radio.Button value={viewType}>
                              {viewBy[viewType]}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </div>
                      {filters.view_by[0] === "C" && (
                        <div>
                          <span className="view-label">Competitor</span>
                          <TreeSelect
                            placeholder="Please select"
                            treeCheckable
                            showCheckedStrategy={SHOW_PARENT}
                            style={{ width: 250 }}
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                            treeData={competitors}
                            dropdownMatchSelectWidth={false}
                            maxTagCount="responsive"
                            allowClear
                            autoClearSearchValue={false}
                            value={filters.selected_competitor}
                            onChange={(val) =>
                              dispatch(
                                setFilters({
                                  ...filters,
                                  selected_competitor: val,
                                  deep_dive_list: [],
                                })
                              )
                            }
                            onMouseLeave={() => {
                              const competitorsOnMouseLeave = [
                                ...selectedCompetitor,
                              ];
                              const competitorsOnChange = [
                                ...filters.selected_competitor,
                              ];
                              if (
                                !isEqual(
                                  competitorsOnMouseLeave.sort(),
                                  competitorsOnChange.sort()
                                )
                              ) {
                                dispatch(
                                  setSelectedCompetitor(
                                    filters.selected_competitor
                                  )
                                );
                                dispatch(setFilters(filters));
                                dispatch(getComparisonTrendData(filters));
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="sub-card-container">
                    <div className="nutro-skus sub-card-item">
                      <div className="section-title">
                        <h4>{getComparisonTitle()}</h4>
                        <p>
                          Select a bar for details analysis (select multiple
                          using Ctrl key)
                        </p>
                      </div>
                      <div className="chart-filter">
                        <span className="chart-filter-title">KPI</span>
                        <Select
                          placeholder="All"
                          dropdownMatchSelectWidth={false}
                          style={{ width: 110 }}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          value={filters.performance_summary_comparison_kpi[0]}
                          onChange={(value) => {
                            const newFilters = {
                              ...filters,
                              performance_summary_comparison_kpi: [value],
                              performance_summary_deepdive_kpi: [value],
                              deep_dive_list: [],
                            };
                            dispatch(setFilters(newFilters));
                            dispatch(getComparisonTrendData(newFilters));
                          }}
                        >
                          {kpiDropdown.map((kpi) => (
                            <Option value={kpi}>{kpi}</Option>
                          ))}
                        </Select>
                      </div>
                      <ColumnBulletChart
                        title={getComparisonTitle()}
                        kpi={filters.performance_summary_comparison_kpi[0]}
                        data={comparisonTrendData}
                        xField={viewBy[filters.view_by[0]]}
                        yField={[
                          "Sales",
                          filters.performance_summary_comparison_kpi[0],
                        ]}
                        yFieldNumFmt={[
                          numFmt.comma,
                          filters.performance_summary_comparison_kpi[0] ===
                            itemsListed
                            ? numFmt.wholeNumber
                            : numFmt.percentage,
                        ]}
                        viewBy={viewBy[filters.view_by[0]]}
                        value={filters.deep_dive_list}
                        onChange={(value) => {
                          const newFilters = {
                            ...filters,
                            deep_dive_list: value,
                          };
                          dispatch(setFilters(newFilters));
                          dispatch(getDeepdiveTrendData(newFilters));
                          dispatch(getMacroTableData(newFilters));
                          dispatch(getMicroTableData(newFilters));
                        }}
                        stored={stored}
                        setStored={setStored}
                        storageKey="BeAvailableComparisonTrendChart"
                      />
                    </div>
                    <div className="nutro-skus sub-card-item">
                      <div className="section-title">
                        <h4>{getDeepdiveTitle()}</h4>
                        <p>
                          Select more bars in the left graph to compare them
                          here
                        </p>
                      </div>
                      {filters.deep_dive_list.length > 1 && (
                        <div className="chart-filter">
                          <span className="chart-filter-title">KPI</span>
                          <Select
                            placeholder="All"
                            dropdownMatchSelectWidth={false}
                            style={{ width: 110 }}
                            placement="bottomLeft"
                            maxTagCount="responsive"
                            value={filters.performance_summary_comparison_kpi[0]}
                            onChange={(value) => {
                              const newFilters = {
                                ...filters,
                                performance_summary_comparison_kpi: [value],
                              };
                              dispatch(setFilters(newFilters));
                              dispatch(getDeepdiveTrendData(newFilters));
                            }}
                          >
                            {kpiDropdown.map((kpi) => (
                              <Option value={kpi}>{kpi}</Option>
                            ))}
                            {filters.deep_dive_list.length > 1 && (
                              <Option value={kpiDropdownSales}>
                                {kpiDropdownSales}
                              </Option>
                            )}
                          </Select>
                        </div>
                      )}
                      {filters.deep_dive_list.length > 1 ? (
                        <MultiLineChart
                          title={getDeepdiveTitle()}
                          kpi={filters.performance_summary_deepdive_kpi[0]}
                          data={deepdiveMultiTrendData}
                          xField="Periods"
                          yField={filters.performance_summary_deepdive_kpi[0]}
                          yFieldNumFmt={
                            filters.performance_summary_deepdive_kpi[0] ===
                              kpiDropdownSales
                              ? numFmt.comma
                              : filters.performance_summary_deepdive_kpi[0] ===
                                itemsListed
                                ? numFmt.wholeNumber
                                : numFmt.percentage
                          }
                          seriesField="Trend"
                        />
                      ) : (
                        <ColumnLineChart
                          title={getDeepdiveTitle()}
                          kpi={filters.performance_summary_deepdive_kpi[0]}
                          data={deepdiveSingleTrendData}
                          xField="Periods"
                          yField={[
                            "Sales",
                            filters.performance_summary_deepdive_kpi[0],
                          ]}
                          yFieldNumFmt={[
                            numFmt.comma,
                            filters.performance_summary_deepdive_kpi[0] ===
                              itemsListed
                              ? numFmt.wholeNumber
                              : numFmt.percentage,
                          ]}
                        />
                      )}
                    </div>
                  </div>

                  <div className="next-skus">
                    <CustomTable
                      rowKey={rowKeyMacro}
                      heading="Retailer Overview"
                      description="Select a product to see UPC level view on the bottom table"
                      childrenColumnName="table_hierarchy_data"
                      hideFilter={true}
                      data={macroTableData}
                      checkbox={newReport}
                      value={newReportComponents.includes("2")}
                      columns={macroColumns}
                      scroll={true}
                      xScroll="max-content"
                      addDropdown={
                        filters.view_by[0] === "R"
                          ? false
                          : {
                            options: retailerDropdown,
                            value: filters.selected_retailer,
                            onChange: (val) =>
                              dispatch(
                                setFilters({
                                  ...filters,
                                  selected_retailer: val,
                                })
                              ),
                            onMouseLeave: () => {
                              const selectedRetailerOnMouseLeave = [
                                ...selectedRetailer,
                              ];
                              const selectedRetailerOnChange = [
                                ...filters.selected_retailer,
                              ];

                              if (
                                !isEqual(
                                  selectedRetailerOnMouseLeave.sort(),
                                  selectedRetailerOnChange.sort()
                                )
                              ) {
                                setSelectedRetailer(
                                  filters.selected_retailer
                                );
                                dispatch(getMacroTableData(filters));
                                dispatch(getMicroTableData(filters));
                              }
                            },
                          }
                      }
                      rowSelection={{
                        checkStrictly: true,
                        selectedRowKeys: selectedRowKey,
                        type: "radio",
                        onChange: (selectedRowKeys, selectedRows) => {
                          setSelectedRowKey(selectedRowKeys);
                          setSelectedRow(selectedRows);
                          const newFilters = {
                            ...filters,
                            ...selectedRows[0][rowKeyMacro],
                          };
                          dispatch(getMicroTableData(newFilters));
                        },
                        hideSelectAll: true,
                      }}
                      exportData={exportTableData}
                    />
                  </div>

                  <div className="next-skus">
                    <CustomTable
                      loading={microLoading}
                      hideDropdown={true}
                      rowKey={rowKeyMicro}
                      heading={
                        selectedRow.length > 0
                          ? `${selectedRow[0].table_key} UPCs`
                          : "UPCs"
                      }
                      hideFilter={true}
                      data={microTableData}
                      columns={microColumns}
                      scroll={true}
                      xScroll="max-content"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(BeAvailable);
