import React, { useState } from "react";
import { Layout, Row, Col, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import { useSelector, useDispatch } from "react-redux";
import "./datasources.scss";
import Loader from "../../components/loader";
import ExportForm from "../../components/export-form";
import DummyExportForm from "../../components/dummy-export-form";
import { initiateCluster } from "../../slicers/dataSources.slicer";

const { Content } = Layout;

function DataSources(props) {
  const dispatch = useDispatch();
  const { loading, dataSources } = useSelector((state) => state.refreshDate);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openDummyExportDialog, setOpenDummyExportDialog] = useState(false);

  const dataTab = (heading, source) => (
    <div className="data-tab">
      <Row className="data-header">{heading}</Row>
      <Row className="data-details">
        Last Refreshed on:{" "}
        {dataSources[source] && dataSources[source].last_refresh}, Data upto:{" "}
        {dataSources[source] && dataSources[source].latest_period_available}
      </Row>
      <Row className="data-details">
        Next refresh on:{" "}
        {dataSources[source] && dataSources[source].next_refresh}
      </Row>
    </div>
  );

  return (
    <Wrapper heading={"2"}>
      <Content
        className="site-layout-background content-body content-data "
      >
        <Loader
          loading={loading > 0}
          tip="Please wait while the data is being downloaded"
        >
          <Row>
            <div className="header-row">
              <div className="header-text">External</div>
              {
                <div>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      dispatch(initiateCluster());
                      props.history.push("/export");
                    }}
                  >
                    Export Data
                  </Button>
                </div>
              }
            </div>
          </Row>
          <Row gutter="30">
            <Col md={8}>{dataTab("Nielsen", "NIELSEN")}</Col>
            <Col md={8}>{dataTab("Stackline", "STACKLINE")}</Col>
            <Col md={8}>{dataTab("Walmart", "WALMART")}</Col>
          </Row>
          <Row gutter="30">
            <Col md={8}>{dataTab("Kroger", "KROGER")}</Col>
            <Col md={8}>{dataTab("Sam's Club", "SAM'S CLUB")}</Col>
            <Col md={8}>{dataTab("Earnest", "EARNEST")}</Col>
          </Row>
          <Row gutter="30">
            <Col md={8}>{dataTab("Volume Shifting", "VOLUME SHIFTING")}</Col>
          </Row>
          <Row>
            <div className="header-row">
              <div className="header-text">Internal</div>
            </div>
          </Row>
          <Row gutter="30">
            <Col md={8}>{dataTab("Shipments", "SHIPMENTS")}</Col>
            <Col md={8}>{dataTab("Financials", "FINANCIALS")}</Col>
          </Row>
          <ExportForm
            openExportDialog={openExportDialog}
            setOpenExportDialog={setOpenExportDialog}
          />
          <DummyExportForm
            openDummyExportDialog={openDummyExportDialog}
            setOpenDummyExportDialog={setOpenDummyExportDialog}
          />
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(DataSources);
